import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showInsights: {
		id: 'insights-show-insights-button.show-insights',
		defaultMessage: 'Insights',
		description: 'label for button to show insights panel',
	},
	hideInsights: {
		id: 'insights-show-insights-button.hide-insights',
		defaultMessage: 'Insights',
		description: 'label for button to hide insights panel',
	},
});

import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Issue } from '../../../../model/issue/issue-types.tsx';
import flagMessages from '../../../../view/flags/messages.tsx';
import { warningFlagMeta } from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const ISSUE_DELETE_REQUEST = 'state.actions.issue.delete.ISSUE_DELETE_REQUEST' as const;

export type IssueDeleteRequestAction = {
	type: typeof ISSUE_DELETE_REQUEST;
	payload: {
		issue: Issue;
		childIssueIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const issueDeleteRequest = (
	issue: Issue,
	childIssueIds: IssueId[],
): IssueDeleteRequestAction => ({
	type: ISSUE_DELETE_REQUEST,
	payload: {
		issue,
		childIssueIds,
	},
	meta: {
		...beginOptimisticUiMeta(),
	},
});

export const ISSUE_DELETE_SUCCESS = 'state.actions.issue.delete.ISSUE_DELETE_SUCCESS' as const;

export type IssueDeleteSuccessAction = {
	type: typeof ISSUE_DELETE_SUCCESS;
	payload: {
		issueId: IssueId;
	};
	meta: OptimisticUiMeta;
};

export const issueDeleteSuccess = (
	optimisticId: string,
	issueId: IssueId,
): IssueDeleteSuccessAction => ({
	type: ISSUE_DELETE_SUCCESS,
	payload: {
		issueId,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ISSUE_DELETE_FAILURE = 'state.actions.issue.delete.ISSUE_DELETE_FAILURE' as const;

export type IssueDeleteFailureAction = {
	type: typeof ISSUE_DELETE_FAILURE;
	payload: {
		issueId: IssueId;
	};
	meta: OptimisticUiMeta;
};

export const issueDeleteFailure = (
	optimisticId: string,
	issueId: IssueId,
	errorMessages: string[] | null = null,
): IssueDeleteFailureAction => ({
	type: ISSUE_DELETE_FAILURE,
	payload: {
		issueId,
	},
	meta: errorMessages
		? {
				...revertOptimisticUiMeta(optimisticId),
				...warningFlagMeta({
					id: 'ISSUE_DELETE_ERROR_FLAG',
					titleMessage: flagMessages.deleteFailureTitle,
					descriptionMessage: flagMessages.genericDescription,
					rawDescriptionMessage: errorMessages.join('\n\n'),
				}),
			}
		: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action = IssueDeleteRequestAction | IssueDeleteSuccessAction | IssueDeleteFailureAction;

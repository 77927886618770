import { useEffect, useRef } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';

export const useIsProjectType = ({
	onIsProjectTypeUpdated,
}: {
	onIsProjectTypeUpdated: (arg1: { isProjectType: boolean }) => void;
}) => {
	const [{ data: containerContext }] = useContainerContext();
	const isProjectType = containerContext?.contextType === 'project';
	const previousIsProjectType = useRef<boolean | undefined>();
	if (
		previousIsProjectType.current !== isProjectType &&
		fg('render_insights_button_immediately_when_ready')
	) {
		onIsProjectTypeUpdated({ isProjectType });
		previousIsProjectType.current = isProjectType;
	}

	useEffect(() => {
		if (fg('render_insights_button_immediately_when_ready')) {
			return;
		}
		onIsProjectTypeUpdated({ isProjectType });
	}, [isProjectType, onIsProjectTypeUpdated]);
};

import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { getUserShape } from '@atlassian/jira-issue-field-assignee/src/common/utils.tsx';
import type {
	ParentShape,
	HierarchyShape,
} from '@atlassian/jira-issue-field-parent-switcher/src/common/types.tsx';
import {
	getParentShape,
	getHierarchyShape,
} from '@atlassian/jira-issue-field-parent-switcher/src/common/utils.tsx';
import {
	HIERARCHY_LEVEL_ABOVE,
	type SUMMARY_TYPE,
	type ASSIGNEE_TYPE,
	type PARENT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SUMMARY_TYPE as SYSTEM_SUMMARY_TYPE,
	TIME_ESTIMATE_TYPE as SYSTEM_TIME_ESTIMATE_TYPE,
	ASSIGNEE_TYPE as SYSTEM_ASSIGNEE_TYPE,
	PARENT_TYPE as SYSTEM_PARENT_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { TimeEstimateKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { createTraitsSelector } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import type { Estimate } from '../../../../../model/issue/issue-types.tsx';
import type { PlatformCard } from '../../../../../services/issue/issue-data-transformer.tsx';
import {
	useIsCMPBoard,
	useIsJSWBoard,
} from '../../../../../state/state-hooks/capabilities/index.tsx';
import {
	USER_FETCH_URL,
	SUMMARY_CONFIG_TITLE,
	STORY_POINT_CONFIG_TITLE,
	ORIGINAL_ESTIMATE_CONFIG_TITLE,
	ASSIGNEE_CONFIG_TITLE,
	PARENT_CONFIG_TITLE,
} from './constants.tsx';
import type { StateProps } from './types.tsx';

type IssueFieldValues =
	| {
			[SUMMARY_TYPE]?: string;
			[HIERARCHY_LEVEL_ABOVE]?: HierarchyShape | null;
			[PARENT_TYPE]?: ParentShape | null;
			[ASSIGNEE_TYPE]?: UserValue;
	  }
	| { [key: string]: string }; // for estimate fields

type IssueFieldsInput = Pick<StateProps, 'assignee' | 'parent'> & {
	estimateExtended: Estimate;
	issue: PlatformCard;
};

export const transformToIssueFieldValues = ({
	issue: { summary },
	assignee,
	parent,
	estimateExtended,
}: IssueFieldsInput): IssueFieldValues => ({
	...{ summary },
	...(parent && {
		parent: getParentShape(
			String(parent.id),
			parent.key,
			parent.summary,
			{
				id: parent.issueType.id,
				name: parent.issueType.name,
				iconUrl: parent.issueType.iconUrl,
			},
			parent.color,
		),
		[HIERARCHY_LEVEL_ABOVE]: getHierarchyShape({
			id: parent.issueType.id,
			name: parent.issueType.name,
			iconUrl: parent.issueType.iconUrl,
		}),
	}),
	...(assignee && {
		assignee: getUserShape(assignee.id, assignee.displayName, assignee.avatarUrl || ''),
	}),
	...(estimateExtended &&
		estimateExtended.fieldId && {
			[estimateExtended.fieldId]: estimateExtended.value,
		}),
});

type IssueFieldConfigsInput = {
	estimateExtended: Estimate;
	isEditable: boolean;
	issue: PlatformCard;
};

export const transformToIssueFieldConfigValues = ({
	issue: { key, summary },
	estimateExtended,
	isEditable,
}: IssueFieldConfigsInput) => {
	const summaryConfig = {
		autoCompleteUrl: null,
		allowedValues: [],
		isEditable,
		isRequired: true,
		title: SUMMARY_CONFIG_TITLE,
		schema: {
			type: 'string',
			custom: null,
			customId: null,
			system: SYSTEM_SUMMARY_TYPE,
			renderer: 'jira-text-renderer',
		},
	};

	const storyPointConfig = {
		autoCompleteUrl: null,
		allowedValues: [],
		isEditable,
		isRequired: true,
		title: STORY_POINT_CONFIG_TITLE,
		schema: {
			type: 'number',
			custom: STORY_POINT_ESTIMATE_CF_TYPE,
			customId: estimateExtended?.fieldId
				? parseInt(estimateExtended.fieldId.replace('customfield_', ''), 10)
				: null,
			system: null,
			renderer: 'jira-text-renderer',
		},
	};

	const originalEstimateConfig = {
		autoCompleteUrl: null,
		allowedValues: [],
		isEditable,
		isRequired: true,
		title: ORIGINAL_ESTIMATE_CONFIG_TITLE,
		schema: {
			type: 'number',
			custom: null,
			customId: null,
			system: SYSTEM_TIME_ESTIMATE_TYPE,
			renderer: null,
		},
	};

	const assigneeConfig = {
		autoCompleteUrl: `${USER_FETCH_URL}?issueKey=${key}&query=`,
		allowedValues: [],
		isEditable,
		isRequired: true,
		title: ASSIGNEE_CONFIG_TITLE,
		schema: {
			type: 'user',
			custom: null,
			customId: null,
			system: SYSTEM_ASSIGNEE_TYPE,
			renderer: 'jira-text-renderer',
		},
	};

	const parentConfig = {
		autoCompleteUrl: null,
		allowedValues: [],
		isEditable,
		isRequired: true,
		title: PARENT_CONFIG_TITLE,
		schema: {
			type: 'array',
			custom: null,
			customId: null,
			system: SYSTEM_PARENT_TYPE,
			renderer: null,
		},
	};

	return {
		...(summary && {
			summary: summaryConfig,
		}),
		parent: parentConfig,
		assignee: assigneeConfig,
		...(estimateExtended?.fieldId &&
			TimeEstimateKey === estimateExtended.fieldId && {
				[estimateExtended.fieldId]: originalEstimateConfig,
			}),
		...(estimateExtended?.fieldId &&
			TimeEstimateKey !== estimateExtended.fieldId && {
				[estimateExtended.fieldId]: storyPointConfig,
			}),
	};
};

export const getShouldShowLabels = (
	viewSettingsShowLabels: boolean,
	isCMPBoard: boolean,
): boolean => {
	// TMP
	if (!isCMPBoard) {
		return viewSettingsShowLabels;
	}

	// CMP
	return false; // disregard viewSettings.showLabel in CMP to avoid double labels // CMP now renders labels from custom fields
};

// Helper function used to check personalisation traits to determine
// if a user has seen the JSW CMP Card cover change boarding before
// Returns true if the trait `jira-software_ui_context-menu_opened_card-actions_board-screen_first_time`
// is undefined and if the user was first active in Jira Software over 30 days ago.
// TODO: PNDR-2101 - Remove function when cleaning up changeboarding
export const useIsCardCoversNew = createTraitsSelector<'SITE_USER'>(
	'SITE_USER',
	({ getTraitByName, loaded }) => {
		if (!fg('jsw_cmp_card_covers_changeboarding')) {
			return false;
		}

		if (!loaded) {
			return false;
		}

		const jswFirstActiveDate = getTraitByName('jira-software_first_active');
		if (!jswFirstActiveDate) return false;

		const hasSeenCardCoverOnboarding = getTraitByName(
			'jira-software_ui_context-menu_opened_card-actions_board-screen_first_time',
		);

		const over30DaysAgo = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
		return (
			!hasSeenCardCoverOnboarding && new Date(String(jswFirstActiveDate.value)) < over30DaysAgo
		);
	},
);

// Helper function used to check if we should render change boarding for the
// new JSW CMP Card cover feature.
// TODO: PNDR-2101 - Remove function when cleaning up changeboarding
// eslint-disable-next-line jira/ff/inline-usage
export const useShouldShowCardCoverChangeboarding = () => {
	const [isCardCoversNew] = useIsCardCoversNew();
	const isJSWBoard = useIsJSWBoard() ?? false;
	const isCMPBoard = useIsCMPBoard() ?? false;

	if (!fg('jsw_cmp_card_covers_changeboarding')) {
		return false;
	}

	// Return false for ineligible boards
	if (!isJSWBoard || !isCMPBoard || !isCardCoversNew) {
		return false;
	}

	return expVal('jsw_cmp_card_cover_images_experiment', 'isCardCoverFeatureEnabled', false);
};

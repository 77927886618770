/** @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@compiled/react';
import floor from 'lodash/floor';
import isNil from 'lodash/isNil';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Popup from '@atlaskit/popup';
import { Box, Inline, xcss, Text } from '@atlaskit/primitives';
import { G400, R300, N0, Y500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	type SprintState,
	SPRINT_STATES,
} from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { Sprint, OverlappedSprint } from '../../common/types.tsx';
import { convertCapacity, getCapacityUnitMessage } from '../../common/utils.tsx';
import { useFormatDateRange } from '../../hooks/use-format-date/index.tsx';
import messages from './messages.tsx';
import SprintCapacityFlyout from './sprint-capacity-flyout/index.tsx';
import type { Props } from './types.tsx';

const getSprintStyles = (state: SprintState, isOverloaded: boolean) => {
	if (isOverloaded) {
		return xcss({
			backgroundColor: 'color.background.danger',
			color: 'color.text.danger',
			':hover': {
				backgroundColor: 'color.background.danger.hovered',
			},
		});
	}
	switch (state) {
		case SPRINT_STATES.CLOSED:
			if (!fg('increment_board_a11y_fix')) {
				return xcss({
					backgroundColor: 'color.background.neutral',
					color: 'color.text.disabled',
					':hover': {
						backgroundColor: 'color.background.neutral.hovered',
					},
				});
			}
			return xcss({
				backgroundColor: 'color.background.accent.gray.subtlest',
				color: 'color.text.subtlest',
				':hover': {
					backgroundColor: 'color.background.accent.gray.subtlest.hovered',
				},
			});
		case SPRINT_STATES.ACTIVE:
			return xcss({
				backgroundColor: 'color.background.selected',
				color: 'color.text.brand',
				':hover': {
					backgroundColor: 'color.background.selected.hovered',
				},
			});
		case SPRINT_STATES.FUTURE:
		default:
			return xcss({
				backgroundColor: 'color.background.accent.gray.subtler',
				color: 'color.text.subtle',
				':hover': {
					backgroundColor: 'color.background.neutral.pressed',
				},
			});
	}
};

const CapacityBar = ({
	totalCapacity,
	usedCapacity,
}: {
	totalCapacity: number;
	usedCapacity: number;
}) => {
	const isOverloadedBar = usedCapacity > totalCapacity;
	return (
		<div css={barWrapperStyle}>
			{isOverloadedBar ? (
				<div css={overloadedCapacityStyle} />
			) : (
				<div
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						height: `${totalCapacity === 0 ? 100 : (usedCapacity / totalCapacity) * 100}%`,
					}}
					css={usedCapacityStyle}
				/>
			)}
		</div>
	);
};

const OverlappedDatesWarningIcon = ({
	sprint,
	overlappedSprints,
}: {
	sprint: Sprint;
	overlappedSprints: OverlappedSprint[];
}) => {
	const { formatMessage } = useIntl();
	const warningIconTooltip = (() => {
		let content;
		if (overlappedSprints.length === 1) {
			content = formatMessage(messages.warningForSprintDatesOverlappedWithOneSprint, {
				sprintName: sprint.name,
				anotherSprintName: overlappedSprints[0].sprintName,
				days: floor(overlappedSprints[0].time / (24 * 60 * 60 * 1000), 1),
			});
		} else {
			content = formatMessage(messages.warningForSprintDatesOverlappedWithMoreThanOneSprint, {
				sprintName: sprint.name,
				otherSprints: overlappedSprints
					.slice(0, overlappedSprints.length - 1)
					.map((s) => s.sprintName)
					.join(', '),
				lastSprint: overlappedSprints[overlappedSprints.length - 1].sprintName,
			});
		}

		return (
			<Box xcss={WarningTooltip} backgroundColor="color.background.neutral.bold">
				<Text as="strong" size="UNSAFE_small">
					{formatMessage(messages.overlappingDatesWarningTitle)}
				</Text>
				<Box xcss={tooltipParagraphStyles}>
					<Text as="p" size="UNSAFE_small">
						{content}
					</Text>
				</Box>
			</Box>
		);
	})();
	return (
		<Box
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				marginTop: 'space.025',
			})}
			testId="portfolio-3-plan-increment-common.ui.sprint-column-header.overlapping-warning-icon"
		>
			<Tooltip content={warningIconTooltip}>
				<WarningIcon
					primaryColor={token('color.icon.warning', Y500)}
					label={formatMessage(messages.overlappingDatesWarningLabel)}
				/>
			</Tooltip>
		</Box>
	);
};

const SprintColumnHeader = ({
	sprint,
	usedCapacity,
	doneCapacity,
	totalCapacity,
	defaultCapacity,
	planningConfig,
	overlappedSprints,
	columnDateRange,
	onChangeCapacity,
	isReadOnly,
}: Props) => {
	const { planningUnit, workingHoursPerDay } = planningConfig;
	const { formatMessage } = useIntl();
	const formatDateRange = useFormatDateRange();
	const [flyoutOpen, setFlyoutOpen] = useState(false);

	const capacity = convertCapacity(
		totalCapacity ?? defaultCapacity,
		planningUnit,
		workingHoursPerDay,
	);

	const getSprintStateLabel = (state: SprintState) => {
		switch (state) {
			case SPRINT_STATES.CLOSED:
				return formatMessage(messages.completedSprint);
			case SPRINT_STATES.ACTIVE:
				return formatMessage(messages.activeSprint);
			case SPRINT_STATES.FUTURE:
			default:
				return formatMessage(messages.futureSprint);
		}
	};

	const endDate = sprint.completedDate ? sprint.completedDate : sprint.endDate;

	const dateRange =
		sprint.startDate && endDate ? (
			<>
				{formatDateRange(sprint.startDate, endDate)}
				<br />
			</>
		) : null;

	const capacityAllocatedLabel = (
		<>
			{formatMessage(messages.capacityAllocatedLabel, {
				usedCapacity,
				totalCapacity: capacity,
				unit: getCapacityUnitMessage(planningUnit, formatMessage, capacity),
			})}
			<br />
			{getSprintStateLabel(sprint.state)}
		</>
	);
	const tooltipContent = (
		<Box xcss={tooltipStyles} backgroundColor="color.background.neutral.bold">
			<Text as="strong" size="UNSAFE_small">
				{sprint.name}
			</Text>
			<Text as="p" size="UNSAFE_small">
				{dateRange}
			</Text>
			<Box xcss={tooltipParagraphStyles}>
				<Text
					as="p"
					size="UNSAFE_small"
					testId="portfolio-3-plan-increment-common.ui.sprint-column-header.allocated"
				>
					{capacityAllocatedLabel}
				</Text>
			</Box>
		</Box>
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Popup
			isOpen={flyoutOpen}
			onClose={() => setFlyoutOpen(false)}
			placement="bottom-start"
			content={() => (
				<SprintCapacityFlyout
					onFlyoutClose={() => setFlyoutOpen(false)}
					usedCapacity={usedCapacity}
					totalCapacity={totalCapacity}
					doneCapacity={doneCapacity}
					defaultCapacity={defaultCapacity}
					sprint={sprint}
					planningConfig={planningConfig}
					iterationEndDate={columnDateRange?.end}
					iterationStartDate={columnDateRange?.start}
					onChangeCapacity={onChangeCapacity}
					isReadOnly={isReadOnly}
				/>
			)}
			trigger={(triggerProps) => (
				<Button
					onClick={() => {
						setFlyoutOpen(true);
						fireUIAnalytics(
							createAnalyticsEvent({ action: 'opened', actionSubject: 'sprintFlyout' }),
						);
					}}
					appearance="subtle"
					spacing="none"
					shouldFitContainer
					testId="portfolio-3-plan-increment-common.ui.sprint-column-header.sprint-column-header"
					{...triggerProps}
				>
					<Box xcss={[columnHeaderWrapper, sprintWrapperStyles]}>
						<Tooltip content={tooltipContent} hideTooltipOnClick>
							<Inline
								alignBlock="center"
								// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
								xcss={[sprintBaseStyles, getSprintStyles(sprint.state, usedCapacity > capacity)]}
							>
								<CapacityBar usedCapacity={usedCapacity} totalCapacity={capacity} />
								<div css={sprintNameWrapperStyle}>{sprint.name}</div>
								{!isNil(overlappedSprints) && (
									<OverlappedDatesWarningIcon
										sprint={sprint}
										overlappedSprints={overlappedSprints}
									/>
								)}
							</Inline>
						</Tooltip>
					</Box>
				</Button>
			)}
		/>
	);
};

const tooltipStyles = xcss({
	textAlign: 'center',
});

const tooltipParagraphStyles = xcss({
	marginTop: 'space.150',
});

const sprintWrapperStyles = xcss({
	padding: 'space.050',
});

const columnHeaderWrapper = xcss({
	backgroundColor: 'elevation.surface.sunken',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});

const sprintBaseStyles = xcss({
	fontWeight: '500',
	height: token('space.400', '32px'),
	borderRadius: 'border.radius.100',
	padding: 'space.075',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const barWrapperStyle = css({
	height: token('space.250', '20px'),
	width: '5px',
	borderRadius: '3px',
	backgroundColor: token('elevation.surface', N0),
	display: 'flex',
	alignItems: 'flex-end',
	marginRight: token('space.075', '6px'),
});

const usedCapacityStyle = css({
	borderRadius: '5px',
	width: '100%',
	background: token('color.background.success.bold', G400),
});

const overloadedCapacityStyle = css({
	borderRadius: '5px',
	width: '100%',
	background: token('color.background.danger.bold', R300),
	height: '100%',
});

const sprintNameWrapperStyle = css({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	flex: '1',
	textAlign: 'left',
});

const WarningTooltip = xcss({
	padding: 'space.050',
});

export default SprintColumnHeader;

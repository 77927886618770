import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { NewVersionReleaseModalType } from './ui/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNewVersionReleaseModal = lazy(() =>
	import(/* webpackChunkName: "async-software-new-version-release-modal" */ './ui').then(
		(NewVersionReleaseModal) => NewVersionReleaseModal,
	),
);

const NewVersionReleaseModalAsync = (props: NewVersionReleaseModalType) => (
	<JSErrorBoundary
		id="async-jira-software-new-version-release-modal"
		packageName="jiraSoftwareNewVersionReleaseModal"
		teamName="a4t-tanuki"
		fallback="flag"
	>
		<Placeholder name="software-new-version-release-modal" fallback={<></>}>
			<LazyNewVersionReleaseModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default NewVersionReleaseModalAsync;

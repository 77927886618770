import React from 'react';
import noop from 'lodash/noop';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { ExperienceTrackerProvider } from '@atlassian/jira-experience-tracker/src/ui/experience-tracker-provider/index.tsx';
import {
	JIRA_INSIGHTS,
	INSIGHTS_PANEL_EXPERIENCE,
	EXPERIENCE_TIMEOUT_MILLIS,
	ERROR_BOUNDARY_HIT,
} from '@atlassian/jira-insights-common-constants/src/common/constants/analytics.tsx';
import {
	INSIGHTS_PACKAGE,
	INSIGHTS_PACKAGE_BOUNDARY_ID,
} from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
import {
	InsightsExperienceTracker,
	useInsightsExperience,
} from '@atlassian/jira-insights-experience-tracker/src/ui/index.tsx';
import type { InsightsErrorBoundaryProps, SafeInsightsErrorBoundaryProps } from './types.tsx';

const insightsExperience = { experience: JIRA_INSIGHTS } as const;

export const InsightsErrorBoundary = ({
	id,
	packageName,
	children,
	onError = noop,
}: InsightsErrorBoundaryProps) => {
	return (
		<JSErrorBoundary
			id={id}
			packageName={packageName}
			teamName="helios-headcoach"
			fallback="flag"
			onError={onError}
		>
			{children}
		</JSErrorBoundary>
	);
};

const BaseInsightsErrorBoundaryWithExperienceTracker = ({
	children,
}: SafeInsightsErrorBoundaryProps) => {
	const onFail = useExperienceFail(insightsExperience);
	const { fail } = useInsightsExperience();

	return (
		<InsightsErrorBoundary
			id={INSIGHTS_PACKAGE_BOUNDARY_ID}
			packageName={INSIGHTS_PACKAGE}
			onError={(...args) => {
				onFail(...args);
				const [, error] = args;
				fail(ERROR_BOUNDARY_HIT, error);
			}}
		>
			{children}
		</InsightsErrorBoundary>
	);
};

// can this be deleted?
export const InsightsErrorBoundaryWithExperienceTrackerDeprecated = ({
	children,
	experienceIdsToTrack = [],
}: SafeInsightsErrorBoundaryProps) => (
	<InsightsExperienceTracker
		experienceId={INSIGHTS_PANEL_EXPERIENCE}
		experienceIdsToCollect={experienceIdsToTrack || []}
		timeoutInMs={EXPERIENCE_TIMEOUT_MILLIS}
	>
		<ExperienceTrackerProvider>
			<BaseInsightsErrorBoundaryWithExperienceTracker>
				{children}
			</BaseInsightsErrorBoundaryWithExperienceTracker>
		</ExperienceTrackerProvider>
	</InsightsExperienceTracker>
);

export const WithInsightsErrorBoundary =
	<Props extends object>(Component: React.ComponentType<Props>, id: string, packageName: string) =>
	(props: Props) => {
		return (
			<InsightsErrorBoundary id={id} packageName={packageName}>
				<Component {...props} />
			</InsightsErrorBoundary>
		);
	};

import type { DeploymentDetails } from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { IssueId, IssueLink } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FieldEntry } from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import type { JiraErrorCollection } from '../../rest/software/software-api-error.tsx';
import type { ColumnId } from '../column/column-types.tsx';
import type {
	ISODateTime,
	StatusId,
	ProjectId,
	ColumnKey,
	CardTypeKey,
	RapidViewId,
	TransitionId,
	Status,
} from '../software/software-types.tsx';
import type { EstimationStatisticFieldId } from '../work/work-types.tsx';

export type IssueTypeId = number;

export type SwimlaneId = string;

export type IssueKey = string;

export type DevStatusActivity =
	| 'branch'
	| 'commit'
	| 'pullrequest'
	| 'declined'
	| 'merged'
	| 'deployment'
	| 'design';

export type GqlDevStatusActivity =
	| 'PR_OPEN'
	| 'PR_MERGED'
	| 'PR_DECLINED'
	| 'BRANCH_OPEN'
	| 'COMMIT'
	| 'DEPLOYMENT'
	| 'DESIGN';

export type DevStatus = {
	activity: DevStatusActivity;
	count: number;
	deploymentDetails?: DeploymentDetails;
};

export type DevStatusMap = Record<IssueKey, DevStatus>;

export type Priority = {
	name: string;
	iconUrl: string;
};

export type IssueCustomFields = { [x: string]: null | string | number | string[] | number[] };

export const TMP_STORY_POINTS = 'TMP_STORY_POINTS';
export const TMP_ORIGINAL_TIME_ESTIMATE = 'TMP_ORIGINAL_TIME_ESTIMATE';
export const CUSTOM_ESTIMATE_FIELD = 'CUSTOM_ESTIMATE_FIELD';
export const INCREMENT_PLANNING_ESTIMATE_FIELD = 'INCREMENT_PLANNING_ESTIMATE_FIELD';

export type Estimate =
	| {
			type: typeof TMP_STORY_POINTS;
			displayName?: MessageDescriptor;
			fieldId: EstimationStatisticFieldId;
			value?: number;
	  }
	| {
			type: typeof TMP_ORIGINAL_TIME_ESTIMATE;
			displayName?: MessageDescriptor;
			fieldId: EstimationStatisticFieldId;
			value?: number;
			valueAsText?: string;
	  }
	| {
			// All estimate types in CMP considered as CUSTOM_ESTIMATE_FIELD
			type: typeof CUSTOM_ESTIMATE_FIELD;
			displayName: string | MessageDescriptor;
			fieldId: EstimationStatisticFieldId;
			/**
			 * Estimation string may or may not be a number
			 */
			value?: string;
	  }
	| {
			// Estimate type for Incremental Planning board
			type: typeof INCREMENT_PLANNING_ESTIMATE_FIELD;
			displayName?: string | MessageDescriptor;
			fieldId: string;
			value: string | number;
	  }
	| null;

export type Issue = {
	id: IssueId;
	key: IssueKey;
	summary: string;
	statusId: StatusId;
	columnId: ColumnId;
	projectId: ProjectId;
	typeId: IssueTypeId;
	requestTypeId?: string;
	typeName?: string;
	typeUrl?: string;
	assigneeAccountId?: string | null;
	devStatus?: DevStatus;
	isFlagged?: boolean;
	isVisible?: boolean;
	labels: string[];
	swimlaneId?: SwimlaneId; // KODAMA-118: swimlane ID will either be string or "0",
	isDone: boolean;
	parentId: IssueId | null;
	estimate: Estimate;
	priority: Priority | null;
	dueDate: ISODateTime | null;
	numCompleteChildren: number;
	numTotalChildren: number;
	sprintId: string;
	// CMP only fields
	daysInColumn?: number | null;
	extraFields?: FieldEntry[];
	cardColor?: string;
	fixVersions?: number[];
	// BOF IncrementalPlanning board only fields
	hasScenarioChanges?: boolean | null;
	issueLinks?: IssueLink[] | null;
	lexoRank?: string;
	teamId?: string | null;
	startDate?: ISODateTime | null;
	color?: Color | null;
	customFields?: IssueCustomFields;
	// EOF IncrementalPlanning board only fields
};

export type PartialIssue = {
	id: IssueId;
	key: string;
	parentId: IssueId | null;
	projectId?: ProjectId;
};

export type CoreIssue = {
	id: IssueId;
	key: IssueKey;
	summary: string;
	typeUrl?: string | null;
	parentId?: IssueId | null;
	assigneeAccountId?: string | null;
	status: Status;
	isFlagged?: boolean;
	typeId?: IssueTypeId | null;
	projectId?: ProjectId | null;
	statusId?: StatusId | null;
};

export type IssueArray = Issue[];

// @todo it is not IssueKey - it is Stringified version of IssueId
export type IssueEntities = Record<IssueKey, Issue>;

export type IssueParentId = number;
export type IssueParent = {
	id: IssueId;
	key: IssueKey;
	summary: string;
	status?: Status;
	issueType: {
		iconUrl: string;
		id: CardTypeKey;
		name: string;
		hierarchyLevel?: number;
	};
	color: Color | undefined;
	projectId?: ProjectId;
	flagged?: boolean;
};

export type IssueParentEntities = Record<IssueKey, IssueParent>;

export type IssueCreateRequestPayload = {
	fields: {
		summary: string;
		project: {
			id: string;
		};
		issuetype: {
			id: string;
		};
		transition?: {
			id: string;
		};
	};
};

export type IssueCreateResponsePayload = {
	id: string;
	key: IssueKey;
	self: string;
	transition: {
		status: number;
		errorCollection: JiraErrorCollection;
	};
	postUpdate: {
		status: number;
		errorCollection: JiraErrorCollection;
	};
	issueLists?: {
		status: number;
		errorCollection: JiraErrorCollection;
	};
};

export type IssueLoadResponsePayload = {
	id: string;
	key: string;
	fields: {
		summary: string;
		status: {
			id: string;
		};
		project: {
			id: string;
		};
		assignee: {
			name: string;
			accountId: string;
		} | null;
		issuetype: {
			id: string;
			name: string;
			iconUrl: string;
		};
		priority: {
			name: string;
			iconUrl: string;
		};
		duedate: string | null;
		labels: string[];
		resolutiondate: string;
	};
};

export type DevActivityDataType = 'branch' | 'pullrequest' | 'deployment' | 'repository' | 'design'; // i.e. commits

export type LatestDevActivity = {
	dataType: DevActivityDataType;
	count: number;
	lastUpdated: string;
	stateCount?: number;
	state?: 'OPEN' | 'MERGED' | 'DECLINED';
	open?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LatestDevActivityMap = Record<any, any> | Record<IssueKey, LatestDevActivity>;

export type DevStatusResponsePayload = {
	activity: LatestDevActivityMap;
};

export type IssuesByColumnMap = Record<ColumnId, Issue[]>;

export type IssuesBySwimlaneMap = Record<SwimlaneId, IssuesByColumnMap>;

export type IssuesAmountByColumn = Record<ColumnKey, number>;

export type IssueTransitionAndRankRequestPayload = {
	rapidViewId: RapidViewId;
	issueKeys: IssueKey[];
	rankCustomFieldId: number;
	rankBeforeIssueIdOrKey: IssueId | null | undefined;
	rankAfterIssueIdOrKey: IssueId | null | undefined;
	targetColumn?: ColumnId;
	selectedTransitionId?: TransitionId | null | undefined;
};

export type IssueTransitionRequestPayload = {
	rapidViewId: RapidViewId;
	issueKeys: IssueKey[];
	targetColumn: ColumnId;
	selectedTransitionId: TransitionId;
};

export type BulkIssuesRankRequestPayload = {
	customFieldId: number;
	issueIds: IssueId[];
	issueKeys: IssueKey[];
	rankAfterKey?: IssueKey | null;
	rankBeforeKey?: IssueKey | null;
	rankAfterId?: IssueId | null;
	rankBeforeId?: IssueId | null;
};

export type BulkIssueRankResponse = {
	rankableChanges: [
		{
			issueId: IssueId;
			issueKey: IssueKey;
			wasChanged: boolean;
			errors: string[];
		},
	];
};

export type IssueTransitionAndRankResponse = {
	issuesWereTransitioned: boolean;
};

export type HierarchyNomenclature = {
	firstLevelName: string;
};

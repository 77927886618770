// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { miniMapMapHeight as mapHeight } from '../../../common/constants/styles/index.tsx';
import InnerView from './inner-view/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativePositionStyle = styled2.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MinimapStyleOld = styled.div({
	display: 'inline-flex',
	padding: token('space.075', '6px'),
	background: token('elevation.surface.overlay', 'white'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `4px 8px 14px 0 ${colors.N50A}`),
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MinimapStyleNew = styled2.div({
	display: 'inline-flex',
	padding: token('space.075', '6px'),
	background: token('elevation.surface.overlay', 'white'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: token('elevation.shadow.overlay', `4px 8px 14px 0 ${colors.N50A}`),
	borderRadius: '8px',
});

const MinimapStyle = componentWithFF(
	'compiled.migration.jsw.tanuki',
	MinimapStyleNew,
	MinimapStyleOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const OuterViewStyleOld = styled.div<{ outerWidth: string | number }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${props.outerWidth}px`,
	border: `1px solid ${token('elevation.surface.overlay', 'white')}`,
	borderRadius: '3px',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${mapHeight}px`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OuterViewStyleNew = styled2.div<{ outerWidth: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ outerWidth }) => `${outerWidth}px`,
	borderWidth: 1,
	borderRadius: '3px',
	borderColor: token('elevation.surface.overlay', 'white'),
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: mapHeight,
});

const OuterViewStyle = componentWithFF(
	'compiled.migration.jsw.tanuki',
	OuterViewStyleNew,
	OuterViewStyleOld,
);

export type Props = {
	children: ReactNode;
	innerOffset: number;
	innerWidth: number;
	outerWidth: number;
	onScroll: (newOffset: number) => void;
	onScrollAttempted: (scrollChanged: boolean) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Minimap extends PureComponent<Props> {
	render() {
		return (
			<MinimapStyle data-testid="platform-board-kit.ui.minimap">
				<RelativePositionStyle>
					<InnerView
						onScroll={this.props.onScroll}
						onScrollAttempted={this.props.onScrollAttempted}
						innerOffset={this.props.innerOffset}
						innerWidth={this.props.innerWidth}
					/>
				</RelativePositionStyle>
				<OuterViewStyle outerWidth={this.props.outerWidth}>{this.props.children}</OuterViewStyle>
			</MinimapStyle>
		);
	}
}

/*
MinimapStyle is exported to deconflict the Minimap from the Quickstart button. Both components are displayed in the lower
right corner of the UI. Since Minimap is rendered conditionally based on board context, the Quickstart button cannot know
when it needs to move. Therefore, we are shifting the Minimap css style if the Quickstart button is rendered. This ensures
that the UI elements do not overlap when both are rendered.

This solution is temporary during the Quickstart V1 experiment expected to run from May 2020 through July 2020.

Cleanup/Tech Debt ticket:
https://hello.atlassian.net/browse/NUX-1208

Consumer:
    src/packages/software/onboarding-quickstart/src/ui/open-button/deconflict-hack.js
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export { MinimapStyle };

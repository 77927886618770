import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AfterPaintPrefetchIssues = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-prefetch-issues" */ '../index'),
	{ ssr: false },
);

export const AsyncPrefetchIssues = () => (
	<ErrorBoundary
		id="async.software-board.prfetch-issues"
		packageName="jiraSoftwareBoardPrefetchIssues"
	>
		<Placeholder name="prefetch-issues" fallback={null}>
			<AfterPaintPrefetchIssues />
		</Placeholder>
	</ErrorBoundary>
);

export default AsyncPrefetchIssues;

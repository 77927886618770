export const BOARD = 'BOARD' as const;
export const BACKLOG = 'BACKLOG' as const;
export const ROADMAPS = 'ROADMAPS' as const;
export const UNKNOWN = 'UNKNOWN' as const;

export type CacheInvalidationType =
	| typeof BOARD
	| typeof BACKLOG
	| typeof ROADMAPS
	| typeof UNKNOWN;

export type Resource = {
	params: {
		[key: string]: string | undefined | null;
	};
	refresh: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	update: (arg1: (oldData: any | null) => any | null) => void;
};

export type ResourcesCacheUpdateRequest = {
	origin: CacheInvalidationType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any | null;
};

export const RESOURCES_CACHE_UPDATER_SOURCE = 'RESOURCES_CACHE_UPDATER_SOURCE' as const;

export type ResourcesCacheUpdateSource = typeof RESOURCES_CACHE_UPDATER_SOURCE;

export const RESOURCES_CACHE_UPDATER_SOURCE_KEY = 'resourcesCacheSource';

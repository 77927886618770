import React, { type RefObject, type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import memoizeOne from 'memoize-one';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import TeamsIcon from '@atlaskit/icon/core/teams';
import PeopleIconOld from '@atlaskit/icon/glyph/people';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ToolTip from '@atlaskit/tooltip';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	IntlShapeV2 as IntlShape,
	MessageDescriptorV2 as MessageDescriptor,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import Swimlane from '@atlassian/jira-platform-board-kit/src/ui/swimlane/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import {
	KANBAN_TEAM,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	truncateUnscheduledWorkSwimlaneId,
	appendUnscheduledWorkSwimlaneIdSuffix,
} from '../../../../common/utils/increment-planning/index.tsx';
import type { IssueParent } from '../../../../model/issue/issue-types.tsx';
import type { Person } from '../../../../model/people/people-types.tsx';
import type { Status } from '../../../../model/software/software-types.tsx';
import {
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_CHILDLESS,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_TEAM,
	SWIMLANE_BY_REQUEST_TYPE,
} from '../../../../model/swimlane/swimlane-modes.tsx';
import {
	type SwimlaneId,
	ChildlessSwimlane,
	TEAM,
	type SwimlaneValues,
} from '../../../../model/swimlane/swimlane-types.tsx';
import { openIssueModal } from '../../../../state/actions/issue/modal/index.tsx';
import { swimlaneToggle } from '../../../../state/actions/swimlane/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import { columnByStatusIdSelector } from '../../../../state/selectors/column/column-selectors.tsx';
import {
	firstParentIssueTypeNameSelector,
	getIssueParents,
} from '../../../../state/selectors/issue-parent/index.tsx';
import { isDoneIssueSelector } from '../../../../state/selectors/issue/issue-selectors.tsx';
import { getPerson } from '../../../../state/selectors/people/people-selectors.tsx';
import {
	rapidViewIdSelector,
	projectIdSelector,
	getIssues,
	getIsCMPBoard,
	getMissingParents,
	getIsIncrementPlanningBoard,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { isSwimlaneCollapsed } from '../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import {
	platformSwimlaneSelector,
	makeGetIssueParentForSwimlaneHeader,
} from '../../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import SprintAssociationButton from './increment-planning-buttons/sprint-association-btn/index.tsx';
import messages from './messages.tsx';

type OwnProps = {
	isFirstSwimlane: boolean;
	id: SwimlaneId;
	customRightContent: ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	intl: IntlShape;
	wrapperStyle?: React.CSSProperties;
	innerRef?: RefObject<HTMLDivElement>;
	hasShadow?: boolean;
	isUnscheduledWorkColumnPanel?: boolean;
};

type DispatchProps = {
	openIssueModal: typeof openIssueModal;
	swimlaneToggle: typeof swimlaneToggle;
};

const getExternalValues = memoize(
	(boardId, projectId) => ({
		boardId,
		projectId,
	}),
	(boardId, projectId) => `${boardId}.${projectId}`,
);

const getIcon = (
	isCMPBoard: boolean,
	swimlaneMode?: SwimlaneModeId | null,
	imageUrl?: string | null,
) => {
	if (!imageUrl) return null;
	if (
		swimlaneMode === SWIMLANE_BY_ASSIGNEE.id ||
		swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id
	) {
		return <Avatar size="small" src={imageUrl} name="" />;
	}
	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id || swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		return <IssueTypeIcon src={imageUrl} alt="" />;
	}

	if (isCMPBoard && swimlaneMode === SWIMLANE_BY_PROJECT.id) {
		return <ProjectIcon src={imageUrl} alt="" />;
	}

	if (swimlaneMode === SWIMLANE_BY_REQUEST_TYPE.id) {
		return <RequestTypeIcon src={imageUrl} alt="" />;
	}

	return null;
};

/**
 * Get team icon for increment planning board
 * @param imageUrl team avatar URL
 * @param name team name
 * @returns
 */
const getTeamIcon = (imageUrl?: string | null, name?: string | null) => {
	if (imageUrl) {
		return (
			<Avatar
				size="small"
				src={imageUrl}
				name={fg('ip_remove_aria_labels_for_decorative_images') ? undefined : name || ''}
			/>
		);
	}
	return (
		<PeopleIconWrapper>
			{fg('enghealth-12479-jsw-board-visual-refresh') ? (
				<TeamsIcon
					label={fg('ip_remove_aria_labels_for_decorative_images') ? '' : name || ''}
					LEGACY_size="small"
					LEGACY_fallbackIcon={PeopleIconOld}
					color={token('color.icon')}
				/>
			) : (
				<PeopleIconOld label={name || ''} size="small" />
			)}
		</PeopleIconWrapper>
	);
};

/**
 * Get team lozenge for increment planning board
 * @param intl
 * @param isExternal if it's team not included in Advanced Roadmaps plan
 * @param isPlanOnly if it's team only exist in Advanced Roadmaps plan, ie doesn't exist in Teams in Jira
 * @param isTeamLessSwimlane if it's a swimlane for issues with no team
 * @returns
 */
const getTeamLozenge = (
	intl: IntlShape,
	isExternal: boolean,
	isPlanOnly: boolean,
	isTeamLessSwimlane: boolean,
	isKanban: boolean,
) => {
	const lozengeContents = {
		external: {
			lozenge: planCommonMessages.externalLozenge,
			tooltipTitle: planCommonMessages.externalTeamTooltipTitle,
			tooltipContent: planCommonMessages.externalTeamTooltipContent,
			testId: 'software-board.board-container.board.swimlane.external',
		},
		planOnly: {
			lozenge: planCommonMessages.planOnlyLozenge,
			tooltipTitle: planCommonMessages.planOnlyTeamTooltipTitle,
			tooltipContent: planCommonMessages.planOnlyTeamTooltipContent,
			testId: 'software-board.board-container.board.swimlane.plan-only',
		},
		kanban: {
			lozenge: planCommonMessages.kanbanLozenge,
			tooltipTitle: undefined,
			tooltipContent: undefined,
			testId: 'software-board.board-container.board.swimlane.kanban',
		},
	};

	let lozengeContent;
	if (isExternal) {
		lozengeContent = lozengeContents.external;
	} else if (isKanban) {
		lozengeContent = lozengeContents.kanban;
	} else if (isPlanOnly && !isTeamLessSwimlane) {
		lozengeContent = lozengeContents.planOnly;
	}

	if (lozengeContent) {
		const { tooltipTitle, tooltipContent, lozenge, testId } = lozengeContent;
		const lozengeComponent = <Lozenge testId={testId}>{intl.formatMessage(lozenge)}</Lozenge>;
		if (tooltipTitle && tooltipContent) {
			const tooltip = (
				<Box padding="space.100" xcss={teamTooltipStyles}>
					<Text as="p" color="inherit">
						<Text as="strong">{intl.formatMessage(tooltipTitle)}</Text>
						<Text as="p">{intl.formatMessage(tooltipContent)}</Text>
					</Text>
				</Box>
			);
			return <ToolTip content={tooltip}>{lozengeComponent}</ToolTip>;
		}
		return lozengeComponent;
	}
	return null;
};

const SPOTLIGHT_TARGET_ID = 'board-swimlane-header';

export const getIssuesWithoutSwimlaneHeaderName = (
	message: MessageDescriptor,
	intl: IntlShape,
	swimlaneMode: SwimlaneModeId | null | undefined,
	state: State,
): string => {
	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id) {
		return intl.formatMessage(message, {
			issueParentTypeName: firstParentIssueTypeNameSelector(state),
		});
	}
	if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		return intl.formatMessage(message);
	}
	return intl.formatMessage(message);
};

export const getSwimlaneHeaderIssueStatus = (
	state: State,
	mode: string | undefined,
	issueId: string,
): Status | null => {
	if (mode === SWIMLANE_BY_PARENT_ISSUE.id) {
		const swimlaneHeaderIssue = getIssueParents(state)[issueId];
		return swimlaneHeaderIssue && swimlaneHeaderIssue.status ? swimlaneHeaderIssue.status : null;
	}

	// In CMP Kanplan, in a case of parent task in plan board but its subtasks in kanban board
	// when grouping by stories, swimlane header should look up in the the parent tasks (aka missing parent)
	if (getIsCMPBoard(state) && mode === SWIMLANE_BY_SUBTASK.id) {
		const swimlaneHeaderMissingParent = getMissingParents(state)[Number(issueId)];
		if (swimlaneHeaderMissingParent) {
			return swimlaneHeaderMissingParent.status;
		}
	}

	const swimlaneHeaderIssue = getIssues(state)[issueId];

	// Fetch the column that contains the given statusId
	const swimlaneHeaderIssueColumn =
		swimlaneHeaderIssue && swimlaneHeaderIssue.statusId
			? // @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
				columnByStatusIdSelector(state, swimlaneHeaderIssue.statusId)
			: null;

	// Get the status for the swimlane header issue
	return (
		(swimlaneHeaderIssueColumn && swimlaneHeaderIssueColumn.type === ColumnType.STATUS
			? swimlaneHeaderIssueColumn.statuses
			: []
		).find((status) => swimlaneHeaderIssue && status.id === swimlaneHeaderIssue.statusId) || null
	);
};

export const getSwimlaneSummaryTooltip = (
	swimlaneId: SwimlaneId,
	mode: SwimlaneModeId | null | undefined,
	intl: IntlShape,
) => {
	if (mode === SWIMLANE_BY_SUBTASK.id && swimlaneId === SWIMLANE_CHILDLESS) {
		return intl.formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.childlessTooltipIssueTermRefresh
				: messages.childlessTooltip,
		);
	}
	return null;
};

export const mapStateToProps = () => {
	// memoization is used instead of weak map due to keys being potentially null
	const getIconFactory = memoizeOne(
		(
			isIncrementPlanningBoard: boolean,
			swimlaneMode: SwimlaneModeId | null | undefined,
			imageUrl: string | null | undefined,
			name: string | null | undefined,
			isCMPBoard: boolean,
		) =>
			isIncrementPlanningBoard && swimlaneMode === SWIMLANE_BY_TEAM.id
				? getTeamIcon(imageUrl, name)
				: getIcon(isCMPBoard, swimlaneMode, imageUrl),
	);
	const getTeamLozengeFactory = memoizeOne(
		(
			isIncrementPlanningBoard: boolean,
			values: SwimlaneValues | undefined,
			intl: IntlShape,
			id: string,
		) =>
			isIncrementPlanningBoard && values && values.type === TEAM
				? getTeamLozenge(
						intl,
						!values.isPlanTeam,
						isNil(values.externalId),
						id === SWIMLANE_TEAMLESS ||
							id === appendUnscheduledWorkSwimlaneIdSuffix(SWIMLANE_TEAMLESS),
						values.planningStyle === KANBAN_TEAM,
					)
				: null,
	);
	const customLeftContentFactory = memoizeOne(
		(
			isIncrementPlanningBoard: boolean,
			id: string,
			isIncrementPlanningBoardReadOnly: boolean,
			isUnscheduledWorkColumnPanel?: boolean,
		) => {
			return (
				!isUnscheduledWorkColumnPanel &&
				isIncrementPlanningBoard &&
				!isIncrementPlanningBoardReadOnly && <SprintAssociationButton teamId={id} />
			);
		},
	);

	const issueParentFactory = memoizeOne((issueParent: IssueParent | null) =>
		issueParent ? { summary: issueParent.summary, color: issueParent.color } : null,
	);
	const assigneeFactory = memoizeOne((assignee: Person | null) =>
		assignee
			? {
					avatarUrl: assignee.avatarUrl,
					name: assignee.displayName,
				}
			: null,
	);
	return (state: State, ownProps: OwnProps) => {
		const { intl, isUnscheduledWorkColumnPanel } = ownProps;
		const swimlaneId = truncateUnscheduledWorkSwimlaneId(ownProps.id, isUnscheduledWorkColumnPanel);
		const {
			imageUrl,
			name,
			message,
			cardAmount,
			issueKey,
			mode,
			assigneeAccountId,
			projectKey,
			isFlagged,
			values,
		} = platformSwimlaneSelector(state)(swimlaneId) || {};

		const swimlaneMode = getSwimlaneMode(state);
		const isSwimlaneHeaderClickable =
			mode === SWIMLANE_BY_PARENT_ISSUE.id ||
			mode === SWIMLANE_BY_SUBTASK.id ||
			mode === SWIMLANE_BY_PROJECT.id;
		const issuesWithoutSwimlaneHeaderName = message
			? getIssuesWithoutSwimlaneHeaderName(message, intl, swimlaneMode, state)
			: null;

		const getParent = makeGetIssueParentForSwimlaneHeader(state);
		const issueParent = getParent(ownProps.id);

		const assignee =
			mode === SWIMLANE_BY_SUBTASK.id && assigneeAccountId
				? getPerson(state, assigneeAccountId)
				: null;
		const issueStatus = getSwimlaneHeaderIssueStatus(state, mode, ownProps.id);
		const isCMPBoard = getIsCMPBoard(state);

		// isDone here is used by the Swimlane component to show issue key strikethrough i.e. indicate issue resolution.
		// Moving away from `isSwimlaneBySubtaskParentDoneSelector` as it's very specific to covering all the edge cases required to correctly show the "Move to Done" button. It incorrectly strikethroughs issues when the issue is not resolved.
		// This change will also strikethrough here correctly for TMP (which previously we weren't doing at all).
		const isDone = isDoneIssueSelector(state)(ownProps.id);

		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		const isIncrementPlanningBoardReadOnly = isIncrementPlanningReadOnly(state);

		// Get team icon on increment planning board and swimlaneMode is SWIMLANE_BY_TEAM
		const icon = getIconFactory(isIncrementPlanningBoard, swimlaneMode, imageUrl, name, isCMPBoard);

		// Get team lozenge on increment planning board
		const lozenge = getTeamLozengeFactory(isIncrementPlanningBoard, values, intl, ownProps.id);

		return {
			icon,
			spotlightTargetId:
				ownProps.isFirstSwimlane && mode === SWIMLANE_BY_PARENT_ISSUE.id ? SPOTLIGHT_TARGET_ID : '',
			name: name || issuesWithoutSwimlaneHeaderName,
			// Don't show card amount for increment planning board
			showCardAmount: !isIncrementPlanningBoard,
			cardAmount,
			issueKey,
			issueStatus,
			isSwimlaneHeaderClickable,
			collapsed: isSwimlaneCollapsed(state, ownProps.id),
			externalId: 'software.board.swimlane',
			externalValues: getExternalValues(rapidViewIdSelector(state), projectIdSelector(state)),
			swimlaneMode,
			issueParent: issueParentFactory(issueParent),
			assignee: assigneeFactory(assignee),
			summaryTooltip: getSwimlaneSummaryTooltip(ownProps.id, swimlaneMode, intl),
			isSubtask: swimlaneMode === SWIMLANE_BY_SUBTASK.id && ownProps.id !== ChildlessSwimlane.id,
			link:
				isCMPBoard && swimlaneMode === SWIMLANE_BY_PROJECT.id && projectKey
					? `/browse/${projectKey}`
					: undefined,
			projectKey,
			isFlagged,
			isDone,
			lozenge,
			customLeftContent: customLeftContentFactory(
				isIncrementPlanningBoard,
				ownProps.id,
				isIncrementPlanningBoardReadOnly,
				ownProps.isUnscheduledWorkColumnPanel,
			),
			customRightContent: ownProps.customRightContent,
		};
	};
};
type StateProps = ReturnType<ReturnType<typeof mapStateToProps>>;

const mapDispatchToProps = {
	swimlaneToggle,
	openIssueModal,
} as const;

const onClickFactory = memoizeOne(
	(
		issueKey: StateProps['issueKey'],
		dispatchPropsOpenIssueModal: DispatchProps['openIssueModal'],
		swimlaneMode: StateProps['swimlaneMode'],
	) =>
		(analyticsEvent: UIAnalyticsEvent) => {
			if (issueKey) {
				dispatchPropsOpenIssueModal(issueKey);
			}

			fireUiAnalytics(analyticsEvent, {
				name: 'board',
				attributes: { swimlaneMode },
			});
		},
);
const onToggleFactory = memoizeOne(
	(dispatchPropsWwimlaneToggle: DispatchProps['swimlaneToggle'], id: string) =>
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			dispatchPropsWwimlaneToggle(id);
			fireUIAnalytics(analyticsEvent, 'boardSwimlaneBar');
		},
);

export const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
) => ({
	children: ownProps.children,
	wrapperStyle: ownProps.wrapperStyle,
	innerRef: ownProps.innerRef,
	hasShadow: ownProps.hasShadow,
	...stateProps,
	onClick: onClickFactory(
		stateProps.issueKey,
		dispatchProps.openIssueModal,
		stateProps.swimlaneMode,
	),
	onToggle: onToggleFactory(dispatchProps.swimlaneToggle, ownProps.id),
	isUnscheduledWorkColumnPanel: ownProps.isUnscheduledWorkColumnPanel,
});

export default flow(
	AnalyticsEventToProps('swimlaneBar', {
		onToggle: 'toggled',
	}),
	connect(mapStateToProps, mapDispatchToProps, mergeProps),
	injectIntl,
)(Swimlane);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeIcon = styled.img({
	verticalAlign: 'middle',
	height: '1.25rem',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectIcon = styled.img({
	verticalAlign: 'middle',
	borderRadius: '3px',
	width: '20px',
	height: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PeopleIconWrapper = styled.div({
	borderRadius: '50%',
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N40),
	margin: `0 ${token('space.025', '2px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequestTypeIcon = styled.img({
	verticalAlign: 'middle',
	height: '18px',
});

const teamTooltipStyles = xcss({
	textAlign: 'center',
});

import React, { useCallback } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller/index.tsx';

import type { Sprint } from '../../../../model/sprint/sprint-types.tsx';
import PopupContent from './popup-content/index.tsx';
import PopupTrigger, { type Props as PopupTriggerProps } from './trigger-button/index.tsx';

interface Props {
	activeSprints: Array<Sprint>; // = started but not completed
	selectedSprints: Array<Sprint>; // = explicitly selected by the user in the UI (same content as activeSprints if no selection)
}
function SprintDetails(props: Props) {
	const { activeSprints, selectedSprints } = props;
	const [isOpen, togglePopup, closePopup] = usePopupController();

	const renderPopupTrigger = fg('jira_nav4_beta_drop_2')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(triggerProps: TriggerProps) => {
					const onClick: PopupTriggerProps['onClick'] = (_, analyticsEvent) => {
						togglePopup();
						fireUIAnalytics(analyticsEvent, 'sprintDetailsButton');
					};

					return (
						<PopupTrigger
							{...triggerProps}
							isSelected={isOpen}
							// by the spec, the "sprint details" control
							// only appear when there are active sprints
							// this "isDisabled" check is just a safety / defensive programming
							isDisabled={Math.max(selectedSprints.length, activeSprints.length) === 0}
							// the `onClick` function is coming from useCallback() so it's not less stable than the caller
							onClick={onClick}
							analyticsContext={{
								attributes: {
									intent: isOpen ? 'close' : 'open',
									activeSprintCount: activeSprints.length,
									selectedSprintsCount: selectedSprints.length,
								},
							}}
						/>
					);
				},
				[isOpen, togglePopup, selectedSprints, activeSprints],
			)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(triggerProps: TriggerProps) => {
					function onClick() {
						togglePopup();
					}

					// the function is coming from useCallback() so it's not less stable than the caller
					// eslint-disable-next-line react/jsx-no-bind
					return <PopupTrigger {...triggerProps} isSelected={isOpen} onClick={onClick} />;
				},
				[isOpen, togglePopup],
			);

	const renderPopupContent = useCallback(() => {
		return (
			<PopupContent selectedSprints={selectedSprints} activeSprintCount={activeSprints.length} />
		);
	}, [selectedSprints, activeSprints]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={closePopup}
			placement="bottom-end"
			content={renderPopupContent}
			trigger={renderPopupTrigger}
		/>
	);
}

export default SprintDetails;

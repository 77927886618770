// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import omit from 'lodash/omit';
import times from 'lodash/times';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ANIMATION_TIMEOUT } from '@atlassian/jira-insights-next-best-task/src/common/constants.tsx';
import { useNextBestTaskPanel } from '@atlassian/jira-insights-next-best-task/src/services/use-next-best-task-panel/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { UPDATE_BOARD_SCROLL_POSITION } from '@atlassian/jira-platform-board-kit/src/common/constants/index.tsx';
import MinimapColumn from '@atlassian/jira-platform-board-kit/src/ui/minimap/column/index.tsx';
import Minimap from '@atlassian/jira-platform-board-kit/src/ui/minimap/index.tsx';
import { PACKAGE_NAME } from '../../../../model/constants.tsx';

export type Props = {
	columnCount: number;
} & ComponentProps<typeof Minimap>;

const BoardMinimap = (props: Props) => {
	const { isOpen: isNextBestTaskPanelOpen } = useNextBestTaskPanel();
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			// only execute the logic at state update, not on initial mount.
			// important to delay to wait the animation complete so we get accurate measure of NBT panel for scrollWidth !!!
			setTimeout(
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				() => window.dispatchEvent(new Event(UPDATE_BOARD_SCROLL_POSITION)),
				ANIMATION_TIMEOUT + 100,
			);
		}
		didMountRef.current = true;
	}, [isNextBestTaskPanelOpen]);

	return (
		<Minimap {...omit(props, 'columnCount')}>
			{isNextBestTaskPanelOpen && <NextBestTaskColumn />}
			{times(props.columnCount, (key) => (
				<MinimapColumn key={key} />
			))}
		</Minimap>
	);
};

const MiniMap = (props: Props) => (
	<JSErrorBoundary id="board-minimap" packageName={PACKAGE_NAME} fallback="flag">
		<Placeholder fallback={null} name="board-minimap">
			<BoardMinimap {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default MiniMap;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NextBestTaskColumn = styled.div({
	borderRadius: '3px',
	flex: '1 0 auto',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: token('color.background.neutral', colors.N30),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.subtle', colors.N0),
	marginRight: token('space.025', '2px'),
});

import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const Tracker = styled.div<{ backgroundColor: string; color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	borderRadius: '3px',
	padding: token('space.050', '4px'),
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	textTransform: 'uppercase',
	textWrap: 'nowrap',
	display: 'block',
	height: '16px',
	lineHeight: '16px',
});

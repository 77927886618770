import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';
import { layout, minCardHeight } from '../../../../common/constants/styles/index.tsx';

const hoverIntentDelayMs = 100;

const messages = defineMessages({
	transitionTo: {
		id: 'platform-board-kit.column.column-transition-zones-container.transition-to-zone.transition-to',
		defaultMessage: 'Transition To...',
		description: 'Menu label that reveals possible status transitions once hovered over',
	},
});

type Props = {
	onHover: () => void;
};

const ColumnTransitionToZone = ({ onHover }: Props) => {
	const { formatMessage } = useIntl();
	const autobatch = useAutobatch();
	const [isDraggingOver, setIsDraggingOver] = useState(false);
	const batchSetIsDraggingOver = useCallback(
		(draggingOver: boolean) => {
			autobatch(() => setIsDraggingOver(draggingOver));
		},
		[autobatch],
	);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const previousInputPosition = useRef<{ x: number; y: number } | null>(null);
	const debouncedHoverIntent = useMemo(() => debounce(onHover, hoverIntentDelayMs), [onHover]);

	useEffect(() => {
		if (!containerRef.current) return undefined;

		return dropTargetForElements({
			element: containerRef.current,
			onDragEnter: () => {
				batchSetIsDraggingOver(true);
			},
			onDrag: ({
				location: {
					current: { input },
				},
			}) => {
				if (
					previousInputPosition.current?.x !== input.clientX ||
					previousInputPosition.current?.y !== input.clientY
				) {
					debouncedHoverIntent();
					previousInputPosition.current = {
						x: input.clientX,
						y: input.clientY,
					};
				}
			},
			onDragLeave: (_args) => {
				debouncedHoverIntent.cancel();
				batchSetIsDraggingOver(false);
			},
		});
	}, [batchSetIsDraggingOver, debouncedHoverIntent]);

	return (
		<TransitionToZone
			isDraggingOver={isDraggingOver}
			innerRef={containerRef}
			data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-to-zone.transition-to-zone"
			data-testid="platform-board-kit.ui.column.column-transition-zones-container.transition-to-zone"
		>
			{formatMessage(messages.transitionTo)}
		</TransitionToZone>
	);
};

export default ColumnTransitionToZone;

const ttZoneHeight = minCardHeight * 0.5 - 5;
const ttZoneMargin = layout.adg3smallGutter * 0.5;
const ttZoneMarginTop = -(ttZoneHeight + layout.adg3smallGutter);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionToZoneControl = styled.div<{ isDraggingOver?: boolean }>`
	position: sticky;
	text-align: center;
	pointer-events: all !important;
	top: 0;
	margin: ${ttZoneMarginTop}px ${ttZoneMargin}px ${ttZoneMargin}px;
	border: 2px solid
		${
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(props: any) =>
				props.isDraggingOver
					? token('color.border.brand', colors.B300)
					: token('color.border.brand', colors.B100)
		};
	background-color: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) =>
			props.isDraggingOver
				? token('color.background.selected.hovered', colors.B75)
				: token('color.background.selected', colors.B50)
	};
	height: ${ttZoneHeight}px;
	line-height: ${ttZoneHeight}px;
	width: calc(100% - ${layout.adg3smallGutter * 1.5}px);
	z-index: 10;
	border-radius: ${layout.cardListRadius}px;
`;

type TransitionToZoneProps = {
	isDraggingOver?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionToZoneExperiment = styled2.div<TransitionToZoneProps>({
	position: 'sticky',
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	pointerEvents: 'all !important',
	top: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${ttZoneMarginTop}px ${ttZoneMargin}px ${ttZoneMargin}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props: TransitionToZoneProps) =>
		props.isDraggingOver
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`2px solid ${token('color.border.brand', colors.B300)}`
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`2px solid ${token('color.border.brand', colors.B300)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: TransitionToZoneProps) =>
		props.isDraggingOver
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.selected.hovered', colors.B75)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.selected', colors.B50),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${ttZoneHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${ttZoneHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(100% - ${layout.adg3smallGutter * 1.5}px)`,
	zIndex: 10,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${layout.cardListRadius}px`,
});

const TransitionToZone = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	TransitionToZoneExperiment,
	TransitionToZoneControl,
);

import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScoreContainer = styled.div<{ backgroundColor: string }>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.inverse', colors.N0)}`,
	borderRadius: '3px',
	marginLeft: token('space.050', '4px'),
	padding: `0 ${token('space.050', '4px')}`,
	height: '16px',
});

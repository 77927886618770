import React, { memo, useCallback, type ComponentType } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { JiraDetailPanel } from '@atlassian/jira-layout-controller/src/ui/detail-panel/index.tsx';
import {
	toIssueKey as convertToIssueKey,
	type IssueKey,
	type IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../feature-flags.tsx';
import type { CardId } from '../../model/card/card-types.tsx';
import type { Sprint } from '../../model/sprint/sprint-types.tsx';
import type { EstimationStatisticFieldId } from '../../model/work/work-types.tsx';
import { closeViewSettingsPanel } from '../../state/actions/panels/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../state/index.tsx';
import { getViewSettingsPanel } from '../../state/selectors/panels/index.tsx';
import { useIsCMPBoard } from '../../state/state-hooks/capabilities/index.tsx';
import { AsyncBoardInsightsPanel } from '../board-panels/insights-panel/async.tsx';
import { ViewSettingsPanel } from '../board-panels/view-settings-panel/index.tsx';
import type { OwnProps as IssueWrapperProps } from './issue-wrapper/view.tsx';
import Resizer from './resizer/view.tsx';

export type StateProps = {
	cardKey: IssueKey;
	cardId: CardId | null;
	timestamp: number;
	externalAction: ExternalAction | undefined;
	boardSessionId: string;
	activeSprints: Sprint[] | null;
	selectedSprints: string[] | null;
	selectedIssueKey: string | null | undefined;
	isSprintsEnabled: boolean;
};

export type OwnProps = {
	isDetailViewSidebar: boolean;
	isInsightsPanelOpen: boolean;
	rapidViewId: string;
	parentWidth: number;
	estimationStatistic: EstimationStatisticFieldId;
	projectId: number;
};

type DispatchProps = {
	onClose: () => void;
	onChange: (payload: ChangeEvent) => void;
	onDelete: (arg1: { issueId: IssueId; issueKey: string }) => void;
	onCloseInsightsClick: () => void;
	onResetExternalAction: () => void;
};

export type Props = {
	IssueWrapper: ComponentType<IssueWrapperProps>;
} & StateProps &
	OwnProps &
	DispatchProps;

export const DetailView = ({
	parentWidth,
	cardKey,
	rapidViewId,
	isDetailViewSidebar,
	isInsightsPanelOpen,
	projectId,
	onClose,
	onChange,
	onDelete,
	onCloseInsightsClick,
	onResetExternalAction,
	timestamp,
	externalAction,
	boardSessionId,
	activeSprints,
	selectedSprints,
	isSprintsEnabled,
	estimationStatistic,
	IssueWrapper,
}: Props) => {
	const [issueKey, setIssueKey] = useQueryParam('selectedIssue');
	const issueKeyOrCardKey = issueKey || cardKey;
	const onIssueKeyChange = useCallback<IssueWrapperProps['onIssueKeyChange']>(
		({ toIssueKey }) => setIssueKey(toIssueKey),
		[setIssueKey],
	);
	const [, { setDetailViewAsModal }] = useIssueContextActions();
	const isCMPBoard = useIsCMPBoard();
	const isViewSettingPanelOpen = useBoardSelector(getViewSettingsPanel);
	const onCloseViewSettingsPanel = useBoardActionCreator(() => closeViewSettingsPanel());

	if (
		!cardKey &&
		!isInsightsPanelOpen &&
		(!isViewSettingAsPanelExpEnabledWithNoExposure() || !isViewSettingPanelOpen)
	) {
		return null;
	}

	const hasSprints = activeSprints != null && activeSprints.length > 0;

	return isViewSettingAsPanelExpEnabledWithNoExposure() && isViewSettingPanelOpen ? (
		<ViewSettingsPanel
			isPanelOpen={isViewSettingPanelOpen}
			onPanelClose={onCloseViewSettingsPanel}
		/>
	) : (
		<JiraDetailPanel>
			{(setDetailPanelWidth) => (
				<Resizer parentWidth={parentWidth} useFixedMinWidth={false} onResize={setDetailPanelWidth}>
					{cardKey && isDetailViewSidebar && (
						<DetailsWrapper
							data-testid="software-board.detail-view.issue-wrapper"
							hide={isInsightsPanelOpen}
						>
							<IssueWrapper
								key={issueKeyOrCardKey + timestamp}
								issueKey={convertToIssueKey(issueKeyOrCardKey)}
								onClose={onClose}
								onChange={onChange}
								onIssueDeleteSuccess={onDelete}
								rapidViewId={Number(rapidViewId)}
								onSwitchToModal={setDetailViewAsModal}
								onIssueKeyChange={onIssueKeyChange}
								externalAction={externalAction}
								boardSessionId={boardSessionId}
								estimationStatistic={estimationStatistic}
								onResetExternalAction={onResetExternalAction}
							/>
						</DetailsWrapper>
					)}
					{(!isSprintsEnabled || hasSprints) && isInsightsPanelOpen ? ( // `isSprintsEnabled` is to check whether it's kanban
						<DetailsPanelContainer>
							<div>
								<AsyncBoardInsightsPanel
									isSimplifiedProject={!isCMPBoard}
									isOpen={isInsightsPanelOpen}
									onClose={onCloseInsightsClick}
									rapidViewId={Number(rapidViewId)}
									activeSprint={activeSprints?.[0]}
									projectId={projectId}
									sprints={activeSprints}
									selectedSprints={selectedSprints}
									isSprintsEnabled={isSprintsEnabled}
								/>
							</div>
						</DetailsPanelContainer>
					) : null}
				</Resizer>
			)}
		</JiraDetailPanel>
	);
};

export default memo<Props>(DetailView);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const DetailsWrapperControl = styled.div<{ hide?: boolean }>((props) => ({
	flex: 1,
	height: '100%',
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	display: props.hide ? 'none' : undefined,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsWrapperExperiment = styled2.div<{ hide?: boolean }>({
	flex: 1,
	height: '100%',
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props) => (props.hide ? 'none' : undefined),
});

const DetailsWrapper = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	DetailsWrapperExperiment,
	DetailsWrapperControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsPanelContainer = styled2.div({
	flex: 1,
	position: 'relative',
	height: '100%',
	overflowX: 'hidden',
	overflowY: 'scroll',
});

import React, { useEffect, type ReactNode } from 'react';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { useNextBestTaskContainer } from '../../services/next-best-task-container/index.tsx';

type Props = {
	children: ReactNode;
	isPanelView: boolean;
};

const PULSE_DURATION = 7950;

export const ViewSettingsTogglePulse = ({ children, isPanelView }: Props) => {
	const [{ viewSettingsTogglePulses }, { setViewSettingsTogglePulses }] =
		useNextBestTaskContainer();

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setViewSettingsTogglePulses(false);
		}, PULSE_DURATION);
		return () => {
			clearTimeout(timeoutId);
			setViewSettingsTogglePulses(false);
		};
	}, [setViewSettingsTogglePulses]);

	if (!viewSettingsTogglePulses) {
		return <>{children}</>;
	}

	return isPanelView ? (
		<Box xcss={negativePadding}>
			<SpotlightPulse radius={5}>
				<Box xcss={positivePadding}>{children}</Box>
			</SpotlightPulse>
		</Box>
	) : (
		<Box xcss={positivePadding}>
			<SpotlightPulse radius={5}>
				<Box xcss={negativePadding}>{children}</Box>
			</SpotlightPulse>
		</Box>
	);
};

const negativePadding = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.negative.050',
});

const positivePadding = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

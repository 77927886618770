import React, { type ComponentPropsWithoutRef, memo, useCallback } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';
import { type Props, DependencyFilterOption } from './types.tsx';

const DependenciesFilter = ({
	isOpen = false, // only used for VR testing purposes
	onChange = noop,
	selectedValues = [],
}: Props) => {
	const { formatMessage } = useIntl();
	const onChangeValue = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			onChange(entries.map(({ value }) => value));
		},
		[onChange],
	);
	return (
		<ListFilter
			isOpen={isOpen}
			label={formatMessage(messages.label)}
			onChange={onChangeValue}
			values={[
				{
					label: formatMessage(messages.dependencyType),
					options: [
						{
							label: formatMessage(messages.offTrack),
							value: DependencyFilterOption.OFF_TRACK,
						},
						{
							label: formatMessage(messages.onTrack),
							value: DependencyFilterOption.ON_TRACK,
						},
					],
				},
			]}
			selectedValues={selectedValues}
		/>
	);
};

DependenciesFilter.displayName = 'DependenciesFilter';
DependenciesFilter.whyDidYouRender = true;

export default memo<
	JSX.LibraryManagedAttributes<
		typeof DependenciesFilter,
		ComponentPropsWithoutRef<typeof DependenciesFilter>
	>
>(DependenciesFilter);

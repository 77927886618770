import type { Attributes } from '@atlassian/jira-product-analytics-web-client-types';

export const Action = {
	CLICKED: 'clicked',
	CHANGED: 'changed',
};

export const ActionSubject = {
	BUTTON: 'button',
	TOGGLE: 'toggle',
	SELECT: 'select',
};

export const AnalyticsEvent = {
	UI_EVENT: 'UI_EVENT',
	OPERATIONAL_EVENT: 'OPERATIONAL_EVENT',
	TRACK_EVENT: 'TRACK_EVENT',
};

export const ToggleKey = {
	VERSION_PANEL_TOGGLE: 'versionPanel',
	EPIC_PANEL_TOGGLE: 'epicPanel',
	QUICK_FILTERS_TOGGLE: 'quickFilters',
	COMPACT_MODE_TOGGLE: 'compactMode',
	CARD_DENSITY: 'cardDensity',
	SHOW_VERSIONS_TOGGLE: 'showVersions',
	SHOW_EPICS_TOGGLE: 'showEpics',
	SHOW_STATUSES_TOGGLE: 'showStatuses',
	SHOW_ISSUE_TYPE: 'showIssueType',
	SHOW_ISSUE_KEY: 'showIssueKey',
	SHOW_ESTIMATE: 'showEstimate',
	SHOW_PRIORITY: 'showPriority',
	SHOW_DEVELOPMENT: 'showDevelopment',
	SHOW_ASSIGNEE: 'showAssignee',
	SHOW_ISSUE_LINKS_STATS: 'showIssueLinksStats',
	OPEN_ISSUES_IN_SIDEBAR_TOGGLE: 'openIssuesInSidebar',
	SUBTASKS_EXPAND_ALL: 'subtasksExpandAll',
	SUBTASKS_COLLAPSE_ALL: 'subtasksCollapseAll',
	SWIMLANE_EXPAND_ALL: 'swimlaneExpandAll',
	SWIMLANE_COLLAPSE_ALL: 'swimlaneCollapseAll',
	SHOW_DAYS_IN_COLUMN: 'showDaysInColumn',
	SHOW_DUE_DATE: 'showDueDate',
	SHOW_LABELS: 'showLabels',
	SHOW_EXTRA_FIELD: 'showExtraField',
	WORK_SUGGESTIONS_TOGGLE: 'workSuggestions',
	SHOW_CARD_COVERS: 'showCardCovers',
};

export const IncrementPlanningAnalyticsKey = {
	UNSCHEDULED_COLUMN_TOGGLE: 'unscheduledColumnToggle',
	ISSUE_IDS_TO_SHOW_DEPENDENCIES: 'issueIdsToShowDependencies',
	SHOW_OFFTRACK_DEPENDENCY_LINES: 'showOfftrackDependencyLines',
};

export const AllAnalyticsKey = {
	...ToggleKey,
	...IncrementPlanningAnalyticsKey,
};

export type AnalyticsAttributes = Attributes & {
	key: (typeof ToggleKey)[keyof typeof ToggleKey];
	state: boolean | string;
};

export type IncrementPlanningAnalyticsAttributes = Attributes & {
	key: (typeof AllAnalyticsKey)[keyof typeof AllAnalyticsKey];
	state?: boolean | string;
	count?: number;
};

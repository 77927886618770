import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { zIndex, layout } from '../../../common/constants/styles/index.tsx';
import { isStickySupported } from '../../../common/utils/is-sticky-supported/index.tsx';
import {
	useBoardHasScrollLeft,
	useBoardScrollIsAtRightEdge,
} from '../../../controllers/board-scroll/index.tsx';

const shadowWidth = 7;

type BoardBorderLeftStyleProps = {
	offsetLeftPaddingByWidth?: boolean;
	isVisualRefresh?: boolean;
};

type BoardBorderRightStyleProps = {
	offsetRightPaddingByWidth?: boolean;
};

export type LeftBorderProps = {
	offsetLeftPaddingByWidth?: boolean;
};

export const LeftBorder = ({ offsetLeftPaddingByWidth }: LeftBorderProps) => {
	const [hasScrollLeft] = useBoardHasScrollLeft();

	return (
		<BoardBorderLeftStyle
			isShadowVisible={hasScrollLeft}
			offsetLeftPaddingByWidth={offsetLeftPaddingByWidth}
			isVisualRefresh={isVisualRefreshEnabled()}
		/>
	);
};

export type RightBorderProps = {
	offsetRightPaddingByWidth?: boolean;
};

export const RightBorder = ({ offsetRightPaddingByWidth }: RightBorderProps) => {
	const [isAtRightScrollEdge] = useBoardScrollIsAtRightEdge();

	return (
		<BoardBorderRightStyle
			isShadowVisible={!isAtRightScrollEdge}
			offsetRightPaddingByWidth={offsetRightPaddingByWidth}
			isVisualRefresh={isVisualRefreshEnabled()}
		/>
	);
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const BoardBorderStyleControl = styled.div<{ isShadowVisible: boolean; isVisualRefresh: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		display: !isStickySupported ? 'none' : undefined,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('elevation.surface', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: props.isVisualRefresh ? token('space.300') : `${layout.boardBorderWidth}px`,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.isShadowVisible ? 1 : 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		pointerEvents: props.isShadowVisible ? 'auto' : 'none',
		transition: 'opacity 0.2s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: zIndex.boardBorders,
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderLeftStyleControl = styled(BoardBorderStyleControl)<BoardBorderLeftStyleProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		left: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginLeft: props.isVisualRefresh
			? token('space.negative.300')
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				`${-layout.boardBorderWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `0 ${layout.boardBorderWidth * 2}px 0
            ${token('elevation.shadow.overflow.spread', '#091e4229')},
        5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}`,
		clipPath: 'inset(0px -12px 0px 0)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: props.offsetLeftPaddingByWidth ? `${shadowWidth}px` : '',
	}),
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderRightStyleControl = styled(BoardBorderStyleControl)<BoardBorderRightStyleProps>`
	box-shadow:
		0 ${layout.boardBorderWidth * 2}px 0 ${token('elevation.shadow.overflow.spread', '#091e4229')},
		-5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')};
	clip-path: inset(0px 0px 0px -12px);
	${(props: BoardBorderRightStyleProps) =>
		props.offsetRightPaddingByWidth
			? `width: ${shadowWidth}px; margin-left: ${-shadowWidth}px; right: ${-shadowWidth}px;`
			: 'right: 0;'};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderStyleExperiment = styled2.div<{
	isShadowVisible: boolean;
	isVisualRefresh: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	display: !isStickySupported ? 'none' : '',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	width: ({ isVisualRefresh }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isVisualRefresh ? token('space.300') : `${layout.boardBorderWidth}px`,
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isShadowVisible }) => (isShadowVisible ? 1 : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: ({ isShadowVisible }) => (isShadowVisible ? 'auto' : 'none'),
	transition: 'opacity 0.2s',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.boardBorders,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderLeftStyleExperiment = styled2(
	BoardBorderStyleExperiment,
)<BoardBorderLeftStyleProps>({
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: (props: BoardBorderLeftStyleProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		props.isVisualRefresh ? token('space.negative.300') : `${-layout.boardBorderWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	boxShadow: `0 ${layout.boardBorderWidth * 2}px 0 ${token('elevation.shadow.overflow.spread', '#091e4229')}, 5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}`,
	clipPath: 'inset(0px -12px 0px 0)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props: BoardBorderLeftStyleProps) =>
		props.offsetLeftPaddingByWidth ? `${shadowWidth}px;` : '',
});

const BoardBorderLeftStyle = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	BoardBorderLeftStyleExperiment,
	BoardBorderLeftStyleControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderRightStyleExperiment = styled2(
	BoardBorderStyleExperiment,
)<BoardBorderRightStyleProps>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `0 ${layout.boardBorderWidth * 2}px 0 ${token('elevation.shadow.overflow.spread', '#091e4229')}, -5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}`,
		clipPath: 'inset(0px 0px 0px -12px)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: BoardBorderRightStyleProps) =>
		props.offsetRightPaddingByWidth
			? {
					width: `${shadowWidth}px`,
					marginLeft: `${-shadowWidth}px`,
					right: `${-shadowWidth}px`,
				}
			: {
					right: 0,
				},
);

const BoardBorderRightStyle = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	BoardBorderRightStyleExperiment,
	BoardBorderRightStyleControl,
);

import React, { useCallback, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { getIssueDiscoveryUrl } from '../../common/utils.tsx';
import messages from './messages.tsx';

const GLOBAL_CREATE_POLLINATOR_DATA_TEST_ID = 'jira-issue-create.modal.create-form.success-flag';

type Props = {
	cardKeys: string[];
	id: string | number;
	onDismissed: undefined | ((arg1: string | number) => void);
};

export const CardCreateGlobalFlag = ({ onDismissed, id, cardKeys }: Props) => {
	const { formatMessage } = useIntl();
	const [isCopied, setCopied] = useState(false);

	useEffect(() => {
		let timeoutId: ReturnType<typeof setTimeout>;
		if (isCopied) {
			timeoutId = setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [isCopied]);

	const issueDiscoveryUrl = getIssueDiscoveryUrl(cardKeys);

	const copyToClipboard = useCallback((): void => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		navigator.clipboard.writeText(`${window.location.origin}${issueDiscoveryUrl}`);
		setCopied(true);
	}, [issueDiscoveryUrl]);

	const flagTitle =
		cardKeys.length > 1
			? formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.multiplesIssuesCreatedSuccessFlagTitleIssueTermRefresh
						: messages.multiplesIssuesCreatedSuccessFlagTitle,
					{
						issueCount: cardKeys.length,
					},
				)
			: formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.createSuccessFlagTitleIssueTermRefresh
						: messages.createSuccessFlagTitle,
					{
						issueKey: cardKeys[0],
					},
				);

	const actions = [
		{
			content: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.viewIssueLinkTextIssueTermRefresh
					: messages.viewIssueLinkText,
				{
					numIssues: cardKeys.length,
				},
			),
			href: issueDiscoveryUrl,
		},
		{
			content: isCopied ? (
				<CopySuccess>{formatMessage(messages.linkCopied)}</CopySuccess>
			) : (
				formatMessage(messages.copyLink)
			),
			onClick: () => {
				copyToClipboard();
			},
		},
	];

	return (
		<div data-testid={GLOBAL_CREATE_POLLINATOR_DATA_TEST_ID} data-issue-key={cardKeys[0]}>
			<SuccessAutoDismissFlag
				id={id}
				onDismissed={onDismissed}
				title={flagTitle}
				actions={actions}
			/>
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopySuccess = styled.span({
	color: token('color.background.success.pressed', G300),
});

import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import PersonIcon from '@atlaskit/icon/core/person';
import PersonIconOld from '@atlaskit/icon/glyph/person';
import { xcss, Flex } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { categoryIdForStatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { ColorSchema } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import { CardColor } from '@atlassian/jira-platform-card/src/common/ui/color/index.tsx';
import { CardCustomFields } from '@atlassian/jira-platform-card/src/common/ui/custom-fields/index.tsx';
import { CardDaysInColumn } from '@atlassian/jira-platform-card/src/common/ui/days-in-column/index.tsx';
import { Container } from '@atlassian/jira-platform-card/src/common/ui/icons/index.tsx';
import { CardLabels } from '@atlassian/jira-platform-card/src/common/ui/labels/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { StatusDetails } from '@atlassian/jira-shared-types/src/rest/jira/status.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';
import {
	useCardFieldsSettings,
	useShowEpics,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Capability } from '../../../../../common/capability/index.tsx';
import { IssueParentFieldStatic } from '../../../../../common/fields/issue-parent-field-static/index.tsx';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../../../../feature-flags.tsx';
import { SWIMLANE_BY_PARENT_ISSUE } from '../../../../../model/swimlane/swimlane-modes.tsx';
import {
	getIssueParents,
	makeIssueParentByIdSelector,
} from '../../../../../state/selectors/issue-parent/index.tsx';
import {
	getIssueMedia,
	isIssueMediaHiddenByUser,
} from '../../../../../state/selectors/issue/issue-media-selectors.tsx';
import { isDoneIssueSelector } from '../../../../../state/selectors/issue/issue-selectors.tsx';
import { getPerson } from '../../../../../state/selectors/people/people-selectors.tsx';
import {
	getIsCMPBoard,
	projectTypeSelector,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import {
	isCardMediaEnabled,
	platformIssueSelector,
	getStatusByIssueIdSelector,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import {
	useIsIncrementPlanningBoard,
	useCapability,
} from '../../../../../state/state-hooks/capabilities/index.tsx';
import type { State } from '../../../../../state/types.tsx';
import CardCoverPlaceholder from '../card/cover/placeholder/index.tsx';
import { IssueLinksIndicator } from '../card/issue-links-indicator/index.tsx';
import { DEV_STATUS_PLACEHOLDER_TEST_ID } from './constants.tsx';
import type {
	Props,
	OwnProps,
	StateProps,
	LozengesContainerProps,
	EpicLozengeProps,
	LabelsProps,
	IssueTypeIconProps,
} from './types.tsx';

/**
 * A lightweight card with minimal content and dependencies to be used as
 * a placeholder during costly rendering operations such as scrolling
 */
export const CardLite = ({
	mediaId,
	issue,
	parentSummary,
	parentColor,
	showParent,
	assignee,
	isCMPBoard,
	isDone,
	status,
}: Props) => {
	const showEpicLabels = useShowEpics();
	const shouldRenderEpic = showParent && showEpicLabels;

	const viewSettings = isViewSettingAsPanelExpEnabledWithNoExposure()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCardFieldsSettings()
		: {
				showIssueType: true,
				showIssueKey: true,
				showPriority: true,
				showDevelopment: true,
				showAssignee: true,
				showDaysInColumn: true,
				cardExtraFields: undefined,
			};

	const isIPBoard = useIsIncrementPlanningBoard();
	const hasCompactIssueType = useCapability(Capability.RENDER_COMPACT_ISSUE_TYPE_ICON_ON_CARD);

	if (!issue) return null;

	const shouldRenderIssueLinksIndicator = issue.issueLinks && issue.issueLinks.length > 0;

	const shouldShowCMPCardFooter = isViewSettingAsPanelExpEnabledWithNoExposure()
		? [
				viewSettings.showIssueType,
				viewSettings.showIssueKey,
				viewSettings.showPriority,
				viewSettings.showDevelopment,
				viewSettings.showAssignee && !!assignee?.avatarUrl,
				viewSettings.showDaysInColumn,
			].some((field) => !!field)
		: true;

	const shouldShowTMPCardFooter = isViewSettingAsPanelExpEnabledWithNoExposure()
		? [
				viewSettings.showIssueType,
				viewSettings.showIssueKey,
				viewSettings.showAssignee,
				shouldRenderIssueLinksIndicator,
			].some((field) => !!field)
		: true;

	const showLozengeContainer = shouldRenderEpic && parentSummary !== null && parentColor !== null;
	const showLabels = issue.labels !== undefined && issue.labels.length > 0;

	const customFields = issue?.customFields;

	const statusDetails: StatusDetails | null = status?.id
		? {
				id: status.id.toString(),
				name: status.name,
				statusCategory: {
					id: categoryIdForStatusCategory(status.category),
				},
			}
		: null;

	const tmpCardLite = () => (
		<CardLiteContainer isCMPBoard={Boolean(isCMPBoard)} useContainerQuery={Boolean(!isIPBoard)}>
			<CardLiteWrapper isFlagged={!isIPBoard && Boolean(issue.isFlagged)}>
				{mediaId !== null && <CardCoverPlaceholder mediaId={mediaId} />}

				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<CardLiteContent className={styles.content}>
					{!isIPBoard && issue.cardColor && <CardColor color={issue.cardColor} />}
					{isIPBoard && issue.hasScenarioChanges && issue.cardColor && (
						<CardColor color={issue.cardColor} />
					)}
					<Summary shouldTruncate={false}>{issue.summary}</Summary>
					{statusDetails && (
						<>
							<LozengesContainer>
								<IssueStatusFieldReadonly value={statusDetails} />
							</LozengesContainer>
						</>
					)}
					{(showLozengeContainer ||
						(showLabels && fg('display_labels_on_lite_card_in_tmp_board'))) && (
						<LozengesContainer>
							{shouldRenderEpic && (
								<IssueParentFieldStatic
									summary={parentSummary}
									color={parentColor}
									maxWidth="100%"
								/>
							)}
							<CardLabels labels={issue.labels ?? []} />
						</LozengesContainer>
					)}
					{shouldShowTMPCardFooter && (
						<CardFooter>
							{viewSettings.showIssueType && <IssueTypeIcon src={issue.typeUrl} />}
							{viewSettings.showIssueKey && <IssueKey isDone={isDone}>{issue.key}</IssueKey>}
							{/* when issue_cards_in_program_board is cleaned up, remove this condition (!isIPBoard || fg('issue_cards_in_program_board'))  */}
							{(!isIPBoard || fg('issue_cards_in_program_board')) && viewSettings.showAssignee && (
								<TMPAvatar url={assignee?.avatarUrl} />
							)}
							{isIPBoard && shouldRenderIssueLinksIndicator && (
								<IssueLinksIndicator issueId={issue.id} issueKey={issue.key} />
							)}
						</CardFooter>
					)}
				</CardLiteContent>
			</CardLiteWrapper>
		</CardLiteContainer>
	);

	const cmpCardLite = () => (
		<CardLiteContainer isCMPBoard={Boolean(isCMPBoard)} useContainerQuery={Boolean(!isIPBoard)}>
			<CardLiteWrapper isFlagged={Boolean(issue.isFlagged)}>
				{mediaId !== null && <CardCoverPlaceholder mediaId={mediaId} />}

				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<CardLiteContentCMP className={styles.content}>
					{issue.cardColor && <CardColor color={issue.cardColor} />}
					<ContentSection>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<Summary shouldTruncate className={styles.summary}>
							{issue.summary}
						</Summary>
					</ContentSection>
					{statusDetails && (
						<>
							<LozengesContainer>
								<IssueStatusFieldReadonly value={statusDetails} />
							</LozengesContainer>
						</>
					)}
					{showLozengeContainer && (
						<LozengesContainer>
							{shouldRenderEpic && (
								<EpicLozenge summary={parentSummary} color={parentColor} maxWidth="100%" />
							)}
						</LozengesContainer>
					)}
					{customFields &&
						customFields.some(
							(config) =>
								config.id &&
								(isViewSettingAsPanelExpEnabledWithNoExposure()
									? viewSettings.cardExtraFields?.[config.id] !== false
									: true),
						) && (
							<ContentSection>
								<CardCustomFields
									isCMPBoard={isCMPBoard}
									fields={customFields.filter(
										(config) =>
											config.id &&
											(isViewSettingAsPanelExpEnabledWithNoExposure()
												? viewSettings.cardExtraFields?.[config.id] !== false
												: true),
									)}
								/>
							</ContentSection>
						)}
					{shouldShowCMPCardFooter && (
						<ContentSection>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<FooterSection className={styles.footer}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<FooterPrimarySection className={styles.footerChildSection}>
									{!hasCompactIssueType && viewSettings.showIssueType && (
										<IssueTypeIcon src={issue.typeUrl} />
									)}
									{viewSettings.showIssueKey && <IssueKey isDone={isDone}>{issue.key}</IssueKey>}
								</FooterPrimarySection>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<FooterTertiarySection className={styles.footerChildSection}>
									<Container>
										{hasCompactIssueType && viewSettings.showIssueType && (
											<IssueTypeIcon src={issue.typeUrl} />
										)}
										{issue.devStatusField && viewSettings.showDevelopment && (
											<DevStatusPlaceholder data-testid={DEV_STATUS_PLACEHOLDER_TEST_ID} />
										)}
										{typeof issue.daysInColumn === 'number' && viewSettings.showDaysInColumn && (
											<CardDaysInColumn days={issue.daysInColumn} />
										)}
										{issue.priority && viewSettings.showPriority && (
											/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
											<IconWrapper data-testid="software-board.board-container.board.card-container.card-lite.priority-icon.wrapper">
												<PriorityIcon alt={issue.priority.name} src={issue.priority?.iconUrl} />
											</IconWrapper>
										)}
									</Container>
								</FooterTertiarySection>
								<FooterSecondarySection>
									{assignee?.avatarUrl != null && viewSettings.showAssignee && (
										<CMPAvatar
											url={assignee?.avatarUrl}
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											className={styles.cmpAvatar}
											data-testid="software-board.board-container.board.card-container.card-lite.cmp-avatar"
										/>
									)}
								</FooterSecondarySection>
							</FooterSection>
						</ContentSection>
					)}
				</CardLiteContentCMP>
			</CardLiteWrapper>
		</CardLiteContainer>
	);

	return isCMPBoard ? cmpCardLite() : tmpCardLite();
};

function getShouldShowParent(state: State): boolean {
	const isCMPBoard = getIsCMPBoard(state);
	if (isCMPBoard) {
		return !state.ui.board.displayOptions.areEpicLabelsHidden;
	}

	return getSwimlaneMode(state) !== SWIMLANE_BY_PARENT_ISSUE.id;
}

export const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const issue = platformIssueSelector(state)(id);
	const issueParents = getIssueParents(state);
	const parentId = (() => {
		if (!issue) {
			return null;
		}

		if (id in issueParents) {
			return id;
		}
		return issue.parentId;
	})();

	const issueMedia =
		isCardMediaEnabled(state) && !isIssueMediaHiddenByUser(state, id)
			? getIssueMedia(state, id)
			: null;
	const mediaId =
		!isNil(issueMedia) && 'attachmentMediaApiId' in issueMedia
			? issueMedia.attachmentMediaApiId
			: null;
	const parent = parentId !== null ? makeIssueParentByIdSelector(state)(parentId) : null;
	const isCMPBoard = getIsCMPBoard(state);
	const showParent = getShouldShowParent(state);
	const isJSMBoard = projectTypeSelector(state) === SERVICE_DESK_PROJECT;

	return {
		issue,
		assignee: issue?.assigneeAccountId ? getPerson(state, issue.assigneeAccountId) : null,
		mediaId,
		parentSummary: parent?.summary ?? null,
		parentColor: parent?.color ?? null,
		// Hide parent when grouping by epic
		showParent,
		isCMPBoard,
		status: isJSMBoard ? getStatusByIssueIdSelector(state, { id }) ?? null : null,
		isDone: isDoneIssueSelector(state)(id),
	};
};

const CardLiteConnected = connect(mapStateToProps, {})(CardLite);

export default CardLiteConnected;

const LOZENGE_THEMES = transformColorToTheme((colorSchema: ColorSchema) => ({
	textColor: colorSchema.text,
	backgroundColor: colorSchema.textBackground,
}));

export const LozengesContainer = ({ children }: LozengesContainerProps) => (
	<div
		// eslint-disable-next-line jira/react/no-style-attribute
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			display: 'flex',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			flexDirection: 'row',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			overflow: 'hidden',
		}}
	>
		<div
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				flexFlow: 'row wrap',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				boxAlign: 'center',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: 'center',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				maxWidth: '100%',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				gap: token('space.100', '8px'),
			}}
		>
			{children}
		</div>
	</div>
);

export const EpicLozenge = ({ summary, color, maxWidth }: EpicLozengeProps) => {
	if (summary !== null && color !== null) {
		// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
		const theme = LOZENGE_THEMES[color];
		return (
			<div
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					display: 'inline-block',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					boxSizing: 'border-box',
					maxWidth,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					textTransform: 'uppercase',

					padding: `0 ${token('space.050', '4px')}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					font: token('font.body.small', fontFallback.body.small),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					fontWeight: token('font.weight.bold', '700'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					borderRadius: '3px',
					color: theme.textColor,
					background: theme.backgroundColor,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					whiteSpace: 'nowrap',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					textOverflow: 'ellipsis',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					overflow: 'hidden',
				}}
			>
				{summary}
			</div>
		);
	}

	return null;
};
export const Labels = ({ labels }: LabelsProps) => {
	if (labels == null || !labels.length) {
		return null;
	}

	return (
		<div
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'inline-flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: 'center',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				gap: token('space.100', '8px'),
			}}
		>
			{labels.map((label) => (
				<Label key={label}>{label}</Label>
			))}
		</div>
	);
};

export const IssueTypeIcon = ({ src }: IssueTypeIconProps) => {
	if (src !== undefined) {
		return (
			<img
				src={src}
				alt=""
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					width: '16px',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					height: '16px',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					verticalAlign: 'text-bottom',
				}}
			/>
		);
	}
	return null;
};

type AvatarWrapperProps = {
	children: React.ReactNode;
};

const AvatarWrapperOld = ({ children }: AvatarWrapperProps) => (
	<span
		// eslint-disable-next-line jira/react/no-style-attribute
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			display: 'flex',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			width: '100%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			height: '100%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/enforce-style-prop
			backgroundColor: token('color.icon.subtle', colors.N90),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			borderRadius: '50%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
			justifyContent: 'center',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
			alignItems: 'center',
		}}
	>
		{children}
	</span>
);

const AvatarWrapperNew = ({ children }: AvatarWrapperProps) => (
	<Flex alignItems="center" justifyContent="center" xcss={AvatarWrapperStylesNew}>
		{children}
	</Flex>
);

const AvatarWrapper = componentWithCondition(
	isVisualRefreshEnabled,
	AvatarWrapperNew,
	AvatarWrapperOld,
);

export const TMPAvatar = ({ url }: { url?: string }) => (
	<div
		// eslint-disable-next-line jira/react/no-style-attribute
		style={{
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			width: '24px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			height: '24px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			padding: token('space.025', '2px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			display: 'flex',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			alignItems: 'center',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			justifyContent: 'center',
		}}
		data-testid="software-board.board-container.board.card-container.card-lite.tmp-avatar"
	>
		{!url ? (
			<AvatarWrapper>
				{fg('enghealth-12479-jsw-board-visual-refresh') ? (
					<PersonIcon
						label=""
						color={token('color.icon.subtle', colors.N700)}
						LEGACY_fallbackIcon={PersonIconOld}
						LEGACY_primaryColor={token('color.text.inverse', colors.N0)}
						LEGACY_secondaryColor={token('color.text.subtle', colors.N90)}
					/>
				) : (
					<PersonIconOld
						primaryColor={token('color.text.inverse', colors.N0)}
						secondaryColor={token('color.text.subtle', colors.N90)}
						label=""
					/>
				)}
			</AvatarWrapper>
		) : (
			<div
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					height: '100%',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					borderRadius: '50%',
					background: `url(${url})`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundSize: 'cover',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					width: '100%',
				}}
			/>
		)}
	</div>
);
const iconSize = gridSize * 2;

const gridAreas = () => {
	if (styles.primary && styles.tertiary && styles.secondary)
		return `"${styles.primary} ${styles.tertiary} ${styles.secondary}"`;
	return '"primary tertiary secondary"';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLiteContainer = styled.div<{ isCMPBoard: boolean; useContainerQuery: boolean }>({
	height: '100%',
	boxSizing: 'border-box',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) =>
		props.isCMPBoard
			? token('space.0', '0px')
			: `${token('space.025', '2px')} ${token('space.0', '0px')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) =>
		props.isCMPBoard
			? `${token('space.025', '2px')} ${token('space.0', '0px')}`
			: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	container: ({ useContainerQuery }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		useContainerQuery ? `${styles['card-content-container']} / inline-size` : '',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLiteWrapper = styled.div<{ isFlagged: boolean }>({
	height: '100%',
	borderRadius: '3px',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) =>
		props.isFlagged
			? token('color.background.warning', '#FFF7D6')
			: token('elevation.surface.raised', 'white'),
	boxShadow: `${token(
		'elevation.shadow.raised',
		'0 1px 1px rgba(23, 43, 77, 0.2), 0 0 1px rgba(23, 43, 77, 0.2)',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLiteContent = styled.div({
	height: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	padding: token('space.150', '12px'),
	gap: token('space.100', '8px'),
	// from src/packages/platform/ui/card/src/ui/card/card-content/styled.tsx
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:nth-of-type(2):last-of-type': {
		marginTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div<{ shouldTruncate: boolean }>({
	paddingRight: token('space.400', '32px'),
	wordBreak: 'break-word',
	overflowWrap: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitLineClamp: ({ shouldTruncate }) => (shouldTruncate ? 3 : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ shouldTruncate }) => (shouldTruncate ? '-webkit-box' : 'inline'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ shouldTruncate }) => (shouldTruncate ? 'hidden' : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitBoxOrient: ({ shouldTruncate }) => (shouldTruncate ? 'vertical' : 'unset'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardFooter = styled.div({
	display: 'flex',
	alignItems: 'center',
	minHeight: '28px',

	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.div<{ isDone: boolean }>({
	display: 'flex',
	alignItems: 'center',
	height: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.isDone ? 'line-through' : 'unset'),
});

/**
 * CMP card lite styles below
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLiteContentCMP = styled.div({
	height: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.100', '8px'),
	padding: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CMPAvatar = styled.div<{ url: string }>({
	height: '24px',
	width: '24px',
	margin: token('space.025', '2px'),
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => `url(${props.url}) 0/cover`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentSection = styled.div({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterSection = styled.div({
	display: 'grid',
	width: '100%',
	gap: `${token('space.100', '8px')} ${token('space.050', '4px')}`,
	minHeight: '28px',
	gridTemplateRows: '1fr',
	gridTemplateColumns: 'minmax(0, 1fr) auto auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridTemplateAreas: gridAreas(),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterPrimarySection = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridArea: styles.primary ? styles.primary : 'primary',
	maxWidth: 'fit-content',
	boxSizing: 'border-box',
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterSecondarySection = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridArea: styles.secondary ? styles.secondary : 'secondary',
	boxSizing: 'border-box',
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterTertiarySection = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gridArea: styles.tertiary ? styles.tertiary : 'tertiary',
	minHeight: '24px',
	boxSizing: 'border-box',
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'start',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PriorityIcon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${iconSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${iconSize}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DevStatusPlaceholder = styled.div({
	width: '24px',
	height: '24px',
	flexShrink: '0', // Fixed width element. no shrinking.
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({
	display: 'inline-flex',
	boxSizing: 'border-box',
	height: '20px',
	lineHeight: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	borderRadius: '3px',
	maxWidth: '180px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N700),
});

const AvatarWrapperStylesNew = xcss({
	width: '100%',
	height: '100%',
	backgroundColor: 'color.background.accent.gray.subtler',
	borderRadius: '50%',
});

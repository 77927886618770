import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyProjectKeyboardShortcuts = lazy(
	() => import(/* webpackChunkName: "async-jira-software-keyboard-shortcuts" */ './main'),
);

/**
 * Binds keyboard shortcuts to navigate to the respective experience when enabled:
 * 1 - Backlog
 * 2 - Board
 * 3 - Reports
 * 4 - Timeline
 */
const ProjectKeyboardShortcuts = (props: Props) => (
	<ErrorBoundary
		id="jira-software-keyboard-shortcuts-project-shortcuts"
		packageName="jira-software-keyboard-shortcuts"
		render={() => <></>}
	>
		<Placeholder name="jira-software-keyboard-shortcuts" fallback={<></>}>
			<LazyProjectKeyboardShortcuts {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export default ProjectKeyboardShortcuts;

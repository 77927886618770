import floor from 'lodash/floor';
import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import { SECONDS_PER_HOUR } from '@atlassian/jira-time-tracking-formatter/src/constants.tsx';
import { IP_BOARD_DAYS_PLANNING_UNIT, IP_BOARD_HOURS_PLANNING_UNIT } from './constants.tsx';
import messages from './messages.tsx';
import type { PlanningUnit } from './types.tsx';

const PLAN_PARAMS = {
	PLAN: 'plans',
	SCENARIO: 'scenarios',
	BOARD: 'program',
	ROADMAP: 'backlog',
};

const parsePathParams = (path: string, param: string): string => {
	const regex = new RegExp(`/${param}/(\\w+)`, 'g');
	const matches = Array.from(path.matchAll(regex), (match) => match[1]);

	return matches[0];
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getPathParams = (param: string): string => parsePathParams(window.location.pathname, param);

export const getPlanId = (): number | null | undefined =>
	+getPathParams(PLAN_PARAMS.PLAN) || undefined;

export const getScenarioId = (): number | null | undefined =>
	+getPathParams(PLAN_PARAMS.SCENARIO) || undefined;

export const urls = {
	managePortfolio: '/jira/plans/',
};

export const getBoardUrl = (boardId: number, isEmbed = false) =>
	`/jira/${PLAN_PARAMS.PLAN}/${getPlanId()}/${PLAN_PARAMS.SCENARIO}/${getScenarioId()}/${PLAN_PARAMS.BOARD}/${boardId}${isEmbed && fg('smart_links_for_plans') ? '/embed' : ''}`;

export const getManageBoardSettingsUrl = (
	planId: string | undefined,
	scenarioId: string | undefined,
) => `/jira/plans/${planId}/scenarios/${scenarioId}/settings/program-boards`;

export const convertCapacity = (
	capacity: number,
	planningUnit: PlanningUnit,
	workingHoursPerDay: number,
): number => {
	if (planningUnit === IP_BOARD_DAYS_PLANNING_UNIT) {
		return floor(capacity / workingHoursPerDay / SECONDS_PER_HOUR, 2);
	}
	if (planningUnit === IP_BOARD_HOURS_PLANNING_UNIT) {
		return floor(capacity / SECONDS_PER_HOUR, 2);
	}
	return capacity;
};

export const getCapacityUnitMessage = (
	planningUnit: PlanningUnit,
	formatMessage: (message: MessageDescriptor, value?: Record<string, string | number>) => string,
	originalCapacity?: number,
) => {
	// if the originalCapacity is undefined, assign the capacity to 2 to get the plural unit
	const capacity = isNil(originalCapacity) ? 2 : originalCapacity;
	switch (planningUnit) {
		case IP_BOARD_DAYS_PLANNING_UNIT: {
			return formatMessage(messages.dayUnit, { days: capacity });
		}
		case IP_BOARD_HOURS_PLANNING_UNIT: {
			return formatMessage(messages.hourUnit, { hours: capacity });
		}
		default: {
			return formatMessage(messages.storyPointUnit, { points: capacity });
		}
	}
};

export const getSprintStateLabel = (
	sprintState: string,
	formatMessage: (messageDescriptor: MessageDescriptor) => string,
) => {
	switch (sprintState) {
		case SPRINT_STATES.ACTIVE:
			return formatMessage(messages.activeSprint);
		case SPRINT_STATES.CLOSED:
			return formatMessage(messages.closedSprint);
		case SPRINT_STATES.FUTURE:
		default:
			return formatMessage(messages.futureSprint);
	}
};

export const convertCapacityFromPlanningUnitToSeconds = ({
	capacity,
	planningUnit,
	workingHours,
}: {
	capacity: number;
	planningUnit: string;
	workingHours: number;
}): number => {
	switch (planningUnit) {
		case IP_BOARD_DAYS_PLANNING_UNIT:
			return capacity * SECONDS_PER_HOUR * workingHours;
		case IP_BOARD_HOURS_PLANNING_UNIT:
			return capacity * SECONDS_PER_HOUR;
		default:
			return capacity;
	}
};

export const isScenarioPlanonlyTeamCheck = (teamId: string) => Number.isNaN(parseInt(teamId, 10));

import { useCallback } from 'react';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { isCompleteSprintCrossFlowEnabled } from '../../common/utils.tsx';
import { useRetros } from '../index.tsx';

export const useShowCreateRetroOrCrossSellModal = () => {
	const isCrossflowFFEnabled = isCompleteSprintCrossFlowEnabled();
	const [retrosState, retrosActions] = useRetros();
	const licensedProducts = useLicensedProducts();

	return useCallback(() => {
		if (!retrosState.shouldCreateRetro) {
			return;
		}

		let action = retrosActions.setLoadCreateRetroModal;

		if (isCrossflowFFEnabled && !licensedProducts.confluence) {
			action = retrosActions.setLoadCrossFlowModal;
		}

		action(true);
	}, [isCrossflowFFEnabled, licensedProducts, retrosState.shouldCreateRetro, retrosActions]);
};

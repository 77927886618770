import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { APP_TEAM_NAME } from '@atlassian/jira-project-create-importer-common/src/common/constants/index.tsx';
import { APP_IDENTIFIER } from './common/constants/index.tsx';
import ProjectCreateImportProgress, { type Props } from './ui/index.tsx';

const ErrorBoundary = ({ children }: { children: ReactNode }) => (
	<JSErrorBoundary
		teamName={APP_TEAM_NAME}
		id="projectCreateImportProgress"
		packageName={APP_IDENTIFIER}
		fallback="unmount"
	>
		{children}
	</JSErrorBoundary>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ErrorBoundary>
		<ProjectCreateImportProgress {...props} />
	</ErrorBoundary>
);

import React from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import { useHasToolSections } from '@atlassian/jira-software-connect-toolbar/src/controllers/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import type { BoardMenuItemLocation } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/types.tsx';

type BoardConnectItemsProps = {
	projectKey: string;
	boardId: string;
	onClickWebItem?: () => void;
};

export const BoardConnectItems = ({
	projectKey,
	boardId,
	onClickWebItem,
}: BoardConnectItemsProps) => {
	const itemLocations: BoardMenuItemLocation[] = [
		'board-tools-1',
		'view-actions-work',
		'view-actions-print',
	];

	const hasToolSections = useHasToolSections({
		boardId,
		projectKey,
		mode: 'work',
		itemLocations,
	});

	const onClick = fg('make_board_menu_popup') ? onClickWebItem : undefined;

	return (
		hasToolSections && (
			<DropdownItemGroup hasSeparator>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="board-tools-1"
					onClickWebItem={onClickWebItem}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="view-actions-work"
					onClickWebItem={onClick}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="view-actions-print"
					onClickWebItem={onClickWebItem}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					mode="work"
					onClickWebItem={onClick}
				/>
			</DropdownItemGroup>
		)
	);
};

import { expVal } from '@atlassian/jira-feature-experiments';
import { type FlagConfiguration, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { COPY_ISSUE_KEY_MENU_ID } from '../../constants.tsx';
import messages from './messages.tsx';
import type { ContentProps, Props } from './types.tsx';

const copySuccessfulFlagConfiguration: FlagConfiguration = {
	type: 'success',
	title: messages.copySuccessTitle,
	description: messages.copySuccessMessage,
};

const copySuccessfulFlagConfigurationIssueTermRefresh: FlagConfiguration = {
	type: 'success',
	title: messages.copySuccessTitleIssueTermRefresh,
	description: messages.copySuccessMessageIssueTermRefresh,
};

const copyFailureFlagConfiguration: FlagConfiguration = {
	type: 'error',
	title: messages.copyFailureTitle,
	description: messages.copyFailureMessage,
};

const copyFailureFlagConfigurationIssueTermRefresh: FlagConfiguration = {
	type: 'error',
	title: messages.copyFailureTitleIssueTermRefresh,
	description: messages.copyFailureMessage,
};

export const useCopyIssueKeyContent = ({
	isCopyKeyVisible,
	onCopyIssueKey,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isCopyKeyVisible) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.copyIssueKeyIssueTermRefresh
					: messages.copyIssueKey,
			),
			id: COPY_ISSUE_KEY_MENU_ID,
			onClick: onCopyIssueKey,
		},
	];
};

export const useCopyIssueKey = ({ isMultiSelectMode, issueKey }: Props) => {
	const { showFlag } = useFlagsService();

	const onCopyIssueKey = async () => {
		try {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			await navigator.clipboard.writeText(issueKey);
			showFlag(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? copySuccessfulFlagConfigurationIssueTermRefresh
					: copySuccessfulFlagConfiguration,
			);
		} catch (err) {
			showFlag(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? copyFailureFlagConfigurationIssueTermRefresh
					: copyFailureFlagConfiguration,
			);
		}
	};
	return useCopyIssueKeyContent({
		isCopyKeyVisible: !isMultiSelectMode,
		onCopyIssueKey,
	});
};

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import type { RowState } from '../../common/types.tsx';
import { getRenderedRange } from '../bounds-check/index.tsx';
import { type ScrollState, useScrollState } from '../use-scroll-state/index.tsx';
import { useScrollTo } from '../use-scroll-to/index.tsx';
import type { ScrollOptions } from '../use-scroll-to/types.tsx';

export interface MeasurementCacheEntry {
	height: number;
}

export interface MeasurementCache<CacheKey> {
	get(key: CacheKey): MeasurementCacheEntry | null | undefined;
	set(key: CacheKey, entry: MeasurementCacheEntry): unknown;
	has(key: CacheKey): boolean;
}

export interface CacheOptions<CacheKey> {
	getCacheKey: (index: number) => CacheKey;
	cache: MeasurementCache<CacheKey>;
}

interface Params<CacheKey> {
	/**
	 * The number of virtual rows in this list
	 */
	rowCount: number;
	/**
	 * A function that returns the default or speculated height of a given row.
	 */
	getDefaultRowSize: (index: number) => number;
	/**
	 * If provided, rows will be over-rendered even if they are off-screen, up
	 * to this height above and under the viewport rectangle.
	 */
	overscanHeight?: number;
	/**
	 * Offset of this list from the top
	 */
	offsetTop?: number;
	/**
	 * If provided, measurements will be written onto `MeasurementCache`.
	 *
	 * It will be used as the source of truth for dimensions. The `MeasurementCache`
	 * should implement the following APIs:
	 *
	 * * Getting a previously measured height by key
	 * * Setting a measured height by key
	 * * Subscribing to changes in dimensions
	 */
	cacheOptions?: CacheOptions<CacheKey>;

	/**
	 * a version string, used as an indication of rebuilding the rowStates
	 * when it has value of null, undefined or empty string, it will not be used
	 * when it is different from the previous version, we rebuild the rowStates
	 */
	version?: string | null;
}

/**
 * The position of a given virtual row and callbacks to force measurement.
 *
 * `measure` should be called with the row's element when it is first mounted synchronously in `useLayoutEffect`
 * if rows have dynamic heights. As a helper, `forceRemeasure` is provided and may be called many times after mount
 * to trigger a re-measurement of this row.
 */
export interface RowDescriptor {
	key: number;
	index: number;
	top: number;
	/**
	 * True if the row is currently visible, false if it's rendered due to overscan or some policy.
	 */
	isVisible: boolean;
	measure: (el: Element | null) => void;
	forceRemeasure: (el?: Element | null, withCache?: boolean) => void;
}

/**
 * Public API return value.
 */
export interface Result {
	/**
	 * Positions and measuring calls for each row that is determined to be visible and should be rendered.
	 */
	rows: RowDescriptor[];
	/**
	 * Measured and estimated position and height of all rows including those outside the viewport.
	 * Remove optional when cleaning up dependency_visualisation_for_program_board
	 */
	rowStates?: RowState[];
	/**
	 * Forcibly scroll to a given element
	 */
	scrollTo: (index: number, options?: ScrollOptions) => void;
	/**
	 * Total calculated height of the container including all items regardless of whether they should be rendered.
	 *
	 * Before the whole container has been scrolled through this may be lower than the real height.
	 */
	totalSize: number;
	/**
	 * True if the user is scrolling.
	 */
	isScrolling: boolean;
	reinitialize: () => void;
}

/**
 * Return default row sizes array
 */
export const buildDefaultRowStates = <CacheKey,>(
	rowCount: number,
	offsetTop: number,
	getDefaultRowSize: (i: number) => number,
	cacheOptions?: CacheOptions<CacheKey>,
): RowState[] => {
	const states = new Array(rowCount);

	let currentTop = offsetTop;
	for (let i = 0; i < rowCount; i += 1) {
		const height =
			cacheOptions?.cache.get(cacheOptions.getCacheKey(i))?.height ?? getDefaultRowSize(i);
		const result = {
			height,
			top: currentTop,
			bottom: currentTop + height,
		};
		currentTop = result.bottom;
		states[i] = result;
	}

	return states;
};

interface BuildRowDescriptorsParams<CacheKey> {
	/**
	 * The start index for the range of rows which will be rendered
	 */
	start: number;
	/**
	 * The end index for the range of rows which will be rendered
	 */
	end: number;
	/**
	 * Current estimated row positions/heights for all rows
	 */
	rowStates: RowState[];
	/**
	 * Called whenever heights change, should recalculate row positions.
	 */
	setRowHeight: (row: number, height: number) => void;
	/**
	 * Optional cache of heights. Heights will pushed into the cache on measure.
	 */
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	cacheOptions: CacheOptions<CacheKey> | void;
	/**
	 * The start index for the range of rows which is visible
	 */
	startVisible: number;
	/**
	 * The end index for the range of rows which is visible
	 */
	endVisible: number;
}

interface BuildRowDescriptorParams<CacheKey> {
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	cacheOptions: CacheOptions<CacheKey> | null | void;
	index: number;
	rowStates: RowState[];
	setRowHeight: (row: number, height: number) => void;
	isVisible: boolean;
}

const MINIMUM_CARD_HEIGHT = 0.05;

export function buildRowDescriptor<CacheKey>({
	cacheOptions,
	index,
	rowStates,
	setRowHeight,
	isVisible,
}: BuildRowDescriptorParams<CacheKey>): RowDescriptor {
	const forceRemeasure = (el?: Element | null, withCache = false) => {
		if (!el) {
			return;
		}

		if (withCache && cacheOptions?.cache?.has(cacheOptions.getCacheKey(index))) {
			return;
		}

		const { height } = el.getBoundingClientRect();
		const roundedHeight = Math.round(height * 100) / 100;

		// TODO: React DnD will set row height to 0 on pick-up, that update must be ignored.
		// This is not a clean solution.
		if (roundedHeight > MINIMUM_CARD_HEIGHT && cacheOptions) {
			const cacheKey = cacheOptions.getCacheKey(index);
			if (cacheKey !== undefined) {
				cacheOptions.cache.set(cacheKey, { height });
			}
		}

		if (height != null && height > MINIMUM_CARD_HEIGHT && rowStates[index].height !== height) {
			setRowHeight(index, height);
		}
	};

	return {
		key: index,
		index,
		top: rowStates[index].top,
		isVisible,
		measure(el: Element | null) {
			forceRemeasure(el, true);
		},
		forceRemeasure,
	};
}

const EMPTY_ROW_DESCRIPTOR: RowDescriptor[] = [];

/**
 * Build the `RowDescriptor[]` which are the return value of the public API.
 */
export function buildRowDescriptorsList<CacheKey>({
	start,
	end,
	rowStates,
	cacheOptions,
	setRowHeight,
	startVisible,
	endVisible,
}: BuildRowDescriptorsParams<CacheKey>): RowDescriptor[] {
	if (start === end) {
		return EMPTY_ROW_DESCRIPTOR;
	}

	const numRows = end - start;

	// This code is structured in a way that allows it to be changed into stable indexes and support element recycling.
	const result: RowDescriptor[] = new Array(Math.max(numRows, 0));
	for (let offset = 0; offset < numRows; offset += 1) {
		const index = start + offset;
		const isVisible = index >= startVisible && index <= endVisible;
		result[offset] = buildRowDescriptor({
			cacheOptions,
			index,
			rowStates,
			setRowHeight,
			isVisible,
		});
	}

	return result;
}

function useRows<CacheKey>(
	scrollState: ScrollState,
	rowStates: RowState[],
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	cacheOptions: CacheOptions<CacheKey> | void,
	setRowHeight: (row: number, height: number) => void,
	overscanHeight: number,
): RowDescriptor[] {
	const { start, end } = useMemo(
		() => getRenderedRange(scrollState, rowStates, overscanHeight),
		[scrollState, rowStates, overscanHeight],
	);
	const { start: startVisible, end: endVisible } = useMemo(
		() => getRenderedRange(scrollState, rowStates, 0),
		[scrollState, rowStates],
	);

	return useMemo(
		() =>
			buildRowDescriptorsList({
				start,
				end,
				rowStates,
				cacheOptions,
				setRowHeight,
				startVisible,
				endVisible,
			}),
		[start, end, rowStates, cacheOptions, setRowHeight, startVisible, endVisible],
	);
}

/**
 * Public entry-point for `fast-virtual`.
 *
 * This receives:
 *
 * * a count of rows
 * * a function that returns the default size of a row
 * * optionally an offset for this list from the top of the scroll container
 * * cache options, which is configuration for a dimensions cache per entity
 *
 * It will read scroll state from context use this information to determine which rows are visible. This hook relies
 * on a `ScrollStateContextProvider` being mounted higher in the tree and will not work otherwise.
 *
 * The return value of this function will be an array of "RowDescriptor"s, which are objects representing a visible row
 * the index of this row, its position in the container to be used for absolute positioning and callbacks to trigger
 * measuring its DOM element.
 */
export function useVirtual<CacheKey>({
	rowCount,
	getDefaultRowSize,
	offsetTop = 0,
	overscanHeight = 0,
	cacheOptions,
	version,
}: Params<CacheKey>): Result {
	const scrollState = useScrollState();

	const [rowStates, setRowStates] = useState<RowState[]>(() =>
		buildDefaultRowStates(rowCount, offsetTop, getDefaultRowSize, cacheOptions),
	);
	const reinitialize = () =>
		setRowStates(buildDefaultRowStates(rowCount, offsetTop, getDefaultRowSize, cacheOptions));
	const reinitializeRef = useRef(reinitialize);
	reinitializeRef.current = reinitialize;
	const reinitializeCb = useCallback(() => reinitializeRef.current(), []);

	const lastOffsetTop = useRef<number | null>(null);
	const lastVersion = useRef<string | undefined | null>(null);

	/**
	 * checking rowCount, offsetTop and version to rebuild the rowStates
	 * if the version is undefined, null or empty string, it will not be used
	 */
	const shouldRebuildRowStates =
		rowCount !== rowStates.length ||
		offsetTop !== lastOffsetTop.current ||
		(!isNil(version) && !isEmpty(version) && version !== lastVersion.current);

	useLayoutEffect(() => {
		if (shouldRebuildRowStates) {
			lastVersion.current = version;
			lastOffsetTop.current = offsetTop;
			const states = buildDefaultRowStates(rowCount, offsetTop, getDefaultRowSize, cacheOptions);
			setRowStates(states);
		}
	}, [
		rowStates,
		rowCount,
		offsetTop,
		cacheOptions,
		getDefaultRowSize,
		shouldRebuildRowStates,
		version,
	]);

	const scrollTo = useScrollTo(rowStates);

	const setRowHeight = useCallback(
		(row: number, height: number) => {
			setRowStates((currentRowStates) => {
				let currentTop = offsetTop;
				return currentRowStates.map((state, index) => {
					const newState = { ...state };

					if (index === row) {
						newState.height = height;
					}
					newState.top = currentTop;
					newState.bottom = currentTop + newState.height;
					currentTop = newState.bottom;
					return newState;
				});
			});
		},
		[offsetTop],
	);

	const totalSize = useMemo(() => {
		if (fg('board_rendering_optimisations')) {
			// We don't want to end up with a totalSize that is floating point. It results in the
			// ResizeObserver in useElementOffset triggering for pixel changes below 1px
			return Math.ceil(rowStates.reduce((acc, { height }) => acc + height, 0));
		}

		let sum = 0;
		rowStates.forEach(({ height }) => {
			sum += height; // This is a float
		});
		// We don't want to end up with a totalSize that is floating point. It results in the
		// ResizeObserver in useElementOffset triggering for pixel changes below 1px
		return Math.ceil(sum);
	}, [rowStates]);
	const rows = useRows(scrollState, rowStates, cacheOptions, setRowHeight, overscanHeight);

	return useMemo(
		() => ({
			rows,
			rowStates: fg('dependency_visualisation_program_board_fe_and_be') ? rowStates : undefined,
			scrollTo,
			totalSize,
			isScrolling: scrollState.isScrolling,
			reinitialize: reinitializeCb,
		}),
		[rows, rowStates, scrollTo, totalSize, scrollState.isScrolling, reinitializeCb],
	);
}

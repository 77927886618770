import React, { type ReactElement, type ReactNode, type ComponentType } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { AssigneePickerView as IssueAssigneeFieldReadonly } from '../../common/ui/read-view/popover/index.tsx';
import type { AssigneeFieldProps } from './index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueAssigneeFieldReadonly };

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyIssueAssigneeField = lazy<ComponentType<AssigneeFieldProps>>(
	() => import(/* webpackChunkName: "async-assignee-inline-edit-view" */ './index'),
);

export const AsyncIssueAssigneeField = (props: AssigneeFieldProps) => (
	<ErrorBoundary
		id="issue-field-assignee"
		packageName="jira-issue-field-assignee"
		render={() => <IssueAssigneeFieldReadonly />}
	>
		<Placeholder name="issue-assignee-field" fallback={<IssueAssigneeFieldReadonly />}>
			<AsyncLazyIssueAssigneeField {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export type IssueAssigneeFieldBoundaryProps = {
	children: ReactNode;
	fallback: ReactElement | null;
	packageName: string;
	onError?: () => void;
};

export const IssueAssigneeFieldBoundary = ({
	children,
	fallback,
	packageName,
	onError,
}: IssueAssigneeFieldBoundaryProps) => (
	<JSErrorBoundary
		id="issue-assignee-field"
		packageName={packageName}
		fallback={fallback ? () => fallback : undefined}
		onError={onError}
	>
		<Placeholder name="issue-assignee-field-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);

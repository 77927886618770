import { type RefObject, useCallback, useEffect, useRef } from 'react';
import { useDependencyLinks } from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links/index.tsx';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { useScrollState } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';

const useMeasureHeaderForDependencies = ({
	columnId,
	isUnscheduledWorkColumnPanel,
	headerRef,
}: {
	columnId: number;
	isUnscheduledWorkColumnPanel?: boolean;
	headerRef: RefObject<HTMLDivElement>;
}) => {
	const { scrollLeft } = useScrollState();
	// Hold a ref to the scrollLeft value because we're interested in the value being up to date, but
	// not interested in re-firing the useEffect every time the user scrolls horizontally on the board
	const scrollLeftRef = useRef(scrollLeft);

	useEffect(() => {
		scrollLeftRef.current = scrollLeft;
	}, [scrollLeft]);

	const [_, { updateColumnPositionMap }] = useDependencyLinks();
	const measureAndUpdateHeaderPosition = useCallback(() => {
		if (!isUnscheduledWorkColumnPanel && headerRef && headerRef.current) {
			const { x, width, height } = headerRef.current.getBoundingClientRect();
			updateColumnPositionMap({ x: x + scrollLeftRef.current, width, height }, columnId);
		}
	}, [isUnscheduledWorkColumnPanel, columnId, headerRef, updateColumnPositionMap]);

	useEffect(() => {
		measureAndUpdateHeaderPosition();
	}, [measureAndUpdateHeaderPosition]);

	useResizeObserver({
		ref: headerRef,
		onResize: measureAndUpdateHeaderPosition,
	});
};

// Component for measuring the swimlane column header's position for use in the calculation of positions for
// dependency line visualisation. This has no actual representation on the DOM - we conditionally render
// so that we can conditionally call the hook if the ref is actually defined, since resizeObserver takes only defined refs
export const HeaderMeasurement = ({
	columnId,
	isUnscheduledWorkColumnPanel,
	headerRef,
}: {
	columnId: number;
	isUnscheduledWorkColumnPanel?: boolean;
	headerRef: RefObject<HTMLDivElement>;
}) => {
	useMeasureHeaderForDependencies({ columnId, isUnscheduledWorkColumnPanel, headerRef });
	return null;
};

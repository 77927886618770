import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Props } from './types.tsx';

export const CardCover = (props: Props) => {
	const { uri, onLoad } = props;

	return (
		<Cover>
			<Image src={uri} onLoad={onLoad ?? noop} alt="" />
		</Cover>
	);
};

export default CardCover;

const coverMaxHeight = 260;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Cover = styled2.div({
	pointerEvents: 'none',
	display: 'flex',
	justifyContent: 'center',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageControl = styled.img({
	display: 'block',
	maxWidth: '100%',
	minWidth: 0,
	maxHeight: `${coverMaxHeight}px`,
	minHeight: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageExperiment = styled2.img({
	display: 'block',
	maxWidth: '100%',
	minWidth: 0,
	maxHeight: `${coverMaxHeight}px`,
	minHeight: 0,
});

const Image = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ImageExperiment,
	ImageControl,
);

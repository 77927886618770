import React, { type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import Lozenge from '@atlaskit/lozenge';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type Color,
	type ColorSchema,
	PURPLE,
} from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';

type Props = {
	color?: Color;
	children: ReactNode;
};

type LozengeTheme = {
	backgroundColor: string;
	color: string;
};

const LOZENGE_THEMES: Record<Color, LozengeTheme> = transformColorToTheme(
	(colorSchema: ColorSchema) => ({
		color: colorSchema.text,
		backgroundColor: colorSchema.textBackground,
	}),
);

const getLozengeThemeByColor = (color: Color) =>
	// This uses schema from src/packages/issue/epic-color/src/common/constants.js
	LOZENGE_THEMES[color] || LOZENGE_THEMES[PURPLE];

const CardEpic = (props: Props) => {
	// This uses schema from src/packages/issue/epic-color/src/common/constants.js
	const { color = PURPLE, children } = props;
	/* eslint-disable jira/react/no-style-attribute */
	return (
		<LozengeWrapper
			data-test-id="platform-board-kit.ui.swimlane.epic.lozenge"
			data-testid="platform-board-kit.ui.swimlane.epic.lozenge"
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
			<Lozenge style={getLozengeThemeByColor(color)}>{children}</Lozenge>
		</LozengeWrapper>
	);
	/* eslint-enable jira/react/no-style-attribute */
};

export default CardEpic;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapperControl = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapperExperiment = styled2.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
});

const LozengeWrapper = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	LozengeWrapperExperiment,
	LozengeWrapperControl,
);

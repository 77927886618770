// On compiled.migration.jsw.tanuki FF cleanup, remove styled-component-order disable ^
import React, { type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { Flex } from '@atlaskit/primitives';
import { colors, typography } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/column-header.module.css';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { COLUMN_TITLE_SELECTOR_NAME } from '../../../../constants/index.tsx';
import ColumnCardCount from './column-card-count/index.tsx';

type Props = {
	isDisabled: boolean;
	isEditing: boolean;
	isCMPBoard: boolean;
	text: string;
	showTotalCount: boolean;
	visibleCount: number;
	totalCount: number;
};

const ColumnTitle = ({
	isDisabled,
	isEditing,
	isCMPBoard,
	showTotalCount,
	text,
	totalCount,
	visibleCount,
}: Props) => {
	const convertedText = text.split(' ').join('-').toLowerCase();

	return (
		<Text
			isDisabled={isDisabled}
			aria-label={text}
			// `__board-test-hook__column-title` is used in pollinator tests
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`__board-test-hook__column-title ${styles.compactColumnTitle}`}
			data-test-id="platform-board-kit.common.ui.column-header.editable-title.column-title.column-title"
			data-testid="platform-board-kit.common.ui.column-header.editable-title.column-title.column-title"
			data-component-selector={COLUMN_TITLE_SELECTOR_NAME}
		>
			<FlexWrapper>
				<ColumnName
					title={text}
					data-testid="platform-board-kit.common.ui.column-header.editable-title.column-title.column-name"
					{...(ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') && {
						id: `platform-board-kit.ui.column-title-${convertedText}`,
					})}
				>
					{text}
				</ColumnName>
				{!!visibleCount && (
					<CountWrapper>
						<ColumnCardCount
							isEditing={isEditing}
							showTotalCount={showTotalCount}
							totalCount={totalCount}
							visibleCount={visibleCount}
							isCMPBoard={isCMPBoard}
						/>
					</CountWrapper>
				)}
			</FlexWrapper>
		</Text>
	);
};

export default ColumnTitle;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnNameControl = styled.div({
	display: 'inline-block',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'.msie-11 &': {
		width: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnNameExperiment = styled2.div({
	display: 'inline-block',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'.msie-11 &': {
		width: '100%',
	},
});

const ColumnName = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ColumnNameExperiment,
	ColumnNameControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountWrapper = styled2.div({
	display: 'flex',
	marginLeft: token('space.050', '4px'),
	flexShrink: 0,
});

// FIXME: type error from typography mixin, typing on `fontWeight` too strict
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextControl = styled.h2`
	${typography.h400};

	display: flex;
	text-transform: uppercase;
	font-weight: 500;
	color: ${token('color.text.subtlest', colors.N300)};
	letter-spacing: normal;
	font-size: 12px;
	height: 100%;
	margin-left: ${token('space.100', '8px')};
	margin-right: ${token('space.100', '8px')};
	overflow: hidden;

	.msie-11 & {
		font-weight: 600;
	}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextExperiment = styled2.h2<any>({
	display: 'flex',
	textTransform: 'uppercase',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	font: token('font.heading.xxsmall'),
	fontWeight: token('font.weight.medium'),
	height: '100%',
	marginLeft: token('space.100', '8px'),
	marginRight: token('space.100', '8px'),
	overflow: 'hidden',
	letterSpacing: 'normal',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'.msie-11 &': {
		fontWeight: 600,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Text = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	TextExperiment,
	TextControl,
);

// When cleaning up compiled.migration.jsw.tanuki align, 'alignContent: center' could be added to the styled H2. It wasn't possible due to Eslint error when using isVisualRefreshEnabled()
const FlexWrapper = ({ children }: { children: ReactNode }) =>
	isVisualRefreshEnabled() ? <Flex alignItems="center">{children}</Flex> : <>{children}</>;

import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightManager, SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useFieldConfigActions,
	useIssueFieldConfigWithoutRefetch,
} from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	useFieldsValues,
	useFieldsValuesActions,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { BoardView } from '@atlassian/jira-platform-board-kit/src/common/constants/index.tsx';
import type {
	BoardType,
	IssueParent as BoardKitIssueParent,
} from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import { Card as JiraCard } from '@atlassian/jira-platform-board-kit/src/ui/card/main.tsx';
import type { RenderSummaryProps } from '@atlassian/jira-platform-card/src/common/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { TimeEstimateKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { IssueId as SharedIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { MEATBALL_MENU_APPEARANCE } from '@atlassian/jira-software-context-menu/src/common/constants.tsx';
import {
	useModalDialogActions,
	useReturnFocusTo,
} from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';
import {
	useShowEpics,
	useCardFieldsSettings,
} from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useTraitsActions } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Capability } from '../../../../../common/capability/index.tsx';
import { getDateFormat } from '../../../../../common/utils/get-date-format/index.tsx';
import { isViewSettingAsPanelExpEnabledWithNoExposure } from '../../../../../feature-flags.tsx';
import { CAN_EDIT_ISSUE } from '../../../../../model/permission/permission-types.tsx';
import { PlanModalEntrypointContext } from '../../../../../services/board/plan-modal-entrypoint/index.tsx';
import { fetchIssueLinksStats } from '../../../../../state/actions/issue-links-stats/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../state/index.tsx';
import {
	getPermissionsSelector,
	isIncrementPlanningReadOnly,
} from '../../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getFirstSelectableIssueAscending } from '../../../../../state/selectors/issue/issue-navigation-selectors.tsx';
import { getTimeTrackingOptions } from '../../../../../state/selectors/work/work-selectors.tsx';
import {
	useCapability,
	useIsIncrementPlanningBoard,
	useIsJSMBoard,
	useIsJSWBoard,
} from '../../../../../state/state-hooks/capabilities/index.tsx';
import { AssigneeField } from './assignee-field/index.tsx';
import { CARD_COVER_SPOTLIGHT_TARGET_ID } from './constants.tsx';
import { ContextMenu } from './context-menu/index.tsx';
import { IssueLinksIndicator } from './issue-links-indicator/index.tsx';
import { IssueLinksStats } from './issue-links-stats/index.tsx';
import { IssueParentField } from './issue-parent-field/index.tsx';
import { OriginalEstimateField } from './original-estimate-field/index.tsx';
import { StoryPointField } from './story-point-field/index.tsx';
import { Summary } from './summary/index.tsx';
import type {
	StateProps,
	DispatchProps,
	OwnProps,
	CardInnerProps,
	NewCardProps,
} from './types.tsx';
import {
	getShouldShowLabels,
	transformToIssueFieldConfigValues,
	transformToIssueFieldValues,
	useShouldShowCardCoverChangeboarding,
} from './utils.tsx';

export type Props = StateProps & DispatchProps & OwnProps;

const selectedCardIds: number[] = [];

export const CardInner = ({
	id,
	issue,
	isFocused,
	onEnterPress,
	onFocus,
	onRippleEnd,
	userCanViewMenu,
	Cover,
	parent,
	issueParents,
	onIssueChange,
	onClick,
	onCloseOtherPanels,
	hideEpic,
	isCMPBoard,
	isFlagged,
	isActive = false,
	isIssueViewOpen,
	...props
}: CardInnerProps) => {
	const dispatch = useBoardDispatch();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isIncrementPlanningBoard = useIsIncrementPlanningBoard() ?? false;
	const isIPBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	const isJSWBoard = useIsJSWBoard() ?? false;
	const isJSMBoard = useIsJSMBoard() ?? false;

	const cloudId = useCloudId();
	const accountId = useAccountId();

	const { fetchTraits } = useTraitsActions();

	if (fg('jsw_cmp_card_covers_changeboarding')) {
		fetchTraits(['SITE_USER'], { cloudId, accountId });
	}

	const isFirstIssue = issue.id === useBoardSelector(getFirstSelectableIssueAscending);
	const shouldShowCardCoverChangeboarding = useShouldShowCardCoverChangeboarding();
	const issueShouldShowCardCoverChangeboarding = isFirstIssue && shouldShowCardCoverChangeboarding;

	const viewSettings = isViewSettingAsPanelExpEnabledWithNoExposure()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCardFieldsSettings()
		: {
				showIssueType: true,
				showIssueKey: true,
				showEstimate: true,
				showPriority: true,
				showDevelopment: true,
				showAssignee: true,
				showDaysInColumn: true,
				showDueDate: true,
				showLabels: true,
				cardExtraFields: undefined,
			};

	const returnFocusTo = useReturnFocusTo();
	const { setReturnFocusTo } = useModalDialogActions();
	const menuTriggerRef = useRef<HTMLButtonElement | null>(null);

	const triggerIsFocused = Boolean(
		returnFocusTo?.current &&
			menuTriggerRef.current &&
			returnFocusTo?.current === menuTriggerRef.current,
	);

	const handleMenuOpenChange = useCallback(
		(isOpen: boolean) => {
			if (isOpen && menuTriggerRef?.current) {
				setReturnFocusTo(menuTriggerRef);
			}
		},
		[setReturnFocusTo],
	);

	const { entryPointActions } = useContext(PlanModalEntrypointContext);

	const renderContextMenu = () => {
		if (fg('board_card_context_menu_optimistic_card_fix')) {
			const isNotOptimisticIssue = fg('program_board_misc_improvements')
				? Number(issue.id) > 0 || isIncrementPlanningBoard
				: Number(issue.id) > 0;

			// Do not render in optimistic cards
			return isNotOptimisticIssue ? (
				<ContextMenu
					issueId={issue.id}
					issueKey={issue.key}
					summary={issue.summary}
					selectedCardIds={selectedCardIds}
					appearance={MEATBALL_MENU_APPEARANCE}
					onOpenChange={handleMenuOpenChange}
					triggerRef={menuTriggerRef}
				/>
			) : null;
		}

		return (
			<ContextMenu
				issueId={issue.id}
				issueKey={issue.key}
				summary={issue.summary}
				selectedCardIds={selectedCardIds}
				appearance={MEATBALL_MENU_APPEARANCE}
				onOpenChange={handleMenuOpenChange}
				triggerRef={menuTriggerRef}
			/>
		);
	};

	const renderContextMenuWithSpotlight = () => (
		<SpotlightManager>
			<SpotlightTarget name={CARD_COVER_SPOTLIGHT_TARGET_ID}>
				<SpotlightPulse radius={3}>{renderContextMenu()}</SpotlightPulse>
			</SpotlightTarget>
		</SpotlightManager>
	);

	const renderMenu = () => {
		if (!userCanViewMenu) {
			return null;
		}

		return issueShouldShowCardCoverChangeboarding
			? renderContextMenuWithSpotlight()
			: renderContextMenu();
	};

	const onEnterPressCb = useCallback(() => {
		onEnterPress(issue.key);
	}, [onEnterPress, issue.key]);

	const onFocusCb = useCallback(() => {
		onFocus(id);
	}, [id, onFocus]);

	const onRippleEndCb = useCallback(() => {
		onRippleEnd(id);
	}, [id, onRippleEnd]);

	const viewMode = useViewMode();

	const onClickCb = useCallback(
		(issueId: IssueId, withCmd: boolean, withShift: boolean, externalAction?: ExternalAction) => {
			if (isIncrementPlanningBoard && !withCmd && !withShift && fg('issue_view_in_program_board')) {
				entryPointActions.load();
			}

			onClick(issueId, withCmd, withShift, externalAction);

			if (viewMode === 'SIDEBAR' && !withCmd) {
				onCloseOtherPanels();
			}
		},
		[entryPointActions, isIncrementPlanningBoard, onClick, onCloseOtherPanels, viewMode],
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const cover = useMemo(() => <Cover id={id} onImageLoad={onIssueChange} />, [id, onIssueChange]);
	const issueParent: BoardKitIssueParent | null = useMemo(
		() => (parent ? { ...parent, issueType: { ...parent.issueType } } : null),
		[parent],
	);

	const shouldRenderEpic = useShowEpics();
	const isFlexible = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);

	const hasCompactIssueType = useCapability(Capability.RENDER_COMPACT_ISSUE_TYPE_ICON_ON_CARD);
	let dateFormat = null;
	if (issue?.dueDate) {
		if (isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')) {
			dateFormat = 'dd MMM yyyy';
		} else {
			dateFormat = getDateFormat(issue.dueDate);
		}
	}

	const renderIssueLinksIndicator = useCallback(() => {
		if (!isIncrementPlanningBoard || isEmpty(issue.issueLinks)) {
			return null;
		}
		return <IssueLinksIndicator issueId={issue.id} issueKey={issue.key} />;
	}, [isIncrementPlanningBoard, issue.id, issue.key, issue.issueLinks]);

	const onIssueUnlink = useCallback(
		(issueIds: SharedIssueId[]) =>
			dispatch(fetchIssueLinksStats(issueIds, { analyticsEvent: createAnalyticsEvent({}) })),
		[createAnalyticsEvent, dispatch],
	);

	// Card should not steal focus away from the issue view
	const shouldFocus = isFocused && !isIssueViewOpen;

	const renderIssueLinksStats = () => (
		<IssueLinksStats
			id={issue.id}
			issueKey={issue.key}
			summary={issue.summary}
			typeId={issue.typeId}
			onIssueUnlink={onIssueUnlink}
		/>
	);

	const getBoardViewType = (): BoardType => {
		if (isJSWBoard && !isIncrementPlanningBoard) {
			return BoardView.JSW_BOARD;
		}
		if (isIncrementPlanningBoard) {
			return BoardView.INCREMENT_PLANNING_BOARD;
		}
		if (isJSMBoard) {
			return BoardView.JSM_BOARD;
		}
		// default to JSW_BOARD
		return BoardView.JSW_BOARD;
	};
	const showLabelsOutsideOfCustomFieldsList = getShouldShowLabels(
		viewSettings.showLabels,
		isCMPBoard,
	);

	// change to just onClickCb on issue_view_in_program_board clean up
	const clickCbWithFG = () => {
		if (fg('issue_view_in_program_board')) {
			return onClickCb;
		}
		return !isIPBoardReadOnly ? onClickCb : undefined;
	};
	const shouldPersistActions =
		issueShouldShowCardCoverChangeboarding || isActive || triggerIsFocused;

	return (
		<JiraCard
			{...props}
			id={id}
			issue={issue}
			parent={shouldRenderEpic ? issueParent : null}
			shouldFocus={shouldFocus}
			isFlexible={isFlexible}
			onClick={clickCbWithFG()}
			onEnterPress={onEnterPressCb}
			onFocus={onFocusCb}
			onRippleEnd={onRippleEndCb}
			cover={cover}
			CustomContextMenu={
				!isIPBoardReadOnly &&
				(!fg('avoid_invalid_issueid_in_board_context_menu') || userCanViewMenu)
					? ContextMenu
					: undefined
			}
			renderCustomMenu={renderMenu}
			onSizeChange={onIssueChange}
			isCMPBoard={isCMPBoard}
			selectedCardIds={selectedCardIds}
			dateFormat={dateFormat}
			hasCompactIssueType={hasCompactIssueType}
			isIPBoard={isIncrementPlanningBoard}
			isFlagged={!isIncrementPlanningBoard && isFlagged}
			renderIssueLinksIndicator={renderIssueLinksIndicator}
			renderIssueLinksStats={renderIssueLinksStats}
			persistActions={shouldPersistActions}
			showDaysInColumn={viewSettings.showDaysInColumn}
			shouldRenderCardType={viewSettings.showIssueType}
			showIssueKey={viewSettings.showIssueKey}
			showEstimate={viewSettings.showEstimate}
			showPriority={viewSettings.showPriority}
			showDevelopment={viewSettings.showDevelopment}
			showCardExtraFields={viewSettings.cardExtraFields}
			showAssignee={viewSettings.showAssignee}
			showDueDate={viewSettings.showDueDate}
			boardType={getBoardViewType()}
			showLabels={showLabelsOutsideOfCustomFieldsList}
		/>
	);
};

export const NewCard = (props: NewCardProps) => {
	const {
		issue,
		assignee,
		parent,
		issueParents,
		onIssueChange,
		shouldRenderRichCard,
		projectIds,
		isCMPBoard,
		estimate: estimateExtended,
		projectKey,
	} = props;

	const [isSummaryEditing, setIsSummaryEditing] = useState(false);
	const [, { setIssue }] = useFieldsValuesActions();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();

	// In the increment planning board, scenario issue keys do not yet have a numeric identifier as
	// they have not been created in Jira yet. In this case, the key is equal to the projectKey,
	// and thus not unique. Instead, we pass the issue id to the field config service (as it
	// requires a unique identifier for each issue).
	const key = isIncrementPlanningBoard && issue.key === projectKey ? `${issue.id}` : issue.key;
	const [issueFieldsValues] = useFieldsValues(key);
	const [{ value: issueFieldConfig }] = useIssueFieldConfigWithoutRefetch(key);
	const [, { setIssueConfig }] = useFieldConfigActions();
	const canEditIssues = useBoardSelector((state) => getPermissionsSelector(state)[CAN_EDIT_ISSUE]);

	const timeTrackingOptions = useBoardSelector(getTimeTrackingOptions);

	const inlineEditPlacement =
		isIncrementPlanningBoard && issue.columnId === UNSCHEDULED_COLUMN_ID ? 'top-end' : undefined;

	const renderSummary = useCallback(
		(renderSummaryProps: RenderSummaryProps) => (
			<Summary
				issueId={issue.id}
				issueKey={key}
				onSizeChange={onIssueChange}
				isSummaryEditing={isSummaryEditing}
				setIsSummaryEditing={setIsSummaryEditing}
				shouldRenderRichField={shouldRenderRichCard}
				dialogPlacement={inlineEditPlacement}
				{...renderSummaryProps}
			/>
		),
		[issue.id, key, onIssueChange, isSummaryEditing, shouldRenderRichCard, inlineEditPlacement],
	);

	const renderEstimateField = useCallback(() => {
		// when estimate feature is disabled
		if (!estimateExtended || isNil(estimateExtended.fieldId)) return null;

		if (estimateExtended.fieldId === TimeEstimateKey) {
			return (
				<OriginalEstimateField
					key={`card-estimate-${issue.estimate}`}
					issueId={issue.id}
					issueKey={issue.key}
					timeTrackingOptions={timeTrackingOptions}
					estimate={issue.estimate}
					shouldRenderRichField={shouldRenderRichCard}
				/>
			);
		}
		return (
			<StoryPointField
				key={`card-estimate-${issue.estimate}`}
				issueId={issue.id}
				issueKey={key}
				estimate={issue.estimate}
				storyPointFieldId={estimateExtended.fieldId}
				shouldRenderRichField={shouldRenderRichCard}
				dialogPlacement={inlineEditPlacement}
			/>
		);
	}, [
		estimateExtended,
		issue.estimate,
		issue.id,
		issue.key,
		key,
		shouldRenderRichCard,
		timeTrackingOptions,
		inlineEditPlacement,
	]);

	const renderIssueParentField = useCallback(
		() => (
			<IssueParentField
				issueId={issue.id}
				issueKey={issue.key}
				currentProjectId={issue.projectId}
				projectIds={projectIds}
				isCompanyManaged={isCMPBoard}
				isIncrementPlanningBoard={isIncrementPlanningBoard}
				parentSummary={parent?.summary || null}
				parentColor={parent?.color || null}
				issueParents={issueParents}
				shouldRenderRichField={shouldRenderRichCard}
				projectKey={projectKey}
			/>
		),
		[
			isCMPBoard,
			issue.id,
			issue.key,
			issue.projectId,
			issueParents,
			parent,
			projectIds,
			shouldRenderRichCard,
			isIncrementPlanningBoard,
			projectKey,
		],
	);

	const renderAssignee = useCallback(
		() => (
			<AssigneeField
				issueKey={issue.key}
				assignee={assignee}
				shouldRenderRichField={shouldRenderRichCard}
				isCMPBoard={isCMPBoard}
				issueId={Number(issue.id)}
			/>
		),
		[issue.key, assignee, shouldRenderRichCard, isCMPBoard, issue.id],
	);

	useEffect(() => {
		if (shouldRenderRichCard && !issueFieldsValues) {
			setIssue(key, transformToIssueFieldValues({ issue, assignee, parent, estimateExtended }));
		}
	}, [
		shouldRenderRichCard,
		issueFieldsValues,
		issue.key,
		setIssue,
		parent,
		issue,
		assignee,
		timeTrackingOptions,
		estimateExtended,
		key,
	]);

	useEffect(() => {
		if (shouldRenderRichCard && !issueFieldConfig) {
			setIssueConfig(
				key,
				transformToIssueFieldConfigValues({
					issue,
					estimateExtended,
					isEditable: canEditIssues,
				}),
			);
		}
	}, [
		canEditIssues,
		estimateExtended,
		issue,
		issueFieldConfig,
		parent,
		setIssueConfig,
		shouldRenderRichCard,
		key,
	]);

	return (
		<ContextualAnalyticsData
			attributes={{
				issueId: props.issue.id,
			}}
		>
			<CardInner
				{...props}
				{...(!isCMPBoard && {
					renderSummary,
					userCanViewMenu: props.userCanViewMenu && !isSummaryEditing,
				})}
				renderIssueParentField={isCMPBoard ? undefined : renderIssueParentField}
				renderAssignee={renderAssignee}
				{...(!isCMPBoard && { renderEstimateField })}
				isDragDisabled={props.isDragDisabled || isSummaryEditing}
			/>
		</ContextualAnalyticsData>
	);
};

export const NewCardWithNullableIssue = (props: Props) => {
	const { issue, ...rest } = props;
	if (issue !== null) {
		return <NewCard {...rest} issue={issue} />;
	}
	return null;
};

const Card = (props: Props) => {
	const { issue, ...rest } = props;
	if (issue !== null) {
		return <CardInner {...rest} issue={issue} />;
	}
	return null;
};

export default Card;

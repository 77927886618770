import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	type ProjectType,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';

export const CORE_INVITES_IN_JIRA_PROJECTS = {
	CONTROL: 'control',
	NOT_ENROLLED: 'not-enrolled',
	INVITE_ONLY: 'invite-only-variation',
	INVITE_ONLY_NOT_ENROLLED: 'invite-only-not-enrolled',
	INVITE_RA: 'invite-ra-variation',
	INVITE_RA_NOT_ENROLLED: 'invite-ra-variation',
} as const;

export type CORE_INVITES_IN_JIRA_PROJECTS =
	(typeof CORE_INVITES_IN_JIRA_PROJECTS)[keyof typeof CORE_INVITES_IN_JIRA_PROJECTS];

export type CoreInvitesInJiraProjectsCohort =
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.NOT_ENROLLED
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.CONTROL
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY_NOT_ENROLLED
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA
	| typeof CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA_NOT_ENROLLED;

const getCoreInvitesInJiraProjects = (): CORE_INVITES_IN_JIRA_PROJECTS =>
	getMultivariateFeatureFlag(
		'core-invites-in-jira-projects',
		CORE_INVITES_IN_JIRA_PROJECTS.NOT_ENROLLED,
		[
			CORE_INVITES_IN_JIRA_PROJECTS.NOT_ENROLLED,
			CORE_INVITES_IN_JIRA_PROJECTS.CONTROL,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY_NOT_ENROLLED,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA_NOT_ENROLLED,
		],
	);

// INVITE_ONLY displays the experiment only when users can direct invite
// INVITE_RA is shown for both users that can and can't invite.
export const isCoreInvitesInJiraProjectsEnabled = (isSiteAdmin: boolean) => {
	const flag = getCoreInvitesInJiraProjects();

	return (
		(isSiteAdmin && flag === CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY) ||
		flag === CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA
	);
};

export const isElligibleProject = (projectType: ProjectType) =>
	projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT;

export const isServiceProject = (projectType: ProjectType) => projectType === SERVICE_DESK_PROJECT;

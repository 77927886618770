import { useState, useCallback, useEffect } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { cloneIssueExperienceDescription } from '../experience-description/index.tsx';
import type { CloneMetaResponse, CloneMetaState } from './types.tsx';

const getCloneMetaUrl = (issueKey: IssueKey) => `/rest/internal/2/issue/${issueKey}/clone/meta`;

export const useCloneMetaService = (issueKey: IssueKey, projectType: ProjectType | null) => {
	const cloneMetaUrl = getCloneMetaUrl(issueKey);
	const appEditions = useAppEditions();

	const [metaResponse, setMetaResponse] = useState<CloneMetaState>({
		loadingState: 'LOADING',
	});

	const fetchCloneMeta = useCallback(async () => {
		fetch(cloneMetaUrl, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new FetchError(response.status, response.statusText);
				}
				return response.json();
			})
			.then((response: CloneMetaResponse) => {
				sendExperienceAnalytics(
					cloneIssueExperienceDescription(true, 'META', 'clone-issue', projectType, appEditions),
				);

				setMetaResponse({ loadingState: 'SUCCESS', meta: response });
			})
			.catch((error) => {
				// Broken meta API => degraded experience. Everything still works with defaults.
				// 4XXs indicate the API is behaving as expected; an undefined or zero error status
				// code indicates a browser error that we don't want to track as a failure.
				const actuallySuccessfulStatusCodes = [401, 403, 404];

				if (actuallySuccessfulStatusCodes.includes(error.statusCode)) {
					sendExperienceAnalytics(
						cloneIssueExperienceDescription(true, 'META', 'clone-issue', projectType, appEditions),
					);
				} else if (error.statusCode) {
					sendExperienceAnalytics(
						cloneIssueExperienceDescription(
							false,
							'META',
							'clone-issue',
							projectType,
							appEditions,
							`${error.message} (Status: ${error.statusCode})`,
						),
					);
				}

				setMetaResponse({ loadingState: 'ERROR' });
			});
	}, [cloneMetaUrl, projectType, appEditions]);

	useEffect(() => {
		fetchCloneMeta();
	}, [fetchCloneMeta]);

	return [metaResponse] as const;
};

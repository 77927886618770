import { useMemo } from 'react';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useNextBestTaskContainer } from '../next-best-task-container/index.tsx';

export const useContextAri = (): { sprintAri: string | null; projectAri: string | null } => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const [{ sprints: activeSprints }] = useNextBestTaskContainer();
	const { data: project, loading, error } = useProjectContext();
	const projectAri = useMemo(() => {
		if (project?.projectId && String(project?.projectId) !== 'undefined' && !loading && !error) {
			return createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: String(project?.projectId),
			});
		}
		return null;
	}, [cloudId, error, loading, project?.projectId]);

	const sprintAri: string | null = useMemo(() => {
		// Note: for now we only generate sprintAri if there is only one active sprint in the list
		if (activeSprints && activeSprints.length === 1) {
			const sprintId = activeSprints[0].id;
			return createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'sprint',
				activationId,
				resourceId: sprintId.toString(),
			});
		}
		return null;
	}, [cloudId, activationId, activeSprints]);

	return { sprintAri, projectAri };
};

import React, { forwardRef } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { boardMenuItems } from '../common/constants.tsx';
import { MenuContextContainer } from '../controllers/menu-context/index.tsx';
import { SelectedIssuesContainer } from '../controllers/selected-issues/index.tsx';
import { usePermissionService } from '../services/permission-service/index.tsx';
import { useProjectService } from '../services/project-service/index.tsx';
import MenuButton from './button/main.tsx';
import type { Props as ButtonProps } from './button/types.tsx';
import Popup from './popup/main.tsx';
import type { Props, ProjectAwareMenuProps, BoardMenuProps } from './types.tsx';

const TARGET_NAME = 'automation-menu-target';

const WrappedMenuButton = forwardRef<HTMLElement, ButtonProps>((props: ButtonProps, ref) => (
	<SpotlightTarget name={TARGET_NAME}>
		<MenuButton {...props} ref={ref} />
	</SpotlightTarget>
));

/**
 * TMP only automation menu
 * @deprecated - Use project aware menu always where possible.
 */
const AutomationMenu = (props: Props) => {
	const { canManageAutomations = false, ...popupProps } = props;
	const popup = (
		<Popup
			{...popupProps}
			Trigger={WrappedMenuButton}
			canManageAutomations={canManageAutomations}
		/>
	);

	return (
		<ErrorBoundary id="automation-menu">
			<SpotlightManager>
				<MenuContextContainer>
					<SelectedIssuesContainer>{popup}</SelectedIssuesContainer>
				</MenuContextContainer>
				<EngagementSpotlight
					engagementId={TARGET_NAME}
					variables={{
						projectId: props.projectId,
						projectKey: props.projectKey,
					}}
				/>
			</SpotlightManager>
		</ErrorBoundary>
	);
};

const ProjectAwareAutomationMenu = (props: ProjectAwareMenuProps) => {
	const project = useProjectService();
	const { isProjectAdmin } = usePermissionService(project && project.projectId);

	if (project) {
		return (
			<AutomationMenu
				{...props}
				isSimplified={
					ff('jsw-replace-automationmenu-with-new-context-aware-menu_rltpk')
						? project.isSimplified
						: false
				}
				isUIF={props.isUIF ?? false} // Will be cleaned up after UIF
				projectType={project.projectType}
				projectId={project.projectId}
				projectKey={project.projectKey}
				canManageAutomations={isProjectAdmin}
			/>
		);
	}
	return null;
};

export const BoardMenu = (props: BoardMenuProps) => (
	<ProjectAwareAutomationMenu {...props} items={boardMenuItems} />
);

export default AutomationMenu;

import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import type { ProjectContext } from '@atlassian/jira-providers-container-context/src/types.tsx';

export const useProjectContext = (): ProjectContext | null => {
	const [{ data: containerContext }] = useContainerContext();
	if (containerContext === null || containerContext.contextType !== 'project') {
		return null;
	}
	return containerContext;
};

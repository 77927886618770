import React, { memo } from 'react';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import { TypeIconContainer, InnerIconContainer, InnerActionContainer } from './styled.tsx';
import type { Props } from './types.tsx';

/**
 * Displays the issue type icon, as well as the drag handle on hover/focus.
 *
 * Dragging is forcibly disabled when the number of children is over the
 * child issue limit. In that case the restricted message is displayed in the
 * tooltip.
 */
export const IssueType = memo<Props>(
	({
		issueTypeName,
		issueTypeIconUrl,
		isDraggable,
		isActionVisible,
		restrictedReorderingMessage,
		onHandleUserClickAnalytics,
		reorderMenu,
		issueTypeIcon,
	}: Props) => {
		const intl = useIntl();
		const title = intl.formatMessage(messages.issueTypeTitle, { issueTypeName });
		const hasValidRestrictedMessage = restrictedReorderingMessage !== '';
		const tooltipContent = issueTypeName || '';

		const action = reorderMenu || (
			<DragHandlerIcon label="" primaryColor={token('color.icon.subtle', N300)} />
		);

		// Using an intermediate div(TypeIconContainer) since AKTooltip needs an element to bind to
		// We aren't wrapping just ListIcon or AsyncIcon since AKTooltip is stateful
		// and we don't want it to unmount as the mouseHover changes
		return (
			<Tooltip
				content={
					!isDraggable && hasValidRestrictedMessage
						? String(restrictedReorderingMessage)
						: tooltipContent
				}
				hideTooltipOnMouseDown={isDraggable}
				position="top"
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				testId="issue-line-card.issue-type.tooltip"
			>
				<TypeIconContainer
					onClick={() => onHandleUserClickAnalytics?.('typeIcon')}
					data-testid="issue-line-card-issue-type.issue-type"
					isActionVisible={isDraggable && isActionVisible}
					isRestricted={hasValidRestrictedMessage}
				>
					<InnerIconContainer>
						{issueTypeIcon ?? <AsyncIcon alt={title} url={issueTypeIconUrl} />}
					</InnerIconContainer>
					{isDraggable && <InnerActionContainer>{action}</InnerActionContainer>}
				</TypeIconContainer>
			</Tooltip>
		);
	},
);

const LINK_IS_BLOCKED_BY = 'is blocked by' as const;
const LINK_IS_DUPLICATED_BY = 'is duplicated by' as const;
const LINK_BLOCKS = 'BLOCKS' as const;
const LINK_IS_CLONED_BY = 'is cloned by' as const;
const LINK_CLONES = 'clones' as const;
const LINK_IS_CAUSED_BY = 'is caused by' as const;
const LINK_CAUSES = 'causes' as const;
const LINK_RELATES_TO = 'relates to' as const;
const LINK_DUPLICATES = 'duplicates' as const;

// use LINK_CUSTOM for all custom type name
const LINK_CUSTOM = 'custom' as const;

export type LinkIssueType =
	| typeof LINK_IS_BLOCKED_BY
	| typeof LINK_IS_DUPLICATED_BY
	| typeof LINK_BLOCKS
	| typeof LINK_IS_CLONED_BY
	| typeof LINK_CLONES
	| typeof LINK_IS_CAUSED_BY
	| typeof LINK_CAUSES
	| typeof LINK_RELATES_TO
	| typeof LINK_DUPLICATES;

const LINK_ISSUE_TYPE: LinkIssueType[] = [
	LINK_IS_BLOCKED_BY,
	LINK_IS_DUPLICATED_BY,
	LINK_BLOCKS,
	LINK_IS_CLONED_BY,
	LINK_CLONES,
	LINK_IS_CAUSED_BY,
	LINK_CAUSES,
	LINK_RELATES_TO,
	LINK_DUPLICATES,
];

export const safeLinkTypeName = (value: string): string =>
	// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'LinkIssueType'.
	LINK_ISSUE_TYPE.includes(value) ? value : LINK_CUSTOM;

import React, {
	type SyntheticEvent,
	useCallback,
	useLayoutEffect,
	useMemo,
	type KeyboardEvent,
} from 'react';
import { css, styled } from '@compiled/react';
import type { Placement } from '@atlaskit/inline-dialog';
import { token } from '@atlaskit/tokens';
import { useFieldConfigWithoutRefetch } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	INLINE_EDIT_ERROR_CONTENT_SELECTOR,
	INLINE_EDIT_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-summary-inline-edit/src/common/constants.tsx';
import { AsyncSummaryInlineEdit } from '@atlassian/jira-issue-field-summary-inline-edit/src/ui/async.tsx';
import { SummaryInlineEditErrorBoundary } from '@atlassian/jira-issue-field-summary-inline-edit/src/ui/error-boundary/index.tsx';
import type { RenderSummaryProps } from '@atlassian/jira-platform-card/src/common/types.tsx';
import {
	shouldHighlightText,
	HighlightText,
} from '@atlassian/jira-platform-card/src/common/ui/highlight-text/index.tsx';
import { CardSummary } from '@atlassian/jira-platform-card/src/common/ui/summary/index.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { RenderInlineEditFieldProps } from '../../../../../../common/fields/types.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field/index.tsx';
import type { EditableFieldProps } from '../../../../../../common/fields/use-editable-field/types.tsx';
import { PACKAGE_NAME } from '../../../../../../model/constants.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import { issueIncrementPlanningUpdate } from '../../../../../../state/actions/issue/update/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../../state/state-hooks/capabilities/index.tsx';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants.tsx';

export type SummaryProps = RenderInlineEditFieldProps &
	RenderSummaryProps & {
		issueId: IssueId;
		issueKey: IssueKey;
		onSizeChange?: () => void;
		isSummaryEditing: boolean;
		setIsSummaryEditing: (isSummaryEditing: boolean) => void;
		dialogPlacement?: Placement;
	} & Partial<EditableFieldProps>;

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

// Prevent enter from opening issue view
const stopEnterPropagation = (e: KeyboardEvent) => {
	if (e.key === 'Enter') {
		e.stopPropagation();
	}
};

const SummaryInner = ({
	shouldMenuRender,
	highlight,
	issueId,
	issueKey,
	text,
	onSizeChange,
	isSummaryEditing,
	dialogPlacement,
	setIsSummaryEditing,
	onSubmit,
	onUpdate,
	onFailure,
}: SummaryProps) => {
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const dispatch = useBoardDispatch();

	const highlightText = useMemo(
		() =>
			highlight && shouldHighlightText(text, highlight) ? (
				<HighlightText phrases={highlight} content={text} />
			) : undefined,
		[highlight, text],
	);

	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));

	useLayoutEffect(() => {
		if (onSizeChange) {
			requestAnimationFrame(onSizeChange);
		}
	}, [isSummaryEditing, onSizeChange]);

	const onEditStateChange = useCallback(
		(newIsEditing: boolean) => {
			dispatch(setInlineEditing('summary', issueId, newIsEditing));
			setIsSummaryEditing(newIsEditing);
		},
		[setIsSummaryEditing, dispatch, issueId],
	);

	return (
		<Wrapper
			shouldMenuRender={shouldMenuRender}
			isSummaryEditing={isSummaryEditing}
			onClick={isSummaryEditing ? stopPropagation : undefined}
			onKeyDown={stopEnterPropagation}
			disableClick={preventInlineEditing && !isSummaryEditing}
		>
			<AsyncSummaryInlineEdit
				issueId={issueId.toString()}
				issueKey={issueKey}
				highlightText={highlightText}
				onChange={onSizeChange}
				onEditStateChange={onEditStateChange}
				onCancel={onSizeChange}
				onSubmit={onSubmit}
				onUpdate={onUpdate}
				onFailure={onFailure}
				dialogPlacement={dialogPlacement}
				/**
				 * "disableSummaryUnderlineText" and "onSaveField" props are currently only used by Increment planning boards of Advanced Roadmaps
				 * - "disableSummaryUnderlineText" is passed down to "issue/fields/summary-components/summary-inline-edit/src/ui/main.tsx" in order to
				 *   remove the underline styling rendered on the summary text when hovering over a card in the board
				 * - "onSaveField" is passed down to "issue/fields/summary-components/summary-inline-edit-state/src/controllers/with-sweet-state/index.tsx"
				 *   in order to override the default "saveField" function defined in "issue/fields/field-base/src/services/edit-field-service/main.tsx"
				 */
				{...(isIncrementPlanningBoard && {
					disableSummaryUnderlineText: true,
					onSaveField: async (_: string, fieldId: string, fieldValue: string | null) => {
						dispatch(
							issueIncrementPlanningUpdate({
								issueId,
								fieldId,
								fieldValue,
							}),
						);
					},
				})}
			/>
		</Wrapper>
	);
};

export const Summary = ({ shouldRenderRichField, ...props }: SummaryProps) => {
	const [issueSummaryFieldValue] = useFieldValue({
		issueKey: toIssueKey(props.issueKey),
		fieldKey: SUMMARY_TYPE,
	});
	const [{ value: summaryFieldConfig }] = useFieldConfigWithoutRefetch(
		props.issueKey,
		SUMMARY_TYPE,
	);
	const shouldRenderRich = shouldRenderRichField && issueSummaryFieldValue && summaryFieldConfig;

	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});

	const fallback = useMemo(() => <CardSummary {...props} />, [props]);

	if (shouldRenderRich) {
		return (
			<SummaryInlineEditErrorBoundary
				packageName={PACKAGE_NAME}
				fallback={fallback}
				onError={editableField.onError}
			>
				<UFOSegment name="ng-board.inline-edit.summary-field">
					<SummaryInner {...props} {...editableField} />
				</UFOSegment>
			</SummaryInlineEditErrorBoundary>
		);
	}

	return fallback;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	shouldMenuRender?: boolean;
	isSummaryEditing?: boolean;
	disableClick?: boolean;
}>(
	{
		// Should be same padding/margin right as
		// src/packages/platform/ui/card/src/common/ui/summary/styled.tsx

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginRight: ({ shouldMenuRender, isSummaryEditing }) =>
			shouldMenuRender && !isSummaryEditing ? token('space.400', '32px') : undefined,
		width: '100%',
		height: '100%',

		// Textarea for inline edit
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:has(> * > textarea)': {
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},

		// Confirm cancel buttons for inline edit
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:has(> * > button[type="submit"])': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},

		// warning/error inline-dialog
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`div:has(> [data-component-selector="${INLINE_EDIT_ERROR_CONTENT_SELECTOR}"])`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			position: 'absolute !important',
		},

		// Change top of summary to align with top of menu
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`div[data-component-selector="${INLINE_EDIT_CONTAINER_SELECTOR}"] form button+div`]: {
			paddingTop: token('space.025', '2px'),
			marginTop: token('space.negative.050', '-4px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);

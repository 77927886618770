import React, { useState, type ReactNode, useCallback, useMemo, memo } from 'react';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import {
	VISIBLE,
	HIDDEN,
} from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import type { CardType } from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardKanbanCreateIssueNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-create-issue/async.tsx';
import type { CardType as SoftwareCardType } from '../../../../model/software/software-types.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	allowedCardTypesForIccSelector,
	getIssueTypesForIssueCreation,
} from '../../../../state/selectors/card-create/card-create-selectors.tsx';
import { getColumnIndex } from '../../../../state/selectors/column/column-selectors.tsx';
import { getInlineCardCreateConfig } from '../../../../state/selectors/inline-create/inline-create-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../state/selectors/software/software-selectors.tsx';
import { getFirstOpenSwimlaneIdSelector } from '../../../../state/selectors/work/work-selectors.tsx';
import messages from './messages.tsx';
import { ICCPlaceholder, TooltipIconWrapper } from './styled.tsx';
import { Trigger } from './trigger/index.tsx';
import type { InlineCardCreateWithCardTypesProps } from './types.tsx';
import {
	shouldDisplayNudge,
	mapInlineCardCreateCardType,
	isPartialIccMode,
} from './utils/index.tsx';
import { VisibleIcc } from './visible-icc/index.tsx';

export const InlineCardCreateWithCardTypes = memo(
	({
		canShowIccNudge,
		// TODO-BOARD-REFACTOR-TYPES: this isn't a type error yet, but this is not set anywhere although it's marked as required
		// @ts-expect-error issueHierarchyLevel it was marked as required
		issueHierarchyLevel,
		triggerAppearance = VISIBLE,
		cardIndex,
		columnId,
		swimlaneId,
		groupParentId,
		isLastSwimlane,
		onChangeFormVisible,
		insertBefore,
		projectId,
		showOnHover,
		isFooter,
	}: InlineCardCreateWithCardTypesProps) => {
		const [showInlineCardCreate, setInlineCardCreateVisibility] = useState(false);
		const [isDismissed] = useIsDismissed();

		const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);

		const config = useBoardSelector(getInlineCardCreateConfig);

		const firstOpenSwimlaneId = useBoardSelector(getFirstOpenSwimlaneIdSelector);

		const columnIndex = useBoardSelector((state) => getColumnIndex(state, columnId));

		const isTheFirstCurrentSwimlaneOpen = swimlaneId === null || firstOpenSwimlaneId === swimlaneId;

		const intl = useIntl();

		const cardTypes = useBoardSelector((state) => {
			if (isIPBoard) {
				return getIssueTypesForIssueCreation(state)(projectId).map(
					(issueType: SoftwareCardType) => ({
						...issueType,
						isDisabled: false,
					}),
				);
			}

			return allowedCardTypesForIccSelector(state)(columnId, swimlaneId, !!groupParentId);
		});

		const mappedCardTypesOld = useMemo(
			() =>
				cardTypes.map((cardType) =>
					mapInlineCardCreateCardType({
						...cardType,
						hasRequiredFields: isPartialIccMode(columnId, cardType.id, config),
					}),
				),
			[cardTypes, columnId, config],
		);

		const mappedCardTypes = useMemo(
			() => cardTypes.map((cardType) => mapInlineCardCreateCardType(cardType)),
			[cardTypes],
		);

		const externalValues = getExternalValues(cardIndex, columnIndex);

		const renderAdditionalTypeSelectOptionContent = useCallback(
			(cardType: CardType) => {
				if (!cardType.isDisabled) {
					return null;
				}

				return (
					<Tooltip
						content={intl.formatMessage(messages.disabledIssueTypeTooltip)}
						position="bottom-start"
					>
						<TooltipIconWrapper>
							<InfoIcon primaryColor={token('color.text.discovery', colors.P300)} label="" />
						</TooltipIconWrapper>
					</Tooltip>
				);
			},
			[intl],
		);

		if (mappedCardTypes.length < 1) {
			return <ICCPlaceholder />;
		}

		const showHiddenCardForNudge = () => {
			setInlineCardCreateVisibility(true);
		};

		const hideHiddenCardForNudge = () => {
			setInlineCardCreateVisibility(false);
		};

		const canDisplayNudge = shouldDisplayNudge({
			isTheFirstCurrentSwimlaneOpen,
			triggerAppearance,
			canShowIccNudge,
			cardIndex: externalValues.cardIndex,
			isDismissed,
		});

		return (
			<ContextualAnalyticsData attributes={{ issueHierarchyLevel }}>
				<ConditionalNudgeWrapper
					Wrapper={BoardKanbanCreateIssueNudgeAsync}
					conditionsToApplyWrapper={canDisplayNudge}
					onShow={showHiddenCardForNudge}
					onHide={hideHiddenCardForNudge}
					attributes={{
						triggerAppearance,
					}}
				>
					{showInlineCardCreate && triggerAppearance === HIDDEN ? (
						<FormattedMessage
							id="platform-board.create-button-text.icon-label-v2"
							defaultMessage="Create issue"
						>
							{(formattedMessage: ReactNode[]) => (
								<Trigger
									onClick={hideHiddenCardForNudge}
									label={
										typeof formattedMessage[0] === 'string' ? formattedMessage[0] : 'Create issue'
									}
								/>
							)}
						</FormattedMessage>
					) : (
						<VisibleIcc
							renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
							cardIndex={cardIndex}
							cardTypes={cardTypes}
							// TODO-BOARD-REFACTOR-TYPES
							// @ts-expect-error CardType is not compatible with BoardCardType
							mappedCardTypes={
								fg('simplify_icc_summary_validation') ? mappedCardTypes : mappedCardTypesOld
							}
							columnId={columnId}
							groupParentId={groupParentId}
							swimlaneId={swimlaneId}
							triggerAppearance={triggerAppearance}
							isLastSwimlane={isLastSwimlane}
							insertBefore={insertBefore}
							onChangeFormVisible={onChangeFormVisible}
							showOnHover={showOnHover}
							projectId={projectId}
							isFooter={isFooter}
						/>
					)}
				</ConditionalNudgeWrapper>
			</ContextualAnalyticsData>
		);
	},
);

const getExternalValues = memoize(
	(cardIndex, columnIndex) => ({
		cardIndex: !isNil(cardIndex) ? cardIndex + 1 : null,
		columnIndex: !isNil(columnIndex) ? columnIndex + 1 : null,
	}),
	(cardIndex, columnIndex) => `${cardIndex}.${columnIndex}`,
);

export default AnalyticsSubject('issue')(InlineCardCreateWithCardTypes);

import React from 'react';
import { useJiraServiceManagementProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { useProjectContext } from '../project-context/index.tsx';

export const BOARD_VIEW_TYPE = 'board';
export const CALENDAR_VIEW_TYPE = 'calendar';

export const useViewsRedirect = ({
	viewType,
}: {
	viewType: typeof BOARD_VIEW_TYPE | typeof CALENDAR_VIEW_TYPE;
}) => {
	const { data: navData } = useJiraServiceManagementProjectNavigation();
	const projectContext = useProjectContext();

	if (
		projectContext == null ||
		navData == null ||
		(viewType === BOARD_VIEW_TYPE && !!navData.boardInfo?.enabled) ||
		(viewType === CALENDAR_VIEW_TYPE && !!navData.calendarInfo?.enabled)
	) {
		return null;
	}

	const projectKey = projectContext.project.key;
	return <ScreenRedirect to={`/jira/servicedesk/projects/${projectKey}`} />;
};

import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import DropdownMenu from '@atlaskit/dropdown-menu';
import AppIcon from '@atlaskit/icon/core/app';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { BoardToolSection } from '../../../types.tsx';
import { BoardToolSubItem } from './board-tool-sub-item/index.tsx';

interface Props {
	section: BoardToolSection;
	isMenuItem?: boolean;
}

export function BoardToolSectionView({ section, isMenuItem = false }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	if (getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4')) {
		const content = () =>
			section.subItems?.map((subItem) => <BoardToolSubItem key={subItem.id} subItem={subItem} />);

		return isMenuItem ? (
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="left-start"
				trigger={(triggerProps) => (
					<Tooltip content={section.label}>
						<ButtonItem
							{...triggerProps}
							iconBefore={<AppIcon label={section.label} color={token('color.icon')} />}
							iconAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
							onClick={() => setIsOpen(!isOpen)}
							testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.popup-menu-trigger"
						>
							{section.label}
						</ButtonItem>
					</Tooltip>
				)}
				content={() => (
					<MenuGroup maxWidth={240} onClick={(e) => e.stopPropagation()}>
						<Section>{content()}</Section>
					</MenuGroup>
				)}
			/>
		) : (
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-end"
				trigger={(triggerProps) => (
					<Tooltip content={section.label}>
						<Button
							{...triggerProps}
							isSelected={isOpen}
							iconAfter={ChevronDownIcon}
							onClick={() => setIsOpen(!isOpen)}
							testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.popup-menu-trigger"
						>
							{section.label}
						</Button>
					</Tooltip>
				)}
				content={content}
			/>
		);
	}

	return (
		<DropdownMenu
			placement="bottom-end"
			trigger={section.label}
			testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.dropdown-menu"
		>
			{section.subItems?.map((subItem) => <BoardToolSubItem key={subItem.id} subItem={subItem} />)}
		</DropdownMenu>
	);
}

import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	padding: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	display: 'flex',
	boxSizing: 'border-box',
	height: '1px',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.icon.danger', colors.N40)}`,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<Container>
		<Line />
	</Container>
);

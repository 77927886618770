/** @jsx jsx */
import React, { useCallback, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import SprintIcon from '@atlaskit/icon/core/sprint';
import SprintIconOld from '@atlaskit/icon/glyph/sprint';
import Link from '@atlaskit/link';
import { Text } from '@atlaskit/primitives';
import { N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelButton from '@atlassian/jira-help-panel-button/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	DOC_LINKS,
	IPH_IDS,
	BOARD_ISSUE_SOURCE,
	KANBAN_TEAM,
	SCRUM_TEAM,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { isScenarioPlanonlyTeamCheck } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { openTeamAssociationModal } from '../../../../../../state/actions/sprints/sprint-association-modal/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import { getTeamById } from '../../../../../../state/selectors/team/team-selectors.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const SprintAssociationButton = ({ teamId }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const dispatch = useBoardDispatch();
	const team = useBoardSelector(getTeamById)(teamId);

	const isAssociated =
		team?.sprintByIterationId && Object.keys(team?.sprintByIterationId).length > 0;

	const onClickHandler = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			// stop the click event bubble to prevent triggering the collapse of the swimlane header
			e.stopPropagation();
			dispatch(openTeamAssociationModal(teamId));

			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'syncSprints',
				{
					mode: isAssociated ? 'change' : 'add',
				},
			);
		},
		[createAnalyticsEvent, isAssociated, dispatch, teamId],
	);

	const onEnterHandler = useCallback(
		(e: React.KeyboardEvent) => {
			// stop the key enter event bubble to prevent triggering the collapse of the swimlane header
			if (e.key === 'Enter') {
				onClickHandler(e);
			}
		},
		[onClickHandler],
	);

	/**
	 * Not showing for
	 * -- Unassigned swimlane
	 * -- External teams (means outside of the plan)
	 * -- Kanban teams
	 * -- Undefined team - happens after a scenario team is committed to Jira
	 */
	if (
		isEmpty(teamId) ||
		teamId === SWIMLANE_TEAMLESS ||
		isEmpty(team) ||
		!team.isPlanTeam ||
		team.planningStyle === KANBAN_TEAM
	) {
		return null;
	}

	// check if the team is a scenario added plan-only team, we will revisit the logic for the sceanrio TWP team in https://hello.jira.atlassian.cloud/browse/JPO-28308
	if (isScenarioPlanonlyTeamCheck(teamId)) {
		return (
			<Text as="p" color="color.text.subtlest">
				{formatMessage(messages.scenarioTeamWarning, {
					strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
				})}
			</Text>
		);
	}

	if (team.issueSourceType === null) {
		return (
			<Text as="p" color="color.text.subtlest">
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.noIssueSourceWarningIssueTermRefresh
						: messages.noIssueSourceWarning,
				)}
			</Text>
		);
	}

	if (team.planningStyle === SCRUM_TEAM && team.issueSourceType !== BOARD_ISSUE_SOURCE) {
		const helpOnClick = (e: React.MouseEvent) => {
			// stop the click event bubble to prevent triggering the collapse of the swimlane header
			e.stopPropagation();
		};
		const helpOnEnter = (e: React.KeyboardEvent) => {
			// stop the key enter event bubble to prevent triggering the collapse of the swimlane header
			if (e.key === 'Enter') {
				e.stopPropagation();
			}
		};
		return (
			<Text as="p" color="color.text.subtlest">
				{formatMessage(messages.nonBoardTeamWarning, {
					link: (chunks: ReactNode[]) => (
						<HelpPanelButton
							articleId={IPH_IDS.CAPACITY_PLANNING}
							appearance="link"
							spacing="none"
							fallbackComponent={
								<Link href={DOC_LINKS.CAPACITY_PLANNING} target="_blank">
									{chunks}
								</Link>
							}
							onClick={helpOnClick}
							onKeyDown={helpOnEnter}
						>
							{chunks}
						</HelpPanelButton>
					),
				})}
			</Text>
		);
	}

	return (
		<Button
			onClick={onClickHandler}
			onKeyDown={onEnterHandler}
			appearance="subtle"
			spacing="compact"
			iconBefore={
				fg('enghealth-12479-jsw-board-visual-refresh') ? (
					<SprintIcon
						LEGACY_fallbackIcon={SprintIconOld}
						LEGACY_size="small"
						label=""
						LEGACY_primaryColor={token('color.text.subtle', N700)}
						color={token('color.icon')}
					/>
				) : (
					<SprintIconOld size="small" label="" primaryColor={token('color.text.subtle', N700)} />
				)
			}
		>
			<span css={buttonStyles}>
				{formatMessage(isAssociated ? messages.changeSprints : messages.addSprints)}
			</span>
		</Button>
	);
};

export default SprintAssociationButton;

const buttonStyles = css({
	fontSize: token('space.150', '12px'),
	fontWeight: 500,
});

import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';
import { type CustomField, CardCustomField } from './card-custom-field/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CustomField };

type CardCustomFieldsProps = {
	fields: CustomField[] | null | undefined;
	isCMPBoard?: boolean | null;
	fixVersions?: number[];
};

export const CardCustomFields = ({ fields, isCMPBoard, fixVersions }: CardCustomFieldsProps) => {
	if (!fields || fields.length === 0) return null;

	return (
		<ErrorBoundary id="jira-platform-card-custom-field" packageName="jira-platform-card">
			<CustomCardFieldList
				data-testid="platform-card.common.ui.custom-fields.custom-card-field-list"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.customCardFieldList}
			>
				{fields.map((field) => (
					<CardCustomField
						field={field}
						key={field.id}
						isCMPBoard={isCMPBoard}
						fixVersions={fixVersions}
					/>
				))}
			</CustomCardFieldList>
		</ErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomCardFieldList = styled.div({
	width: '100%',
	maxWidth: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
});

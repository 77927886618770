import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	type CustomTriggerProps,
	DropdownItemGroup,
	type OnOpenChangeArgs,
	DropdownItem,
} from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useSeenStatus } from '@atlassian/jira-servicedesk-itsm-onboarding-common/src/controllers/seen-status/index.tsx';
import { ShortcutLabel } from '@atlassian/jira-shortcut-label/src/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint.tsx';
import ManageWorkflowItem from '@atlassian/jira-software-manage-workflow-menu-item/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Capability } from '../../../common/capability/index.tsx';
import { editSprintModalLoad } from '../../../services/utils/performance-analytics/index.tsx';
import {
	useCapability,
	useIsCMPBoard,
	useIsJSMBoard,
	useCanEditBoard,
} from '../../../state/state-hooks/capabilities/index.tsx';
import Operations from '../extension/index.tsx';
import CardMediaItem from './card-media-item/index.tsx';
import ConfigureBoardItem from './configure-board-item/index.tsx';
import ConfigureBoardMenuItem from './configure-board/index.tsx';
import BoardConnectItemsOld from './connect-items-old/index.tsx';
import { BoardConnectItems } from './connect-items/index.tsx';
import CreateBoardMenuItem from './create-board/index.tsx';
import { EditSprintMenuItem } from './edit-sprint-item/index.tsx';
import EditSprintModalEntryPoint from './edit-sprint-modal/index.tsx';
import ManageCustomFiltersItem from './manage-custom-filters-item/index.tsx';
import messages from './messages.tsx';
import { SPOTLIGHT_TARGET_ID } from './onboarding-spotlight/constants.tsx';
import { OnboardingSpotlight } from './onboarding-spotlight/view.tsx';
import ReleaseVersion from './release-version/index.tsx';

export type StateProps = {
	isCardMediaSwitchEnabled: boolean;
	isConfigureBoardEnabled: boolean;
	isDisabled: boolean;
	isEditSprintItemVisible: boolean;
	isManageCustomFiltersEnabled: boolean;
	issueTypeId: string;
	projectKey: string;
	boardId: string;
	sprintId?: number;
	isReleaseVersionVisible?: boolean;
	hasOperations?: boolean;
};

type OwnProps = {};

export type BoardMenuProps = OwnProps & StateProps;

const JSM_BOARD_MEATBALL_MENU_SEEN_STATUS_USER_PROPERTY_KEY = 'jsm-board-meatball-menu-seen-status';

const BoardMenu = ({
	isCardMediaSwitchEnabled,
	isConfigureBoardEnabled,
	isDisabled,
	isEditSprintItemVisible,
	isManageCustomFiltersEnabled,
	issueTypeId,
	projectKey,
	boardId,
	sprintId,
	isReleaseVersionVisible,
	hasOperations,
}: BoardMenuProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [shouldShowPulse, setShouldShowPulse] = useState(false);
	const [shouldShowSpotlight, setShouldShowSpotlight] = useState(false);
	const [hasSpotlightDisplayed, setHasSpotlightDisplayed] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isFullscreen, { setIsFullscreen }] = useIsFullscreen();

	const isJSMBoard = useIsJSMBoard();
	const canEditBoard = useCanEditBoard();

	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	const shouldRenderBoardMoreMenuItem = useCapability(Capability.RENDER_BOARD_MORE_MENU_ITEM);
	// move the Enter Fullscreen action in dropdown if Nav4 && EAP for Software projects only
	const shouldRenderFullscreenMenuItem = useCapability(
		Capability.RENDER_BOARD_FULLSCREEN_MENU_ITEM,
	);
	const shouldRenderBoardSettingsMenuItem = useCapability(
		Capability.RENDER_BOARD_SETTINGS_MENU_ITEM,
	);
	const shouldRenderCreateBoardMenuItem = useCapability(Capability.RENDER_CREATE_BOARD_MENU_ITEM);
	const shouldRenderEditSprintMenuItem = useCapability(Capability.RENDER_EDIT_SPRINT_MENU_ITEM);
	const shouldRenderManageWorkflowMenuItem = useCapability(
		Capability.RENDER_MANAGE_WORKFLOW_MENU_ITEM,
	);

	const isCMPBoard = useIsCMPBoard();
	const cloudId = useCloudId();
	const isAdmin = useIsAdmin();

	const [{ isSeen: hasSeenSpotlight }, { markAsSeen: markSpotlightAsSeen }] = useSeenStatus({
		userPropertyKey: JSM_BOARD_MEATBALL_MENU_SEEN_STATUS_USER_PROPERTY_KEY,
		isSeenValue: true,
		packageName: 'jiraBoard',
		teamName: 'jsd-shield',
	});

	useEffect(() => {
		if (
			// eslint-disable-next-line jira/ff/no-preconditioning
			fg('jsm_boards_promo_gate') &&
			isJSMBoard &&
			isAdmin &&
			!hasSeenSpotlight &&
			!hasSpotlightDisplayed
		) {
			setShouldShowPulse(!hasSeenSpotlight);
		}
	}, [hasSeenSpotlight, hasSpotlightDisplayed, isAdmin, isJSMBoard]);

	const onClick = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const onOpenChangeOld = useCallback(
		(attrs: OnOpenChangeArgs) => {
			setIsOpen(attrs.isOpen);
			fireUIAnalytics(
				createAnalyticsEvent({ actionSubject: 'dropdownMenu', action: 'toggled' }),
				'boardHeaderMenu',
			);
		},
		[setIsOpen, createAnalyticsEvent],
	);

	const onOpenChange = useCallback(
		(attrs: OnOpenChangeArgs) => {
			if (isJSMBoard && isAdmin && !hasSeenSpotlight) {
				setShouldShowSpotlight(true);
				setShouldShowPulse(false);
				fireUIAnalytics(
					createAnalyticsEvent({ actionSubject: 'button', action: 'clicked' }),
					'boardAdminOnboardingConfigPulse',
				);
			} else {
				setIsOpen(attrs.isOpen);
				fireUIAnalytics(
					createAnalyticsEvent({ actionSubject: 'dropdownMenu', action: 'toggled' }),
					'boardHeaderMenu',
				);
			}
		},
		[isJSMBoard, isAdmin, hasSeenSpotlight, createAnalyticsEvent],
	);

	const onClose = useCallback(() => {
		setIsPopupOpen(false);
	}, [setIsPopupOpen]);

	const onPopupClick = useCallback(() => {
		if (isJSMBoard && isAdmin && !hasSeenSpotlight) {
			setShouldShowSpotlight(true);
			setShouldShowPulse(false);
			fireUIAnalytics(
				createAnalyticsEvent({ actionSubject: 'button', action: 'clicked' }),
				'boardAdminOnboardingConfigPulse',
			);
		} else {
			fireUIAnalytics(
				createAnalyticsEvent({ actionSubject: 'popupMenu', action: 'toggled' }),
				'boardHeaderMenu',
			);
			setIsPopupOpen(!isPopupOpen);
		}
	}, [isJSMBoard, isAdmin, hasSeenSpotlight, setIsPopupOpen, createAnalyticsEvent, isPopupOpen]);

	const dropdownMenuTrigger = useCallback(
		({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
			if (isVisualRefreshEnabled() || isTabNavigation) {
				return (
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.iconButtonLabel)}
						ref={triggerRef}
					/>
				);
			}

			return (
				<Button
					{...triggerProps}
					iconBefore={
						<ShowMoreHorizontalIcon
							label="More actions"
							color={token('color.icon', '#44546F')}
							LEGACY_fallbackIcon={MoreIcon}
							LEGACY_primaryColor={token('color.icon', '#44546F')}
						/>
					}
					aria-label={formatMessage(messages.dropdownAriaLabel)}
					ref={triggerRef}
				/>
			);
		},
		[formatMessage, isTabNavigation],
	);

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			if (isVisualRefreshEnabled() || isTabNavigation) {
				return (
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.iconButtonLabel)}
						onClick={onPopupClick}
						testId="software-board.header.menu.icon-button"
						isSelected={isPopupOpen}
					/>
				);
			}
			return (
				<Button
					{...triggerProps}
					iconBefore={
						<ShowMoreHorizontalIcon
							label="More actions"
							color={token('color.icon', '#44546F')}
							LEGACY_fallbackIcon={MoreIcon}
							LEGACY_primaryColor={token('color.icon', '#44546F')}
						/>
					}
					aria-label={formatMessage(messages.dropdownAriaLabel)}
					onClick={onPopupClick}
					isSelected={isPopupOpen}
					testId="software-board.header.menu.button"
				/>
			);
		},
		[formatMessage, onPopupClick, isPopupOpen, isTabNavigation],
	);

	const boardAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	const toggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
		if (fg('make_board_menu_popup')) {
			onClose();
		}
	}, [isFullscreen, setIsFullscreen, onClose]);

	const shortcutsKeyMap: ActiveKeyMap = {
		z: {
			callback: toggleFullscreen,
		},
	};

	const entryPointParams = useMemo(
		() => ({
			boardId: boardAri,
			sprintId: createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'sprint',
				resourceId: String(sprintId),
			}),
			fetchSprints: true,
			isCompanyManaged: isCMPBoard,
		}),
		[boardAri, cloudId, sprintId, isCMPBoard],
	);

	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		editSprintModalEntryPoint,
		entryPointParams,
		editSprintModalLoad,
	);

	const editSprintModalTriggerRef = useEntryPointButtonTrigger(entryPointActions);

	const renderReleaseDropdownItem = useCallback(
		() => isReleaseVersionVisible && isTabNavigation && <ReleaseVersion rapidViewId={boardId} />,
		[boardId, isReleaseVersionVisible, isTabNavigation],
	);

	const popupContent = useCallback(() => {
		return (
			<MenuGroup maxWidth={isTabNavigation ? 240 : undefined}>
				<Section>
					{shouldRenderFullscreenMenuItem && (
						<ButtonItem
							iconAfter={<ShortcutLabel text="Z" />}
							onClick={toggleFullscreen}
							aria-keyshortcuts="Z"
						>
							{isFullscreen
								? formatMessage(messages.exitFullScreen)
								: formatMessage(messages.enterFullScreen)}
						</ButtonItem>
					)}
					{isEditSprintItemVisible && shouldRenderEditSprintMenuItem && (
						<EditSprintMenuItem ref={editSprintModalTriggerRef} onClick={onClose} />
					)}

					{shouldRenderManageWorkflowMenuItem && (
						<ManageWorkflowItem
							projectKey={projectKey}
							isDisabled={isDisabled}
							issueTypeId={issueTypeId}
						/>
					)}

					{!isCMPBoard && isManageCustomFiltersEnabled ? <ManageCustomFiltersItem /> : null}

					{!isCMPBoard && isCardMediaSwitchEnabled ? (
						<CardMediaItem isDisabled={isDisabled} onClick={onClose} />
					) : null}

					{!isCMPBoard && isConfigureBoardEnabled ? <ConfigureBoardItem /> : null}

					{shouldRenderBoardSettingsMenuItem && <ConfigureBoardMenuItem />}
					{isCMPBoard &&
						!isTabNavigation &&
						(!fg('move-connect-addons-board-backlog-nav4') ||
							fg('update_connect_apps_in_board_backlog')) && (
							<BoardConnectMenuItems
								boardId={boardId}
								projectKey={projectKey}
								location="board-tools-1"
								onClickWebItem={onClose}
								mode="work"
							/>
						)}

					{shouldRenderCreateBoardMenuItem && <CreateBoardMenuItem onClosePopup={onClose} />}

					{isCMPBoard &&
						!isTabNavigation &&
						(!fg('move-connect-addons-board-backlog-nav4') ||
							fg('update_connect_apps_in_board_backlog')) && (
							<>
								<BoardConnectMenuItems
									boardId={boardId}
									projectKey={projectKey}
									location="view-actions-work"
									mode="work"
									onClickWebItem={onClose}
								/>
								<BoardConnectMenuItems
									boardId={boardId}
									projectKey={projectKey}
									location="view-actions-print"
									onClickWebItem={onClose}
									mode="work"
								/>
							</>
						)}
					{renderReleaseDropdownItem()}
					{fg('jira_nav4_eap_drop_2') ? (
						<>
							{isCMPBoard && isTabNavigation && fg('move-connect-addons-board-backlog-nav4') && (
								<BoardConnectItems
									projectKey={projectKey}
									boardId={boardId}
									onClickWebItem={onClose}
								/>
							)}
							{!isCMPBoard &&
								isTabNavigation &&
								hasOperations &&
								fg('move-connect-addons-board-backlog-nav4') && (
									<Section hasSeparator>
										<Operations />
									</Section>
								)}
						</>
					) : (
						<>
							{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') && (
								<BoardConnectItemsOld
									projectKey={projectKey}
									boardId={boardId}
									onClickWebItem={onClose}
								/>
							)}
						</>
					)}
				</Section>
			</MenuGroup>
		);
	}, [
		boardId,
		editSprintModalTriggerRef,
		hasOperations,
		isCMPBoard,
		isCardMediaSwitchEnabled,
		isConfigureBoardEnabled,
		isDisabled,
		isEditSprintItemVisible,
		isManageCustomFiltersEnabled,
		isTabNavigation,
		issueTypeId,
		onClose,
		projectKey,
		renderReleaseDropdownItem,
		shouldRenderBoardSettingsMenuItem,
		shouldRenderCreateBoardMenuItem,
		shouldRenderEditSprintMenuItem,
		shouldRenderManageWorkflowMenuItem,
		shouldRenderFullscreenMenuItem,
		isFullscreen,
		toggleFullscreen,
		formatMessage,
	]);

	// hide the meatball menu button if user doesn't have edit board permission for JSM project
	if (isJSMBoard && !canEditBoard) {
		return null;
	}

	const dropdownItemGroupContent = (
		<DropdownItemGroup>
			{shouldRenderFullscreenMenuItem && (
				<DropdownItem
					elemAfter={<ShortcutLabel text="Z" />}
					onClick={toggleFullscreen}
					aria-keyshortcuts="Z"
				>
					{isFullscreen
						? formatMessage(messages.exitFullScreen)
						: formatMessage(messages.enterFullScreen)}
				</DropdownItem>
			)}
			{isEditSprintItemVisible && shouldRenderEditSprintMenuItem && (
				<EditSprintMenuItem ref={editSprintModalTriggerRef} onClick={onClick} />
			)}

			{shouldRenderManageWorkflowMenuItem && (
				<ManageWorkflowItem
					projectKey={projectKey}
					isDisabled={isDisabled}
					issueTypeId={issueTypeId}
				/>
			)}

			{!isCMPBoard && isManageCustomFiltersEnabled ? <ManageCustomFiltersItem /> : null}

			{!isCMPBoard && isCardMediaSwitchEnabled ? (
				<CardMediaItem isDisabled={isDisabled} onClick={onClick} />
			) : null}

			{!isCMPBoard && isConfigureBoardEnabled ? <ConfigureBoardItem /> : null}

			{shouldRenderBoardSettingsMenuItem && <ConfigureBoardMenuItem />}
			{isCMPBoard &&
				!isTabNavigation &&
				(!fg('move-connect-addons-board-backlog-nav4') ||
					fg('update_connect_apps_in_board_backlog')) && (
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="board-tools-1"
						onClickWebItem={onClick}
						mode="work"
					/>
				)}

			{shouldRenderCreateBoardMenuItem && <CreateBoardMenuItem />}

			{isCMPBoard &&
				!isTabNavigation &&
				(!fg('move-connect-addons-board-backlog-nav4') ||
					fg('update_connect_apps_in_board_backlog')) && (
					<>
						<BoardConnectMenuItems
							boardId={boardId}
							projectKey={projectKey}
							location="view-actions-work"
							mode="work"
						/>
						<BoardConnectMenuItems
							boardId={boardId}
							projectKey={projectKey}
							location="view-actions-print"
							onClickWebItem={onClick}
							mode="work"
						/>
					</>
				)}
			{renderReleaseDropdownItem()}
			{fg('jira_nav4_eap_drop_2') ? (
				<>
					{isCMPBoard && isTabNavigation && fg('move-connect-addons-board-backlog-nav4') && (
						<BoardConnectItems projectKey={projectKey} boardId={boardId} onClickWebItem={onClick} />
					)}
					{!isCMPBoard &&
						isTabNavigation &&
						hasOperations &&
						fg('move-connect-addons-board-backlog-nav4') && (
							<DropdownItemGroup hasSeparator>
								<Operations />
							</DropdownItemGroup>
						)}
				</>
			) : (
				<>
					{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') && (
						<BoardConnectItemsOld
							projectKey={projectKey}
							boardId={boardId}
							onClickWebItem={onClick}
						/>
					)}
				</>
			)}
		</DropdownItemGroup>
	);

	const onAcknowledgeSpotlight = () => {
		markSpotlightAsSeen();
		setHasSpotlightDisplayed(true);
		setShouldShowSpotlight(false);
		setIsOpen(true);
		setIsPopupOpen(true);
	};

	if (fg('make_board_menu_popup')) {
		return (
			<>
				{isEditSprintItemVisible && (
					<EditSprintModalEntryPoint
						entryPointReferenceSubject={entryPointReferenceSubject}
						entryPointActions={entryPointActions}
						onReady={stopMetric}
					/>
				)}
				{(shouldRenderFullscreenMenuItem || shouldRenderBoardMoreMenuItem) &&
					// eslint-disable-next-line jira/ff/no-preconditioning
					(fg('jsm_boards_promo_gate') && isJSMBoard && isAdmin ? (
						<SpotlightTarget name={SPOTLIGHT_TARGET_ID} key="add-form-button">
							<SpotlightPulse radius={3} pulse={shouldShowPulse}>
								<Popup
									isOpen={isPopupOpen}
									onClose={onClose}
									placement="bottom-end"
									content={popupContent}
									trigger={popupTrigger}
								/>
								{shouldRenderFullscreenMenuItem && <Shortcuts keyMap={shortcutsKeyMap} />}
							</SpotlightPulse>
							{shouldShowSpotlight && (
								<OnboardingSpotlight onAcknowledge={onAcknowledgeSpotlight} />
							)}
							{shouldShowPulse && (
								<ContextualAnalyticsData
									sourceType={SCREEN}
									sourceName="boardAdminOnboardingConfigPulse"
								>
									<FireScreenAnalytics attributes={{ name: 'boardAdminOnboardingConfigPulse' }} />
								</ContextualAnalyticsData>
							)}
						</SpotlightTarget>
					) : (
						<>
							<Popup
								isOpen={isPopupOpen}
								onClose={onClose}
								placement="bottom-end"
								content={popupContent}
								trigger={popupTrigger}
							/>
							{shouldRenderFullscreenMenuItem && <Shortcuts keyMap={shortcutsKeyMap} />}
						</>
					))}
			</>
		);
	}

	return (
		<>
			{isEditSprintItemVisible && (
				<EditSprintModalEntryPoint
					entryPointReferenceSubject={entryPointReferenceSubject}
					entryPointActions={entryPointActions}
					onReady={stopMetric}
				/>
			)}

			{(shouldRenderFullscreenMenuItem || shouldRenderBoardMoreMenuItem) &&
				// eslint-disable-next-line jira/ff/no-preconditioning
				(fg('jsm_boards_promo_gate') && isJSMBoard && isAdmin ? (
					<SpotlightTarget name={SPOTLIGHT_TARGET_ID} key="add-form-button">
						<SpotlightPulse radius={3} pulse={shouldShowPulse}>
							<DropdownMenu
								placement="bottom-end"
								isOpen={isOpen}
								testId="software-board.header.menu.dropdown-menu"
								onOpenChange={onOpenChange}
								trigger={dropdownMenuTrigger}
								shouldRenderToParent={isTabNavigation}
							>
								{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') ? (
									<Box xcss={dropdownMenuGroupStyles}>{dropdownItemGroupContent}</Box>
								) : (
									dropdownItemGroupContent
								)}
							</DropdownMenu>
							{shouldRenderFullscreenMenuItem && <Shortcuts keyMap={shortcutsKeyMap} />}
						</SpotlightPulse>
						{shouldShowSpotlight && <OnboardingSpotlight onAcknowledge={onAcknowledgeSpotlight} />}
						{shouldShowPulse && (
							<ContextualAnalyticsData
								sourceType={SCREEN}
								sourceName="boardAdminOnboardingConfigPulse"
							>
								<FireScreenAnalytics attributes={{ name: 'boardAdminOnboardingConfigPulse' }} />
							</ContextualAnalyticsData>
						)}
					</SpotlightTarget>
				) : (
					<>
						<DropdownMenu
							placement="bottom-end"
							isOpen={isOpen}
							testId="software-board.header.menu.dropdown-menu"
							onOpenChange={onOpenChangeOld}
							trigger={dropdownMenuTrigger}
							shouldRenderToParent={isTabNavigation}
						>
							{isTabNavigation && fg('move-connect-addons-board-backlog-nav4') ? (
								<Box xcss={dropdownMenuGroupStyles}>{dropdownItemGroupContent}</Box>
							) : (
								dropdownItemGroupContent
							)}
						</DropdownMenu>
						{shouldRenderFullscreenMenuItem && <Shortcuts keyMap={shortcutsKeyMap} />}
					</>
				))}
		</>
	);
};

const dropdownMenuGroupStyles = xcss({
	maxWidth: '240px',
});

export default BoardMenu;

import React, { Fragment } from 'react';
import '@atlaskit/css-reset';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { APP_NAMES } from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import ServiceDeskAppBase from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/app-base/index.tsx';
import {
	useViewsRedirect,
	BOARD_VIEW_TYPE,
} from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/views-redirect/index.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/index.tsx';
import { SuspenseTrackerFallback } from '@atlassian/jira-software-suspense-tracker/src/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { renderBoardApp } from '@atlassian/jira-spa-apps-next-gen-board/src/view/board/board-app/index.tsx';
import { Breadcrumbs } from '@atlassian/jira-spa-apps-software-breadcrumbs/src/ui/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useResource, useRouterActions, usePathParam } from '@atlassian/react-resource-router';

const ServicedeskBoardApp = () => {
	const [paramBoardId] = usePathParam('boardId');
	const boardId = Number(paramBoardId);
	const [paramProjectKey] = usePathParam('projectKey');
	const cmpBoardData = useResource<UIFBoardCachedDataResult | null>(uifBoardResource);
	const tenantContext = useTenantContext();
	const { key } = cmpBoardData.data?.result?.boardLocation ?? {};
	const projectKey = key ?? paramProjectKey ?? null;
	const history = useRouterActions();

	return (
		<>
			<Breadcrumbs />
			{renderBoardApp({
				rapidViewId: Number(boardId),
				projectKey: projectKey ?? '',
				projectType: SERVICE_DESK_PROJECT,
				context: { tenantContext },
				cmpBoardData,
				boardData: null,
				history,
				isCacheHit: false,
				isCMPBoard: true,
			})}
			<RedirectIfProjectArchived projectKey={projectKey} />
		</>
	);
};

export const ServicedeskBoardAppLayout = () => {
	const viewsRedirect = fg('jsm_views_of_work_phase_1')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useViewsRedirect({ viewType: BOARD_VIEW_TYPE })
		: null;

	if (viewsRedirect) {
		return viewsRedirect;
	}

	const ContextualAnalyticsDataView = fg('jsm_views_of_work_phase_1')
		? ContextualAnalyticsData
		: Fragment;

	return (
		<UFOSegment name="servicedesk-board">
			<Placeholder
				fallback={
					<>
						<NextGenBoardSkeleton />
						<SuspenseTrackerFallback />
					</>
				}
				name="service-desk-board"
			>
				<MarkProductStart />
				<ContextualAnalyticsDataView
					attributes={{
						...(fg('jsm_views_of_work_phase_1') && { isJsmMergedViews: true }),
					}}
				>
					<ServiceDeskAppBase appName={APP_NAMES.BOARD}>
						<ErrorBoundary id="spa-apps-servicedesk.board" packageName="spa-apps-servicedesk-board">
							<ServicedeskBoardApp />
						</ErrorBoundary>
					</ServiceDeskAppBase>
				</ContextualAnalyticsDataView>
			</Placeholder>
		</UFOSegment>
	);
};

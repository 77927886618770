import { expVal } from '@atlassian/jira-feature-experiments';
import type {
	MobileColumn,
	MobileIssue,
	MobileIssueTypes,
	MobilePriorities,
	MobileEpicIssue,
	MobileEstimation,
	MobileSprint,
} from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import { CUSTOM_FIELD_LABELS } from '../../../../../../common/constants.tsx';
import {
	type Issue,
	CUSTOM_ESTIMATE_FIELD,
	type Estimate,
} from '../../../../../../model/issue/issue-types.tsx';
import { messages } from './messages.tsx';

export interface TransformIssueParams {
	issue: MobileIssue;
	estimation: MobileEstimation;
	column: MobileColumn;
	epicsByKey: {
		[key: string]: MobileEpicIssue;
	};
	issueTypesById: MobileIssueTypes;
	prioritiesById: MobilePriorities;
	sprints?: MobileSprint[];
}

const ExtraFieldLabelDefaultEmptyValue = 'None';

export function getParentId(
	issue: MobileIssue,
	epicsByKey: {
		[key: string]: MobileEpicIssue;
	},
) {
	if (issue.epic) {
		const epicId = epicsByKey[issue.epic.key]?.id ?? null;
		return epicId === issue.id ? null : epicId;
	}

	return issue.subTaskParentId ?? null;
}

export function transformIssue({
	issue,
	estimation,
	column,
	epicsByKey,
	issueTypesById,
	prioritiesById,
	sprints,
}: TransformIssueParams): Issue {
	const labels: Array<string> = [...(issue.labels ?? [])];

	if (labels.length === 0) {
		const labelsExtraField = issue.fieldEntries?.find((field) => field.id === CUSTOM_FIELD_LABELS);

		if (labelsExtraField && labelsExtraField.renderer === 'text') {
			const fieldLabels = labelsExtraField.text?.split(/,\s*/g) ?? [];

			// If there is exactly one label and it is 'None' we ignore it.
			// This could be a problem if the Customer actually sets None but
			// right now the default value returned via the mobile endpoint is 'None'
			// if it should be empty..
			if (fieldLabels.length > 1 || fieldLabels[0] !== ExtraFieldLabelDefaultEmptyValue) {
				fieldLabels.forEach((label) => {
					labels.push(label);
				});
			}
		}
	}

	const sprintId = sprints?.find((sprint) => issue.sprintIds.includes(sprint.id))?.id;

	// All estimate types in CMP considered as CUSTOM_ESTIMATE_FIELD
	const estimate: Estimate | null =
		estimation != null && 'field' in estimation
			? {
					type: CUSTOM_ESTIMATE_FIELD,
					displayName: issue.trackingStatistic
						? messages.remainingTimeEstimate
						: estimation.field.displayName,
					fieldId: issue.trackingStatistic?.statFieldId ?? estimation.field.fieldId,
					value: issue.trackingStatistic?.statFieldValue.text ?? issue.estimation,
				}
			: null;

	const daysInColumnSourceMillis =
		(issue.cumulativeTimeInColumnMillis ?? 0) + (issue.currentTimeInColumnMillis ?? 0);

	const dueDate: string | null =
		issue.dueDate &&
		expVal('jsw_cmp_show_due_date_by_default_experiment', 'isDueDateFeatureEnabled', false)
			? issue.dueDate
			: null;

	return {
		id: issue.id,
		key: issue.key,
		summary: issue.summary,
		statusId: Number(issue.statusId),
		requestTypeId: issue.requestTypeId,
		columnId: column.id,
		projectId: Number(issue.projectId),
		parentId: getParentId(issue, epicsByKey),
		typeId: Number(issue.issueTypeId),
		typeName: issueTypesById[issue.issueTypeId].name,
		typeUrl: issueTypesById[issue.issueTypeId].iconUrl,
		assigneeAccountId: issue.assigneeAccountId,
		isFlagged: issue.flagged,
		priority: issue.priorityId
			? {
					name: prioritiesById[issue.priorityId]?.name,
					iconUrl: prioritiesById[issue.priorityId]?.iconUrl,
				}
			: null,
		daysInColumn:
			daysInColumnSourceMillis != null ? daysInColumnSourceMillis / (1000 * 60 * 60 * 24) : null,
		extraFields: issue.fieldEntries,
		cardColor: issue.cardColor,
		labels,
		estimate,
		isDone: issue.isResolved ?? false,
		sprintId: sprintId != null ? String(sprintId) : 'none',
		dueDate,
		// TODO: Missing children stats
		numCompleteChildren: 0,
		numTotalChildren: 0,

		// These are not a problem to be missing
		swimlaneId: undefined,
		devStatus: undefined,
		fixVersions: issue.fixVersions,
		isVisible: issue.isVisible ?? true, // If undefined default to true
	};
}

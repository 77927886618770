import type { ErrorInfo } from 'react';
import type { SlaExtraErrorAttributes, SlaViewMode } from '../../common/types.tsx';

export type HtmlFieldResponse = {
	fieldAsHtml: string;
	fieldCssClass: string;
};
export type JsonFieldResponse<T> = {
	fieldId: string;
	value: T;
	isEditable: boolean;
};

export type CycleTimeData = {
	friendly: string;
	epochMillis: number;
};

export type CycleTimeDataSimple = {
	millis: number;
	friendly: string;
};

type CycleData = {
	startTime: CycleTimeData;
	goalDuration: CycleTimeDataSimple;
	elapsedTime: CycleTimeDataSimple;
	remainingTime: CycleTimeDataSimple;
	remainingTimeUnits?: string;
	breached: boolean;
};

export type CompletedCycle = CycleData & {
	breachTime?: CycleTimeData;
	stopTime: CycleTimeData;
};

export type OngoingCycle = CycleData & {
	breachTime?: CycleTimeData;
	paused: boolean;
	withinCalendarHours: boolean;
};

export type Sla = {
	id: string;
	name: string;
	_links: {
		self: string;
	};
	completedCycles: CompletedCycle[];
	ongoingCycle?: OngoingCycle;
	slaDisplayFormat?: SLA_DISPLAY_FORMAT;
};

export type SlaFieldResponse = JsonFieldResponse<Sla | SlaError>;

export type JsonResponseField = SlaFieldResponse;

export type ResponseField = HtmlFieldResponse | JsonResponseField;

export type SLA_DISPLAY_FORMAT = 'OLD_SLA_FORMAT' | 'NEW_SLA_FORMAT';

export type SlaProps = {
	isBreached: boolean;
	timeRemainingFriendly: string;
	status: SlaStatus;
	timeRemainingInMinutes: number;
	breachTime: string | undefined;
	elapsedTime: string;
	goalDuration: string;
	withinCalendarHours: boolean | undefined;
	hoverContent?: string | undefined;
	slaDisplayFormat?: SLA_DISPLAY_FORMAT;
};

export type SlaError = {
	errorMessage: string;
	i18nErrorMessage: {
		i18nKey: string;
		parameters: string[];
	};
};

export type ExtraErrorAttributes = {
	readonly [attributeKey: string]: string | boolean | number | null;
};

export type AnalyticsPayloadSla = {
	error: Error;
	errorInfo: ErrorInfo;
};

export type Props = {
	viewMode: SlaViewMode;
	value?: SlaProps | SlaError;
	onError?: (location: string, error: Error, attributes: SlaExtraErrorAttributes) => void;
	isLastColumn?: boolean;
};

export type SlaResponse = SlaFieldResponse;
export type StoredFieldUpdater<I> = (newVal: Partial<I>, oldVal: I) => void;
export type GenericFieldDataTransformerInput<I> = {
	storedValue: I;
	storedFieldUpdater: StoredFieldUpdater<I>;
};

export const ONGOING = 'ONGOING' as const;
export const COMPLETED = 'COMPLETED' as const;
export const PAUSED = 'PAUSED' as const;
export type SlaStatus = typeof ONGOING | typeof COMPLETED | typeof PAUSED;

export const SLA_CUSTOM_FIELD_TYPE = 'com.atlassian.servicedesk:sd-sla-field' as const;

export type SlaDataSelectorProps = {
	fieldType: typeof SLA_CUSTOM_FIELD_TYPE;
	value?: SlaProps | SlaError;
};

export type FieldDataTransformers = {
	['com.atlassian.servicedesk:sd-sla-field']: (
		arg1: GenericFieldDataTransformerInput<SlaFieldResponse>,
	) => SlaDataSelectorProps;
};

export type TransformableFieldType = keyof FieldDataTransformers;

export type FieldDataTransformer<T extends TransformableFieldType> = FieldDataTransformers[T];

import { ff } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import { useIntl } from '@atlassian/jira-intl';
import { X2C_SERVICE_URL_PREFIX } from './constants/index.tsx';
import messages from './messages.tsx';

export const isSynthetic = () =>
	// Filter out synthetic tests using all known ways
	getMeta('ajs-is-synthetic') === 'true' || ff('jsw.pollinator.tenants');

export const createDownloadLogsUrl = ({
	cloudId,
	migrationId,
	fileName,
}: {
	cloudId: string;
	migrationId: string;
	fileName: string;
}) =>
	`${X2C_SERVICE_URL_PREFIX}/${cloudId}/import/${migrationId}/logs?${new URLSearchParams({
		fileName,
	}).toString()}`;

export const useErrorLogFileName = (projectName: string) => {
	const { formatMessage } = useIntl();
	return formatMessage(messages.errorLogFileName, {
		projectName: projectName.replace(/[&*()[\];'"?!() ]/g, '_'),
	});
};

import { useCallback, useRef } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import {
	CloneStatus,
	cloneStatusIsCancelled,
	cloneStatusIsComplete,
	cloneStatusIsFailed,
	cloneStatusIsInProgress,
} from '../constants.tsx';
import {
	cloneIssueExperienceDescription,
	type CloneIssueStage,
} from '../experience-description/index.tsx';
import messages from '../messages.tsx';
import { useCloneStatus } from './context.tsx';
import type {
	CloneApiPayload,
	CloneApiResponse,
	CloneTaskResult,
	GetIssueKeyResult,
	TaskApiResponse,
} from './types.tsx';

const getCloneUrl = (issueKey: IssueKey) => `/rest/internal/2/issue/${issueKey}/clone`;

const getCloneProgressUrl = (taskId: string) => `/rest/api/3/task/${taskId}`;

const getIssueKeyUrl = (issueId: string) => `/rest/api/3/issue/${issueId}?fields=key`;

const getYourWorkUrl = () => '/jira/your-work';

export const useCloneService = (issueKey: IssueKey, projectType: ProjectType | null) => {
	const cloneUrl = getCloneUrl(issueKey);
	const appEditions = useAppEditions();

	const [cloneFetchStatus, { setCloneFetchStatus }] = useCloneStatus();
	const { showFlag, dismissFlag } = useFlagsService();

	const flagId = useRef<string | undefined>();
	const progressTaskId = useRef('0');

	// For retries
	const payloadRef = useRef<CloneApiPayload>();
	const onTryAgainClicked = useRef<() => void>();

	const dismissFlagIfNeeded = useCallback(() => {
		if (flagId.current) {
			dismissFlag(flagId.current);
		}
	}, [dismissFlag]);

	const onFailure = useCallback(
		(
			stage: CloneIssueStage,
			errorMessage: string,
			errorStatusCode?: number,
			failedReason?: string,
		): void => {
			setCloneFetchStatus(CloneStatus.FAILED);
			dismissFlagIfNeeded();

			// Filter out all client errors (undefined or 0 status code) from our experience analytics.
			// For SLA purposes a clone failure where the API behaves as expected is still a success.
			if (errorStatusCode !== null && errorStatusCode !== undefined && errorStatusCode !== 0) {
				const successStatusCodes = [200, 401, 403, 404];
				const wasSuccessful = successStatusCodes.includes(errorStatusCode);

				const errorMessageIfFailed = wasSuccessful
					? undefined
					: `${errorMessage} (Status: ${errorStatusCode})`;

				sendExperienceAnalytics(
					cloneIssueExperienceDescription(
						wasSuccessful,
						stage,
						'clone-issue',
						projectType,
						appEditions,
						errorMessageIfFailed,
					),
				);
			}

			flagId.current = showFlag({
				type: 'error',
				isAutoDismiss: false,
				title: [messages.cloneFailedTitle, { issueKey }],
				description: failedReason
					? [messages.cloneFailedWorkflow, { failedReason }]
					: messages.cloneStartFailed,
				actions: [
					{
						onClick: onTryAgainClicked.current,
						content: messages.tryAgain,
					},
				],
			});
		},
		[setCloneFetchStatus, dismissFlagIfNeeded, projectType, showFlag, issueKey, appEditions],
	);

	const showVagueSuccessFlag = useCallback(() => {
		// Clone was successful but we somehow lost the result or don't have the cloned issue key
		flagId.current = showFlag({
			type: 'success',
			isAutoDismiss: true,
			title: messages.cloneSuccessTitle,
			description: [messages.cloneSuccessButLostResultDescription, { oldIssueKey: issueKey }],
			actions: [
				{
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					onClick: () => window.open(getYourWorkUrl(), '_blank'),
					content: messages.goToYourWork,
				},
			],
		});
	}, [issueKey, showFlag]);

	const onSuccess = useCallback(
		(result?: CloneTaskResult | string) => {
			dismissFlagIfNeeded();

			sendExperienceAnalytics(
				cloneIssueExperienceDescription(true, 'CLONE', 'clone-issue', projectType, appEditions),
			);

			if (!result || typeof result === 'string') {
				showVagueSuccessFlag();
				return;
			}

			fetch(getIssueKeyUrl(result.issueId), {
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					if (!response.ok) {
						throw new FetchError(response.status, response.statusText);
					}
					return response.json();
				})
				.then(({ key: clonedKey }: GetIssueKeyResult) => {
					const clonedIssueLink = `/browse/${clonedKey}`;
					flagId.current = showFlag({
						type: 'success',
						isAutoDismiss: false,
						title: messages.cloneSuccessTitle,
						description: [
							messages.cloneSuccessDescription,
							{ clonedIssueKey: clonedKey, oldIssueKey: issueKey },
						],
						actions: [
							{
								onClick: () => {
									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									window.open(clonedIssueLink, '_blank');
									dismissFlagIfNeeded();
								},
								content: messages.cloneSuccessLink,
							},
						],
					});
				})
				.catch(() => {
					showVagueSuccessFlag();
				});
		},
		[dismissFlagIfNeeded, issueKey, projectType, showFlag, showVagueSuccessFlag, appEditions],
	);

	const pollProgressAfterMs = useCallback(
		(delayMs: number) => {
			const progressUrl = getCloneProgressUrl(progressTaskId.current);

			setTimeout(() => {
				fetch(progressUrl, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						if (!response.ok) {
							throw new FetchError(response.status, response.statusText);
						}
						return response.json();
					})
					.then((json: TaskApiResponse) => {
						const { status, message, result } = json;
						setCloneFetchStatus(status);

						if (cloneStatusIsInProgress(status)) {
							pollProgressAfterMs(Math.min(delayMs + 2000, 10000));
						} else if (cloneStatusIsComplete(status)) {
							onSuccess(result);
						} else if (cloneStatusIsFailed(status) || cloneStatusIsCancelled(status)) {
							onFailure(
								'CLONE',
								`${status || 'NO_STATUS'}: ${message}`,
								200,
								typeof result === 'string' ? result : undefined,
							);
						}
					})
					.catch((error) => onFailure('FETCH', error.message, error.statusCode));
			}, delayMs);
		},
		[onFailure, onSuccess, setCloneFetchStatus],
	);

	const makeCloneRequest = useCallback(
		async (payload: CloneApiPayload) => {
			setCloneFetchStatus(CloneStatus.ENQUEUED);
			dismissFlagIfNeeded();

			// Saved for retries if needed
			payloadRef.current = payload;

			flagId.current = showFlag({
				type: 'info',
				isAutoDismiss: true,
				title: [messages.cloneStartTitle, { issueKey }],
				description: [messages.cloneStartDescription, { issueKey }],
			});

			fetch(cloneUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then((response) => {
					if (!response.ok) {
						throw new FetchError(response.status, response.statusText);
					}
					return response.json();
				})
				.then((json: CloneApiResponse) => {
					const { status, taskId } = json;

					// Failure on initiation is very unlikely
					if (cloneStatusIsFailed(status) || !taskId) {
						throw new Error(
							`Clone task (ID: ${taskId}) failed on initiation with status: ${status || 'null'}`,
						);
					}

					progressTaskId.current = taskId;

					pollProgressAfterMs(1000);
				})
				.catch((error) => onFailure('CLONE', error.message, error.statusCode));
		},
		[
			setCloneFetchStatus,
			dismissFlagIfNeeded,
			showFlag,
			issueKey,
			cloneUrl,
			pollProgressAfterMs,
			onFailure,
		],
	);

	onTryAgainClicked.current = useCallback(() => {
		const payload: CloneApiPayload = payloadRef.current || {};
		makeCloneRequest(payload);
	}, [makeCloneRequest]);

	return [cloneFetchStatus, makeCloneRequest] as const;
};

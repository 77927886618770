import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonLabel: {
		id: 'software-view-settings.common.button-label',
		defaultMessage: 'View settings',
		description: "A settings menu for a board's view settings",
	},
	panelSection: {
		id: 'software-view-settings.common.panel-section',
		defaultMessage: 'Panels',
		description: 'Section title for group of settings having different panels',
	},
	versionPanel: {
		id: 'software-view-settings.common.version-panel',
		defaultMessage: 'Version panel',
		description: 'A setting to toggle the visibility of the version panel',
	},
	epicPanel: {
		id: 'software-view-settings.common.epic-panel',
		defaultMessage: '{epicName} panel',
		description: 'A setting to toggle the visibility of the epic panel',
	},
	quickFilters: {
		id: 'software-view-settings.common.quick-filters',
		defaultMessage: 'Quick filters',
		description: 'A setting to toggle the visibility of the quick filters',
	},
	workSuggestions: {
		id: 'software-view-settings.common.work-suggestions',
		defaultMessage: 'Work suggestions',
		description: 'A setting to toggle the feature of work suggestions',
	},
	compactMode: {
		id: 'software-view-settings.common.compact-mode',
		defaultMessage: 'Compact mode',
		description: 'A setting to toggle to a more visually compact card layout',
	},
	cardDetails: {
		id: 'software-view-settings.common.card-details',
		defaultMessage: 'Card details',
		description: 'A group of settings to toggle the visibility of card details',
	},
	cardFields: {
		id: 'software-view-settings.common.card-fields',
		defaultMessage: 'Fields',
		description: 'A group of settings to toggle the visibility of card fields',
	},
	cardDetailVersion: {
		id: 'software-view-settings.common.card-detail-version',
		defaultMessage: 'Version',
		description: "A setting to toggle the visibility of a card's version",
	},
	cardDetailStatus: {
		id: 'software-view-settings.common.card-detail-status',
		defaultMessage: 'Status',
		description: "A setting to toggle the visibility of a card's status",
	},
	cardDetailIssueType: {
		id: 'software-view-settings.common.card-detail-issue-type',
		defaultMessage: 'Issue type',
		description: "A setting to toggle the visibility of a card's issue type",
	},
	cardDetailIssueKey: {
		id: 'software-view-settings.common.card-detail-issue-key',
		defaultMessage: 'Issue key',
		description: "A setting to toggle the visibility of a card's issue key",
	},
	cardDetailEstimate: {
		id: 'software-view-settings.common.card-detail-estimate',
		defaultMessage: 'Estimate',
		description: "A setting to toggle the visibility of a card's estimate",
	},
	cardDetailPriority: {
		id: 'software-view-settings.common.card-detail-priority',
		defaultMessage: 'Priority',
		description: "A setting to toggle the visibility of a card's priority",
	},
	cardDetailDevelopment: {
		id: 'software-view-settings.common.card-detail-development',
		defaultMessage: 'Development',
		description: "A setting to toggle the visibility of a card's dev status",
	},
	cardDetailAssignee: {
		id: 'software-view-settings.common.card-detail-assignee',
		defaultMessage: 'Assignee',
		description: "A setting to toggle the visibility of a card's assignee",
	},
	swimlanes: {
		id: 'software-view-settings.common.swimlanes',
		defaultMessage: 'Swimlanes',
		description: 'A group of settings to interact with swimlanes',
	},
	expandAll: {
		id: 'software-view-settings.common.expand-all',
		defaultMessage: 'Expand all',
		description: 'A dropdown option to expand all swimlanes',
	},
	collapseAll: {
		id: 'software-view-settings.common.collapse-all',
		defaultMessage: 'Collapse all',
		description: 'A dropdown option to collapse all swimlanes',
	},
	openIssuesInSidebar: {
		id: 'software-view-settings.common.open-issues-in-sidebar',
		defaultMessage: 'Open issues in sidebar',
		description: 'A dropdown option to feature to open issues in a sidebar menu',
	},
	subtasks: {
		id: 'software-view-settings.common.subtasks',
		defaultMessage: 'Subtasks',
		description: 'A group of settings to toggle the visibility of subtasks',
	},
	expandAllSubtasks: {
		id: 'software-view-settings.common.expand-all-subtasks',
		defaultMessage: 'Expand all',
		description: 'A dropdown option to expand all subtasks',
	},
	collapseAllSubtasks: {
		id: 'software-view-settings.common.collapse-all-subtasks',
		defaultMessage: 'Collapse all',
		description: 'A dropdown option to collapse all subtasks',
	},
	showUnscheduledWork: {
		id: 'software-view-settings.common.show-unscheduled-work',
		defaultMessage: 'Show unscheduled work',
		description: 'A setting to show the unscheduled work in increment planning board',
	},
	cardLayout: {
		id: 'software-view-settings.common.card-layout',
		defaultMessage: 'Card layout',
		description: 'A radio group to select card layout',
	},
	cardDensity: {
		id: 'software-view-settings.common.card-density',
		defaultMessage: 'Density',
		description: 'A radio group to select density of cards',
	},
	daysInColumn: {
		id: 'software-view-settings.common.days-in-column',
		defaultMessage: 'Days in column',
		description: 'A setting to toggle the visibility of days in column field',
	},
	dueDate: {
		id: 'software-view-settings.common.due-date',
		defaultMessage: 'Due date',
		description: 'A setting to toggle the visibility of due date field',
	},
	labels: {
		id: 'software-view-settings.common.labels',
		defaultMessage: 'Labels',
		description: 'A setting to toggle the visibility of labels field',
	},
	linkedIssues: {
		id: 'software-view-settings.common.linked-issues',
		defaultMessage: 'Linked issues',
		description: 'A setting to toggle the visibility of linked issues on cards',
	},
	cardCovers: {
		id: 'software-view-settings.common.card-covers',
		defaultMessage: 'Cover images',
		description: 'A setting to toggle the visibility of card cover images on cards',
	},
	toggleHint: {
		id: 'software-view-settings.common.toggle-hint',
		defaultMessage: 'Press {key} to open and close {panel}',
		description: 'Hint message about keyboard shortcut to toggle (show / hide) the panel.',
	},
	beta: {
		id: 'software-view-settings.common.beta',
		defaultMessage: 'Beta',
		description: 'A label to indicate that a feature is in beta',
	},
	maxDependenciesError: {
		id: 'software-view-settings.common.max-dependencies-error',
		defaultMessage:
			'You can only show dependencies for {maxDeps, plural, one {# issue} other {# issues}} at one time',
		description: 'Error message to show when maximum number of dependencies is reached',
	},
	maxDependenciesErrorIssueTermRefresh: {
		id: 'software-view-settings.common.max-dependencies-error-issue-term-refresh',
		defaultMessage:
			'You can only show dependencies for {maxDeps, plural, one {# issue} other {# issues}} at one time',
		description: 'Error message to show when maximum number of dependencies is reached',
	},
	cardDetailIssueTypeIssueTermRefresh: {
		id: 'software-view-settings.common.card-detail-issue-type-issue-term-refresh',
		defaultMessage: 'Work type',
		description: "A setting to toggle the visibility of a card's issue type",
	},
	cardDetailIssueKeyIssueTermRefresh: {
		id: 'software-view-settings.common.card-detail-issue-key-issue-term-refresh',
		defaultMessage: 'Work item key',
		description: "A setting to toggle the visibility of a card's issue key",
	},
	openIssuesInSidebarIssueTermRefresh: {
		id: 'software-view-settings.common.open-issues-in-sidebar-issue-term-refresh',
		defaultMessage: 'Open work items in sidebar',
		description: 'A dropdown option to feature to open issues in a sidebar menu',
	},
	linkedIssuesIssueTermRefresh: {
		id: 'software-view-settings.common.linked-issues-issue-term-refresh',
		defaultMessage: 'Linked work',
		description: 'A setting to toggle the visibility of linked issues on cards',
	},
});

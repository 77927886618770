import React from 'react';
import CreateRuleIcon from '@atlaskit/icon/glyph/add';
import type {
	ManualRule,
	ManualRuleExecutor,
	TemplateRecommendation,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalyticsDeferred,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { ConditionalRecommendation } from '../../../../../common/ui/conditional-recommendation/index.tsx';
import MenuLink from '../../../../../common/ui/menu-link/main.tsx';
import {
	useCanManageAutomations,
	useBaseAutomationUrl,
} from '../../../../../controllers/menu-context/index.tsx';
import EmptyState from './empty-state/index.tsx';
import messages from './messages.tsx';
import Rule from './rule/index.tsx';
import { AddIcon, RulesContainer } from './styled.tsx';

export type RuleListProps = {
	rules: ManualRule[];
	executingRuleId: number | null;
	executeRule: ManualRuleExecutor;
	setClosed: () => void;
	selectedIssueIds?: number[];
	recommendations?: TemplateRecommendation[];
};

const RuleList = (props: RuleListProps) => {
	const { formatMessage } = useIntl();
	const { rules, executingRuleId, executeRule, setClosed, selectedIssueIds, recommendations } =
		props;
	const [canManageAutomations] = useCanManageAutomations();
	const [baseAutomationUrl] = useBaseAutomationUrl();

	if (rules.length === 0) {
		return (
			<ConditionalRecommendation
				canManageAutomations={canManageAutomations}
				baseAutomationUrl={baseAutomationUrl}
				recommendations={recommendations}
				fallback={<EmptyState />}
				setClosed={setClosed}
			/>
		);
	}

	return (
		<>
			<RulesContainer>
				<MountEvent
					onMount={(event) => fireOperationalAnalyticsDeferred(event, 'ruleList mounted')}
				/>
				{rules.map((rule: ManualRule) => (
					<Rule
						key={`manual-rule-${rule.id}`}
						rule={rule}
						isExecuting={rule.id === executingRuleId}
						executeRule={executeRule}
						selectedIssueIds={selectedIssueIds}
					/>
				))}
				{canManageAutomations && (
					<MenuLink
						itemKey="CREATE_MANUAL_RULE"
						href={`${baseAutomationUrl}#rule/new?componentFilterCriteria=QUICK_ACTIONS`}
						iconBefore={
							<AddIcon>
								<CreateRuleIcon size="small" label="" />
							</AddIcon>
						}
					>
						{formatMessage(messages.createManualRule)}
					</MenuLink>
				)}
			</RulesContainer>
			<ConditionalRecommendation
				canManageAutomations={canManageAutomations}
				baseAutomationUrl={baseAutomationUrl}
				recommendations={recommendations}
				setClosed={setClosed}
			/>
		</>
	);
};

export default RuleList;

import React from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const CardPriority = (props: Props) => {
	const { name, uri } = props;
	const { formatMessage } = useIntl();
	const priorityLevel = formatMessage(messages.priorityTooltip, { priorityLevel: name });

	return (
		<Icon>
			<Tooltip content={priorityLevel}>
				<Image alt={priorityLevel} src={uri} />
			</Tooltip>
		</Icon>
	);
};

export default CardPriority;

const iconSize = gridSize * 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled2.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageControl = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${iconSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${iconSize}px`,
	verticalAlign: 'text-bottom',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageExperiment = styled2.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${iconSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${iconSize}px`,
	verticalAlign: 'text-bottom',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Image = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	ImageExperiment,
	ImageControl,
);

import React from 'react';
import type { HighlightedTextPart } from '../common/utils/index.tsx';
import { Mark } from './styled.tsx';

export type Props = {
	parts: HighlightedTextPart[];
};

const HighlightText = ({ parts }: Props) => (
	<>
		{parts.map((part, i) => {
			if (part.type === 'text') {
				return part.content;
			}

			return <Mark key={i}>{part.content}</Mark>;
		})}
	</>
);

export default HighlightText;

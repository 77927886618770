import React from 'react';
import { styled } from '@compiled/react';
import { format, differenceInDays, endOfDay, startOfDay, parseISO } from 'date-fns';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import CardIcon from '../icon/main.tsx';
import { DUE_DATE_ICON } from '../icon/types.tsx';
import { messages } from './messages.tsx';
import type { Props, LozengeStyleProp } from './types.tsx';

export const CardDueDate = (props: Props) => {
	const { isCompleted, dueDate, dateFormat } = props;

	const dueDateEndOfDay = endOfDay(parseISO(dueDate));
	const currentDate = startOfDay(new Date());
	const diffInDays = differenceInDays(dueDateEndOfDay, currentDate);
	const isDueBeforeToday = diffInDays <= 0;
	const isOverDue = isCompleted !== true && isDueBeforeToday;
	const { formatMessage } = useIntl();

	const formattedDueDate =
		dateFormat !== undefined && dateFormat !== null
			? format(dueDateEndOfDay, dateFormat)
			: format(dueDateEndOfDay, 'dd MMM');

	const formattedTooltip = formatMessage(messages.text, {
		date: format(dueDateEndOfDay, 'dd MMMM yyyy'),
	});

	if (isVisualRefreshEnabled()) {
		return dueDate ? (
			<Box
				xcss={[baseStyles, isOverDue ? overdueStyles : normalStyles]}
				testId="platform-card.common.ui.due-date.box"
			>
				<Tooltip content={formattedTooltip} testId="platform-card.common.ui.due-date.tooltip">
					<Content
						aria-label={formattedTooltip}
						isVisualRefreshEAPDropEnabled={fg('jira_nav4_eap_drop_2')}
					>
						<CardIcon type={DUE_DATE_ICON} label={formattedTooltip} />
						{formattedDueDate}
					</Content>
				</Tooltip>
			</Box>
		) : null;
	}

	/* eslint-disable jira/react/no-style-attribute */
	return dueDate ? (
		<Lozenge
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={dueDateLozengeStyle(isOverDue)}
			testId="platform-card.common.ui.due-date.lozenge"
		>
			<Tooltip content={formattedTooltip} testId="platform-card.common.ui.due-date.tooltip">
				<Content aria-label={formattedTooltip}>
					<CardIcon type={DUE_DATE_ICON} label={formattedTooltip} />
					{formattedDueDate}
				</Content>
			</Tooltip>
		</Lozenge>
	) : null;
	/* eslint-enable jira/react/no-style-attribute */
};

CardDueDate.defaultProps = {
	isCompleted: false,
};

export default CardDueDate;

const baseStyles = xcss({
	borderStyle: 'solid',
	borderRadius: 'border.radius',
	borderWidth: 'border.width',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});

const overdueStyles = xcss({
	color: 'color.text.danger',
	borderColor: 'color.border.danger',
});

const normalStyles = xcss({
	color: 'color.text',
	borderColor: 'color.border',
});

const dueDateLozengeStyle = (isOverDue: boolean): LozengeStyleProp =>
	isOverDue
		? {
				color: token('color.text.accent.red', colors.R400),
				backgroundColor: token('color.background.danger', colors.R50),
			}
		: {
				color: token('color.text.subtle', colors.N500),
				backgroundColor: token('color.background.neutral', colors.N20),
			};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentOld = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentNew = styled.div<{ isVisualRefreshEAPDropEnabled?: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: token('space.050'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isVisualRefreshEAPDropEnabled }) =>
		isVisualRefreshEAPDropEnabled
			? {
					fontVariantNumeric: 'tabular-nums',
					minWidth: '72px',
				}
			: {},
);

const Content = componentWithCondition(isVisualRefreshEnabled, ContentNew, ContentOld);

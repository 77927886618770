import React, { useMemo } from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import isMobileUserAgent from '@atlassian/jira-common-is-mobile-user-agent/src/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import type { ChangeTabType } from '@atlassian/jira-share-button/src/ui/types.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ShareToSlack } from './share-to-slack/index.tsx';
import SlackIcon from './share-to-slack/slack-icon.tsx';
import type { ShareIntegrations } from './types.tsx';

/**
 * Returns the active integrations with the share component, checking for the
 * appropriate eligibility. An example of integration is sharing an issue
 * to Slack.
 */
export const useShareIntegrations = (
	shareContentType?: string,
): {
	shareIntegrations: ShareIntegrations;
} => {
	const { showFlag } = useFlagsService();
	const cloudId = useCloudId();
	const ineligibilityReasons: string[] = useMemo(() => {
		const reasons: string[] = [];

		if (isMobileUserAgent()) {
			reasons.push('notDesktop');
		}

		// All Share to Slack must be disabled in FedRAMP environment as
		// Slack Jira Integration app is not available in FedRAMP
		if (isFedRamp()) {
			reasons.push('isFedRAMP');
		}
		return reasons;
	}, []);

	const shareIntegrations = useMemo(
		() =>
			ineligibilityReasons.length === 0
				? [
						{
							type: 'Slack',
							Icon: SlackIcon,
							Content: ({
								onClose,
								changeTab,
							}: {
								onClose: () => void;
								changeTab: ChangeTabType;
							}) => (
								<ShareToSlack
									showFlag={showFlag}
									product="jira"
									channel="productAnalytics"
									onClose={onClose}
									cloudId={cloudId}
									changeTab={changeTab}
									shareContentType={shareContentType}
								/>
							),
						},
					]
				: [],
		[ineligibilityReasons.length, showFlag, cloudId, shareContentType],
	);

	return { shareIntegrations };
};

/** Popup with active sprint details
 * spec: https://www.figma.com/design/YnMz1EvyBCySecvWX4FvHC/%F0%9F%A7%AD-%5BSpork%5D-Jira-navigation-refresh---Components-%26-patterns?node-id=11291-21447&node-type=FRAME&t=76M0RECVACsXpZVQ-0
 */
import React, { type ReactNode } from 'react';
import { isValid as isValidDate } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { type TextProps, Box, Stack, Text, Grid, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	formatWithLocale,
	setNumberOffsetToISODate as parseISODateWithUtcOffset,
} from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import { DEFAULT_DATE_DISPLAY_FORMAT_SHORT } from '../../../../../model/constants.tsx';
import type { Sprint, SprintDate } from '../../../../../model/sprint/sprint-types.tsx';
import messages from './messages.tsx';

const MS_IN_HOURS = 60 * 60 * 1000;

export interface Props {
	selectedSprints: Array<Sprint>;
	activeSprintCount?: number;
}
const PopupContent = (props: Props) => {
	const { formatMessage } = useIntl();
	const { selectedSprints, activeSprintCount = selectedSprints.length } = props;

	if (!activeSprintCount && fg('jira_nav4_beta_drop_2')) {
		// by the spec, this should never happen
		// because the "sprint details" control only appear
		// when there are active sprints
		return null;
	}

	const stackElements: ReactNode[] = [];

	if (activeSprintCount > 1) {
		stackElements.push(
			<Text key="selectionIndicator" size="small" weight="bold" color="color.text.subtlest">
				{formatMessage(messages.sprintSelection, {
					selectedCount: selectedSprints.length,
					totalCount: activeSprintCount,
				})}
			</Text>,
		);
	}

	if (selectedSprints.length === 1) {
		stackElements.push(<SprintFullDetails key="singleSprint" sprint={selectedSprints[0]} />);
	} else {
		stackElements.push(
			...selectedSprints.map((sprint) => <SprintSummary key={sprint.id} sprint={sprint} />),
		);
	}

	return (
		<Box padding="space.200" xcss={containerStyles}>
			<Stack space="space.200">{stackElements}</Stack>
		</Box>
	);
};

export default PopupContent;

export function SprintFullDetails({ sprint }: { sprint: Sprint }) {
	return (
		<Box as="article">
			<Stack space="space.100">
				<TitleDetailsStack>
					<Text as="strong" maxLines={1}>
						{sprint.name}
					</Text>
					{sprint.goal && <Text maxLines={2}>{sprint.goal}</Text>}
				</TitleDetailsStack>
				<SprintRemainingDays sprint={sprint} />
				<SprintStartEndDate sprint={sprint} />
			</Stack>
		</Box>
	);
}

export function SprintSummary({ sprint }: { sprint: Sprint }) {
	return (
		<Box as="article">
			<Stack space="space.100">
				<TitleDetailsStack>
					<Text as="strong" maxLines={1}>
						{sprint.name}
					</Text>
					<SprintRemainingDays sprint={sprint} color="color.text.subtlest" />
				</TitleDetailsStack>
			</Stack>
		</Box>
	);
}

export function SprintRemainingDays({
	sprint,
	color = 'color.text',
}: {
	sprint: Sprint;
	color?: TextProps['color'];
}) {
	const { formatMessage } = useIntl();
	return (
		<>
			<Text color={color}>
				{formatMessage(messages.remainingDays, {
					remainingDays: sprint.daysRemaining,
				})}
			</Text>
		</>
	);
}

export function SprintStartEndDate({ sprint }: { sprint: Sprint }) {
	const { formatMessage, locale } = useIntl();
	const [{ data: loggedInUser }] = useUserSubscriber();

	// TODO BLU-4236 grid should be tighter
	return (
		<Grid templateColumns="1fr 1fr" gap="space.200">
			<TitleDetailsStack>
				<Text size="small" weight="bold" color="color.text.subtlest">
					{formatMessage(messages.startDate)}
				</Text>
				<Text>
					{formatSprintDate({
						sprintDate: sprint.startDate,
						currentTimezone: loggedInUser?.timeZone,
						locale,
						formatMessage,
					})}
				</Text>
			</TitleDetailsStack>
			<TitleDetailsStack>
				<Text size="small" weight="bold" color="color.text.subtlest">
					{formatMessage(messages.endDate)}
				</Text>
				<Text>
					{formatSprintDate({
						sprintDate: sprint.endDate,
						currentTimezone: loggedInUser?.timeZone,
						locale,
						formatMessage,
					})}
				</Text>
			</TitleDetailsStack>
		</Grid>
	);
}

const TitleDetailsStack = ({ children }: { children: ReactNode }) => {
	return <Stack>{children}</Stack>;
};

function formatSprintDate({
	sprintDate,
	currentTimezone,
	locale,
	formatMessage,
}: {
	sprintDate: SprintDate | null;
	currentTimezone?: Parameters<typeof getTimezoneOffset>[0];
	locale: Parameters<typeof formatWithLocale>[2];
	formatMessage: ReturnType<typeof useIntl>['formatMessage'];
}): string {
	if (!sprintDate) {
		// should never happen since we're dealing with active sprints
		// which get dates auto-assigned on start.
		// this test is for appeasing Typescript
		return '';
	}

	// we're doing fancy date manipulation above, not worth blowing up the whole screen, so let's be resilient
	try {
		const utcOffsetInHours: Parameters<typeof parseISODateWithUtcOffset>[1] = Math.floor(
			getTimezoneOffset(
				currentTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'utc',
			) / MS_IN_HOURS,
		);

		const dateObject: Date = parseISODateWithUtcOffset(sprintDate, utcOffsetInHours);
		if (!isValidDate(dateObject)) {
			// should never happen, but some tests use invalid dates such as "yesterday"
			if (typeof sprintDate === 'string') {
				return sprintDate; // better than nothing
			}

			return formatMessage(messages.invalidDate);
		}

		return formatWithLocale(dateObject, DEFAULT_DATE_DISPLAY_FORMAT_SHORT, locale);
	} catch {
		return formatMessage(messages.invalidDate);
	}
}

const containerStyles = xcss({
	width: '280px',
	maxBlockSize: '340px', // 5 sprints summary = no scroll, >5 = scroll
	overflowBlock: 'auto',
});

import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { JIRA_UPFLOW_IFRAME } from './common/constants.tsx';
import type { Props } from './common/types.tsx';
import { addChunkLoadErrorAnalyticsAttribute } from './common/utils.tsx';
import { ChunkLoadErrorFlag } from './ui/chunk-load-error-flag/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const UpFlowIFrameLazy = lazy(
	() => import(/* webpackChunkName: "async-up-flow-iframe" */ './main'),
);

export const UpFlowIFrame = (props: Props) => (
	<JSErrorBoundary
		id={JIRA_UPFLOW_IFRAME}
		packageName={JIRA_UPFLOW_IFRAME}
		teamName="luna-growth"
		fallback={ChunkLoadErrorFlag}
		attributes={addChunkLoadErrorAnalyticsAttribute}
	>
		<Placeholder name="flow-iframe-lazy" fallback={<></>}>
			<UpFlowIFrameLazy {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

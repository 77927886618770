/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export const isNewAutomationMenuEnabled = () =>
	ff('jsw-replace-automationmenu-with-new-context-aware-menu_rltpk');

export const isDevStatusOnCardsDisabled = () => ff('jsw.killswitch.dev-status-on-cards');

export const getBoardJirtJitter = () => getFeatureFlagValue('jsw.nextgen.board.jirt.jitter', 500);

export const isErrorTrackingEnabledWithSentry = () => ff('fe.platform.error.tracking.enabled');

export const isPrefetchIssueDataByColumnLimitEnabled = () =>
	ff('jsw-agile-board-prefetch-issue-data-by-column-limit');

export const isSyntheticTenant = () => ff('jsw.pollinator.tenants');

export const emitApdexOnAnimationFrame = () =>
	ff('jsw.perf.board-backlog-emit-apdex-on-animation-frame');

// eslint-disable-next-line jira/ff/inline-usage
export const isTailoredViewExperimentEnabled = () =>
	expValEquals('jsw_additional_card_fields_in_view_settings', 'cohort', 'variation');

// <tjoy>
// eslint-disable-next-line jira/ff/inline-usage
export function isInAddPeopleButtonOpenTMPControlCohort(): boolean {
	return expValEquals('open_invite_for_open_tmp_projects', 'cohort', 'control');
}
// </tjoy>

// eslint-disable-next-line jira/ff/inline-usage
export const isViewSettingAsPanelExpEnabledWithNoExposure = () =>
	fg('jira_view_settings_additional_fields');

import React, { Component } from 'react';
// Remove ThemeProvider on compiled.migration.jsw.tanuki FF cleanup
// eslint-disable-next-line jira/restricted/styled-components-migration, jira/restricted/styled-components, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { ThemeProvider } from 'styled-components';
import noop from 'lodash/noop';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { COLUMN_WRAPPER_FORM_SELECTOR_NAME } from '../../../../common/constants/index.tsx';
import { newColumn } from '../../../../common/mocks/column/index.tsx';
import {
	ColumnTheme,
	type Column,
	type ColumnId,
	type ValidateColumnCallback,
} from '../../../../common/types.tsx';
import ColumnEditableTitle from '../../../../common/ui/column-header/editable-title/main.tsx';
import ColumnHeaderDumb from '../../../../common/ui/column-header/header/index.tsx';
import { ColumnHeaderWrapper } from '../../../../common/ui/column-header/main.tsx';
import { ColumnWrapper, columnThemes } from '../../../../common/ui/column/index.tsx';

export type ColumnCreateCallback = (
	temporaryColumnId: ColumnId,
	name: string,
	analyticsEvent: AnalyticsEvent, // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

export type Props = {
	validate: ValidateColumnCallback;
	onSubmit: ColumnCreateCallback;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditStart?: () => any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCancel: (columnId: ColumnId, type: string) => any;
};

type State = {
	column: Column;
};

// eslint-disable-next-line jira/react/no-class-components
export default class CreateColumnForm extends Component<Props, State> {
	static defaultProps = {
		onSubmit: noop,
		onCancel: noop,
	};

	state = {
		column: newColumn(),
	};

	onColumnTitleEditChange = (isEditingTitle: boolean) => {
		if (isEditingTitle && this.props.onEditStart) {
			this.props.onEditStart();
		}
	};

	render = () => {
		const columnTitle = (
			<ColumnEditableTitle
				text=""
				columnId={this.state.column.id}
				isEditing
				onConfirm={this.props.onSubmit}
				onCancel={this.props.onCancel}
				onEditChange={this.onColumnTitleEditChange}
				// @ts-expect-error - Type 'ValidateColumnCallback' is not assignable to type '() => { isValid: boolean; message: null; }'.
				validateColumn={this.props.validate}
			/>
		);

		return (
			<ThemeProvider theme={columnThemes.default}>
				<ColumnWrapper
					data-testid={COLUMN_WRAPPER_FORM_SELECTOR_NAME}
					appearance={ColumnTheme.Default}
				>
					<ColumnHeaderWrapper>
						<ColumnHeaderDumb id={this.state.column.id} title={columnTitle} />
					</ColumnHeaderWrapper>
				</ColumnWrapper>
			</ThemeProvider>
		);
	};
}

import React from 'react';
import isNil from 'lodash/isNil';
import { ff } from '@atlassian/jira-feature-flagging';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { IssueProjectEntities } from '../../../model/software/software-types.tsx';
import { useBoardSelector } from '../../../state/index.tsx';
import { getIssueById } from '../../../state/selectors/issue/issue-selectors.tsx';
import {
	getIssueProjects,
	getIsIncrementPlanningBoard,
	getIncrementPlanningEstimateFieldId,
} from '../../../state/selectors/software/software-selectors.tsx';
import { getEstimationStatistic } from '../../../state/selectors/work/work-selectors.tsx';
import { FieldSyncAssignee } from './field-sync-assignee/index.tsx';
import { FieldSyncEstimate } from './field-sync-estimate/index.tsx';
import { FieldSyncParent } from './field-sync-parent/index.tsx';
import { FieldSyncSummary } from './field-sync-summary/index.tsx';

export type SyncSettings = {
	syncAssignee?: boolean;
	syncEstimate?: boolean;
	syncParent?: boolean;
	syncSummary?: boolean;
};

export type Props = SyncSettings & {
	issueId: IssueId;
};

export const FieldSync = ({
	issueId,
	syncAssignee = true,
	syncEstimate = true,
	syncParent = true,
	syncSummary = true,
}: Props) => {
	const issue = useBoardSelector((state) => getIssueById(state, issueId));
	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const estimationStatistic = useBoardSelector(getEstimationStatistic);
	const incrementPlanningEstimateFieldId = useBoardSelector(getIncrementPlanningEstimateFieldId);
	const estimateFieldId = isIPBoard ? incrementPlanningEstimateFieldId : estimationStatistic;

	const projects: IssueProjectEntities = useBoardSelector(getIssueProjects);
	const projectKey = issue && projects[issue.projectId]?.key;

	if (!issue) {
		return null;
	}

	return (
		<>
			{(!ff('relay-migration-issue-fields-summary_eog1v') || isIPBoard) && syncSummary && (
				<FieldSyncSummary issue={issue} projectKey={projectKey} />
			)}
			{syncParent && <FieldSyncParent issue={issue} projectKey={projectKey} />}
			{syncAssignee && <FieldSyncAssignee issue={issue} />}
			{syncEstimate && !isNil(estimateFieldId) && (
				<FieldSyncEstimate
					issue={issue}
					estimateFieldId={estimateFieldId}
					projectKey={projectKey}
				/>
			)}
		</>
	);
};

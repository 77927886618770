import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.title',
		defaultMessage: 'DEPENDENCIES',
		description: 'Title for the dependency flyout',
	},
	addDependency: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.add-dependency',
		defaultMessage: 'Add dependency',
		description: 'Text for the add dependency button',
	},
	viewAllDependencies: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.view-all-dependencies',
		defaultMessage: 'View all dependencies',
		description: 'Text for the view all dependencies button',
	},
	viewInDependenciesTab: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.view-in-dependencies-tab',
		defaultMessage: 'View in Dependencies tab',
		description: 'Text for the view dependencies detail button',
	},
	removeDependency: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.remove-dependency',
		defaultMessage: 'Remove dependency',
		description: 'Tooltip for the remove dependency button',
	},
	addDependencyKey: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.add-dependency-key',
		defaultMessage: 'Add dependency',
		description: 'Label for dependency flyout to add a dependency',
	},
	addDependencyCta: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.add-dependency-cta',
		defaultMessage: 'Add',
		description: 'Label for CTA to add a dependency',
	},
	externalIssueLinksDescription: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.external-issue-links-description',
		description: 'number of issue links external to the increment planning board',
		defaultMessage:
			'{externalIssueLinksCount} {externalIssueLinksCount, plural, one {dependency} other {dependencies}} not shown on this board',
	},
	externalIssueLinksTitle: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.external-issue-links-title',
		description: 'title for the external issue links section',
		defaultMessage: 'Not on this board',
	},
	showLinesTitle: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.show-lines-title',
		defaultMessage: 'Show lines',
		description: 'Title for the show dependencies lines toggle',
	},
	unlinkIssues: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.unlink-issues',
		defaultMessage: 'Unlink issues',
		description: 'Label for remove issue link button',
	},
	externalIssueLinksDescriptionIssueTermRefresh: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.external-issue-links-description-issue-term-refresh',
		description: 'number of issue links external to the increment planning board',
		defaultMessage:
			'{externalIssueLinksCount} {externalIssueLinksCount, plural, one {dependency} other {dependencies}} not shown on this board',
	},
	labelForShowLinesToggle: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.label-for-show-lines-toggle',
		defaultMessage: 'Changing this toggle will show or hide the dependency lines',
		description: 'Label for the show dependencies lines toggle',
	},
	tooltipForShowLinesToggle: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.tooltip-for-show-lines-toggle',
		defaultMessage: 'Lines only show once an issue has been scheduled.',
		description: 'Tooltip for the show dependencies lines toggle',
	},
	offtrackLabel: {
		id: 'software.main.board-container.board.card-container.card.issue-links-indicator.flyout.offtrack-label',
		defaultMessage: 'off-track',
		description: 'Label for off track issue links',
	},
});

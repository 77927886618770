import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	type IssueIncrementPlanningUpdateAction,
	ISSUE_INCREMENT_PLANNING_UPDATE,
	issueIncrementPlanningUpdateFailed,
	issueIncrementPlanningUpdateSuccess,
} from '../../../state/actions/issue/update/index.tsx';
import { getIsIncrementPlanningBoard } from '../../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, BoardDependencies, MiddlewareAPI } from '../../../state/types.tsx';

/**
 * this epic is used for inline updating the issue's parent, story point and summary in the IP board.
 * @param actions
 * @param store
 * @param customRequestHandlers
 * @returns
 */
export function issueIncrementPlanningUpdateEpic(
	actions: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return actions
		.ofType(ISSUE_INCREMENT_PLANNING_UPDATE)
		.mergeMap((action: IssueIncrementPlanningUpdateAction) => {
			const isIncrementPlanningBoard = getIsIncrementPlanningBoard(store.getState());
			if (isIncrementPlanningBoard) {
				const {
					payload: { issueId, fieldValue, fieldId },
				} = action;

				const success = () => Observable.of(issueIncrementPlanningUpdateSuccess());

				const error = (err?: Error) => {
					log.safeErrorWithoutCustomerData(
						'issue.increment.planning.update',
						'Failed to update IP board issue',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						err as Error,
					);
					return Observable.of(issueIncrementPlanningUpdateFailed());
				};

				if (!customRequestHandlers || !customRequestHandlers?.updateIssue) {
					return error(new Error('no request handler found for update issue'));
				}

				return Observable.from(
					customRequestHandlers.updateIssue({
						id: String(issueId),
						[fieldId]: fieldValue,
					}),
				)
					.flatMap(() => success())
					.catch((err) => error(err));
			}
			return Observable.empty<never>();
		});
}

import React from 'react';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	activeSprintsSelector,
	getSelectedOrAllSprintsSelector,
} from '../../../../state/selectors/sprint/sprint-selectors.tsx';
import SprintDetails from './view.tsx';

// TODO BLU-4236 async loading of the content
// TODO BLU-4236 aria dialog
// TODO BLU-4236 error boundary for content (portal)
export const SprintDetailsButton = () => {
	const selectedSprints = useBoardSelector(getSelectedOrAllSprintsSelector);
	const activeSprints = useBoardSelector(activeSprintsSelector);

	if (!activeSprints?.length) {
		// can happen because our parent uses "hasActiveSprints" selector
		// which doesn't check if the array is empty (as of 2024/09)
		return null;
	}

	return <SprintDetails activeSprints={activeSprints} selectedSprints={selectedSprints} />;
};

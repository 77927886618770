import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { CORE_INVITES_IN_JIRA_PROJECTS } from '../index.tsx';

const getCoreInvitesInJiraProjects = (): CORE_INVITES_IN_JIRA_PROJECTS =>
	getMultivariateFeatureFlag(
		'core-invites-in-jira-projects',
		CORE_INVITES_IN_JIRA_PROJECTS.NOT_ENROLLED,
		[
			CORE_INVITES_IN_JIRA_PROJECTS.NOT_ENROLLED,
			CORE_INVITES_IN_JIRA_PROJECTS.CONTROL,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_ONLY_NOT_ENROLLED,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA,
			CORE_INVITES_IN_JIRA_PROJECTS.INVITE_RA_NOT_ENROLLED,
		],
	);

export const fireCoreInvitesInJiraProjectFeatureExposed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	isSiteAdmin: boolean,
) => {
	const featureFlagValue = getCoreInvitesInJiraProjects();
	const cohort = featureFlagValue;

	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
		flagKey: 'core-invites-in-jira-projects',
		value: featureFlagValue,
		cohort,
		isSiteAdmin,
		ineligibilityReasons: [],
		growthInitiative: 'virality',
	});
};

export const fireCoreInvitesInJsmProjectFeatureExposed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	coreInvitesJsmExpValue: string,
) => {
	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
		flagKey: 'jsm_core_invites_experiment',
		cohort: coreInvitesJsmExpValue,
	});
};

import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useFeatures } from '@atlassian/jira-navigation-apps-sidebar-next-gen-use-features/src/controllers/features/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';

export const useIsOneScreenOnboardingEnabled = () => {
	const isJiraAdmin = useIsAdmin();
	const projectContext = useProjectContext();
	const isProjectAdmin = projectContext?.data?.isProjectAdmin;

	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jira_one_screen_onboarding');
	const isVariation = expConfig.get('isEnabled', false);

	return (isJiraAdmin || isProjectAdmin) && isVariation;
};

export const useIsOneScreenOnboardingEnabledWithFeaturesCheck = () => {
	const { data: features } = useFeatures();

	const isOneScreenOnboardingEnabled = useIsOneScreenOnboardingEnabled();

	return features?.hasGetStarted && isOneScreenOnboardingEnabled;
};

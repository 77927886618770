import { softwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-board-routes/src/index.tsx';
import { classicSoftwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

export const useIsEmbedView = () => {
	const [{ route }] = useRouter();

	return (
		route.name === softwareBoardEmbedRoute.name ||
		route.name === classicSoftwareBoardEmbedRoute.name
	);
};

import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useCardMediaHeightById } from '../../../../../../../state/sweet-state/card-media/selectors/index.tsx';

type Props = {
	mediaId: string;
};

const Placeholder = ({ mediaId }: Props) => {
	const [height] = useCardMediaHeightById({ mediaId });

	if (height === null) {
		return null;
	}

	// eslint-disable-next-line jira/react/no-style-attribute
	return <PlaceholderContainer style={{ height: `${height}px` }} />;
};

export default Placeholder;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface.sunken', colors.N10),
	borderRadius: '2px 2px 0 0',
});

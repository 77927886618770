import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { LazyShareToSlack } from './async.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledShareToSlack = styled(LazyShareToSlack)({
	margin: `${token('space.negative.200', '-16px')} ${token('space.negative.300', '-24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledShareToSlackLoader = styled(Spinner)({
	width: '100%',
	margin: '0 auto',
});

import React, { useMemo } from 'react';
import ProgramBoardCustomFieldError from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/error-screens/custom-field/index.tsx';
import ProgramBoard404 from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/error-screens/empty-404/index.tsx';
import { ProgramBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/program-board/index.tsx';
import { useBoardSelector } from '../../../state/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../state/selectors/board/board-permissions-selectors.tsx';
import { isIpBoardFailed } from '../../../state/selectors/board/board-selectors.tsx';
import {
	getBoardScopeFilter,
	getPlanId,
} from '../../../state/selectors/software/software-selectors.tsx';
import { isBoardConfigLoaded } from '../../../state/selectors/work/work-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../state/state-hooks/capabilities/index.tsx';

export const useProgramBoardEmptyStates = (height: string) => {
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const isIncrementPlanning404 = useBoardSelector(isIpBoardFailed);
	const planId = useBoardSelector(getPlanId);
	const boardScopeFilter = useBoardSelector(getBoardScopeFilter);
	const isReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	// This change is added because on this branch there's no router resource
	const isLoaded = useBoardSelector(isBoardConfigLoaded);

	return useMemo(() => {
		if (!isIncrementPlanningBoard) {
			return null;
		}
		if (isIncrementPlanning404) {
			return <ProgramBoard404 />;
		}

		/**
		 * In program boards of Plans, we always use the JSW board header in compact mode,
		 * therefore we use a different board skeleton which does NOT include placeholders
		 * for page breadcrumbs, page title and board top header actions.
		 */
		if (!isLoaded) {
			return <ProgramBoardSkeleton />;
		}

		// Show error screen if custom field not set correctly in program board
		if (boardScopeFilter && boardScopeFilter.filterFieldError) {
			return (
				<ProgramBoardCustomFieldError
					height={height}
					planId={planId}
					boardScopeFilter={boardScopeFilter}
					isReadOnly={isReadOnly}
				/>
			);
		}

		return null;
	}, [
		isIncrementPlanningBoard,
		isIncrementPlanning404,
		isLoaded,
		boardScopeFilter,
		planId,
		isReadOnly,
		height,
	]);
};

import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	CommentVisibility,
	NormalizedLoadedComments,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type {
	Comment,
	CommentId,
} from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import { COMMENT } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { fetchIssueSuccess } from '../common/actions/issue-fetch-actions.tsx';
import { isJsmTimelineEnabled } from '../feature-flags.tsx';
import type { PreviewDataFetched } from '../issue-field/state/actions/field-preview-actions.tsx';

// ADD_COMMENT_FORM_EXPAND
export const ADD_COMMENT_FORM_EXPAND = 'ADD_COMMENT_FORM_EXPAND' as const;

export type AddCommentFormExpandAction = {
	type: typeof ADD_COMMENT_FORM_EXPAND;
};

export const addCommentFormExpand = (): AddCommentFormExpandAction => ({
	type: ADD_COMMENT_FORM_EXPAND,
});

export type AddCommentFormExpand = ReturnType<typeof addCommentFormExpand>;

// ADD_COMMENT_FORM_COLLAPSE
export const ADD_COMMENT_FORM_COLLAPSE = 'ADD_COMMENT_FORM_COLLAPSE' as const;

export type AddCommentFormCollapseAction = {
	type: typeof ADD_COMMENT_FORM_COLLAPSE;
};

export const addCommentFormCollapse = (): AddCommentFormCollapseAction => ({
	type: ADD_COMMENT_FORM_COLLAPSE,
});

export type AddCommentFormCollapse = ReturnType<typeof addCommentFormCollapse>;

// ADD_COMMENT_FORM_CANCEL
export const ADD_COMMENT_FORM_CANCEL = 'ADD_COMMENT_FORM_CANCEL' as const;

export type AddCommentFormCancelAction = {
	type: typeof ADD_COMMENT_FORM_CANCEL;
};

export const addCommentFormCancel = (): AddCommentFormCancelAction => ({
	type: ADD_COMMENT_FORM_CANCEL,
});

export type AddCommentFormCancel = ReturnType<typeof addCommentFormCancel>;

// SAVE_COMMENT_RETRY
export const SAVE_COMMENT_RETRY = 'SAVE_COMMENT_RETRY' as const;

export type SaveCommentRetryAction = {
	type: typeof SAVE_COMMENT_RETRY;
	payload: {
		optimisticId: CommentId;
		createdDate: string;
		isNewComment?: boolean;
	};
};

export const saveCommentRetry = (
	optimisticId: CommentId,
	createdDate: string,
	isNewComment: boolean,
): SaveCommentRetryAction => ({
	type: SAVE_COMMENT_RETRY,
	payload: { optimisticId, createdDate, isNewComment },
});

// SAVE_COMMENT_EDIT
export const SAVE_COMMENT_EDIT = 'SAVE_COMMENT_EDIT' as const;

export type SaveCommentEditAction = {
	type: typeof SAVE_COMMENT_EDIT;
	payload: {
		optimisticId: CommentId;
	};
};

export const saveCommentEdit = (optimisticId: CommentId): SaveCommentEditAction => ({
	type: SAVE_COMMENT_EDIT,
	payload: { optimisticId },
});

// SAVE_COMMENT_SUCCESS_WITH_PASTED_CONTENT
export const SAVE_COMMENT_SUCCESS_WITH_PASTED_CONTENT =
	'SAVE_COMMENT_SUCCESS_WITH_PASTED_CONTENT' as const;

export type SaveCommentSuccessWithPastedContentAction = {
	type: typeof SAVE_COMMENT_SUCCESS_WITH_PASTED_CONTENT;
	payload: {
		comment: Comment;
		pastedContent: string[];
	};
};

export const saveCommentSuccessWithPastedContent = (
	comment: Comment,
	pastedContent: string[],
): SaveCommentSuccessWithPastedContentAction => ({
	type: SAVE_COMMENT_SUCCESS_WITH_PASTED_CONTENT,
	payload: {
		comment,
		pastedContent,
	},
});

// SAVE_COMMENT_REQUEST
export const SAVE_COMMENT_REQUEST = 'SAVE_COMMENT_REQUEST' as const;

export type CommentSaveRequest = {
	id: CommentId;
	createdDate: string;
	authorId: AccountId | null;
	bodyAdf?: ADF;
	bodyHtml?: string;
	richContent?: ADF;
	isNewComment?: boolean;
	visibility: CommentVisibility;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	fullIssueUrl?: string;
	triggerIncidentSaveCommentFlag?: boolean;
	parentId?: CommentId;
};

export type SaveCommentRequestAction = {
	type: typeof SAVE_COMMENT_REQUEST;
	payload: {
		comment: CommentSaveRequest;
		updatedField: string;
		analyticsEvent: UIAnalyticsEvent;
		commentSessionId?: string | null;
	};
};

export const saveCommentRequest = (
	comment: CommentSaveRequest,
	analyticsEvent: UIAnalyticsEvent,
	commentSessionId?: string | null,
): SaveCommentRequestAction => ({
	type: SAVE_COMMENT_REQUEST,
	payload: {
		comment,
		analyticsEvent,
		commentSessionId,
		updatedField: COMMENT,
	},
});

export type SaveCommentRequest = ReturnType<typeof saveCommentRequest>;

// SAVE_COMMENT_SUCCESS
export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS' as const;

type SaveSuccessMeta = {
	shouldFetchViewContext: boolean;
};

export type SaveCommentPayload = {
	fieldId: string;
	comment: Comment;
	optimisticId: CommentId;
	isNewComment?: boolean;
	analyticsEvent: UIAnalyticsEvent;
	commentSessionId?: string | null;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	fullIssueUrl?: String;
	triggerIncidentSaveCommentFlag?: boolean;
	parentId?: string;
};
export type SaveCommentSuccessAction = {
	type: typeof SAVE_COMMENT_SUCCESS;
	payload: SaveCommentPayload;
	meta?: SaveSuccessMeta;
};

export const saveCommentSuccess = (
	comment: Comment,
	optimisticId: CommentId,
	isNewComment: boolean | undefined,
	analyticsEvent: UIAnalyticsEvent,
	commentSessionId?: string | null,
	meta?: SaveSuccessMeta,
	eventOccurredAt?: number | null,
	jsdIncidentActivityViewHidden?: boolean | null,
	fullIssueUrl?: string,
	triggerIncidentSaveCommentFlag?: boolean,
	parentId?: string,
): SaveCommentSuccessAction => ({
	type: SAVE_COMMENT_SUCCESS,
	payload: {
		fieldId: COMMENT,
		comment,
		optimisticId,
		isNewComment,
		analyticsEvent,
		commentSessionId,
		parentId,
		...(isJsmTimelineEnabled() ? { eventOccurredAt } : {}),
		...(isJsmTimelineEnabled() ? { jsdIncidentActivityViewHidden } : {}),
		...(isJsmTimelineEnabled() && fullIssueUrl ? { fullIssueUrl } : {}),
		...(isJsmTimelineEnabled() ? { triggerIncidentSaveCommentFlag } : {}),
	},
	meta,
});

// SAVE_COMMENT_FAILURE
export const SAVE_COMMENT_FAILURE = 'SAVE_COMMENT_FAILURE' as const;

export type CommentSaveFailure = {
	optimisticId: CommentId;
	isServerValidationError: boolean;
	invalidMessage?: string;
};

export type SaveCommentFailureActionType = {
	type: typeof SAVE_COMMENT_FAILURE;
	payload: CommentSaveFailure;
};

export const saveCommentFailure = (payload: CommentSaveFailure): SaveCommentFailureActionType => ({
	type: SAVE_COMMENT_FAILURE,
	payload,
});

export type SaveCommentFailureAction = ReturnType<typeof saveCommentFailure>;

// SAVE_COMMENT_CANCEL
export const SAVE_COMMENT_CANCEL = 'SAVE_COMMENT_CANCEL' as const;

export type SaveCommentCancelAction = {
	type: typeof SAVE_COMMENT_CANCEL;
	payload: {
		optimisticId: CommentId;
		isNewComment: boolean;
		parentId?: CommentId;
	};
};

export const saveCommentCancel = (
	optimisticId: CommentId,
	isNewComment: boolean,
	parentId?: CommentId,
): SaveCommentCancelAction => ({
	type: SAVE_COMMENT_CANCEL,
	payload: { optimisticId, isNewComment, parentId },
});

export const FETCH_OLDER_COMMENTS_REQUEST = 'FETCH_OLDER_COMMENTS_REQUEST' as const;
export const FETCH_NEWER_COMMENTS_REQUEST = 'FETCH_NEWER_COMMENTS_REQUEST' as const;

export type FetchOlderCommentsRequestAction = {
	type: typeof FETCH_OLDER_COMMENTS_REQUEST;
};

export type FetchNewerCommentsRequestAction = {
	type: typeof FETCH_NEWER_COMMENTS_REQUEST;
};

export const fetchOlderCommentsRequest = (): FetchOlderCommentsRequestAction => ({
	type: FETCH_OLDER_COMMENTS_REQUEST,
});

export type FetchOlderCommentsRequest = ReturnType<typeof fetchOlderCommentsRequest>;

export const fetchNewerCommentsRequest = (): FetchNewerCommentsRequestAction => ({
	type: FETCH_NEWER_COMMENTS_REQUEST,
});

export type FetchNewerCommentsRequest = ReturnType<typeof fetchNewerCommentsRequest>;

// FETCH_MORE_COMMENTS_SUCCESS
export const FETCH_MORE_COMMENTS_SUCCESS = 'FETCH_MORE_COMMENTS_SUCCESS' as const;

export type FetchMoreCommentsSuccessAction = {
	type: typeof FETCH_MORE_COMMENTS_SUCCESS;
	payload: NormalizedLoadedComments;
};

export const fetchMoreCommentsSuccess = (
	loadedComments: NormalizedLoadedComments,
): FetchMoreCommentsSuccessAction => ({
	type: FETCH_MORE_COMMENTS_SUCCESS,
	payload: loadedComments,
});

// FETCH_MORE_COMMENTS_FAILURE
export const FETCH_MORE_COMMENTS_FAILURE = 'FETCH_MORE_COMMENTS_FAILURE' as const;

export type FetchMoreCommentsFailureAction = {
	type: typeof FETCH_MORE_COMMENTS_FAILURE;
};

export const fetchMoreCommentsFailure = (): FetchMoreCommentsFailureAction => ({
	type: FETCH_MORE_COMMENTS_FAILURE,
});

// FETCH_SORTED_COMMENTS_REQUEST
export const FETCH_SORTED_COMMENTS_REQUEST = 'FETCH_SORTED_COMMENTS_REQUEST' as const;

export type FetchSortedCommentsRequestAction = {
	type: typeof FETCH_SORTED_COMMENTS_REQUEST;
	payload?: string;
};

export const fetchSortedCommentsRequest = (
	commentId?: string,
): FetchSortedCommentsRequestAction => ({
	type: FETCH_SORTED_COMMENTS_REQUEST,
	payload: commentId,
});

// FETCH_SORTED_COMMENTS_SUCCESS
export const FETCH_SORTED_COMMENTS_SUCCESS = 'FETCH_SORTED_COMMENTS_SUCCESS' as const;

export type FetchSortedCommentsSuccessAction = {
	type: typeof FETCH_SORTED_COMMENTS_SUCCESS;
	payload: NormalizedLoadedComments;
};

export const fetchSortedCommentsSuccess = (
	loadedComments: NormalizedLoadedComments,
): FetchSortedCommentsSuccessAction => ({
	type: FETCH_SORTED_COMMENTS_SUCCESS,
	payload: loadedComments,
});

// FETCH_SORTED_COMMENTS_FAILURE
export const FETCH_SORTED_COMMENTS_FAILURE = 'FETCH_SORTED_COMMENTS_FAILURE' as const;

export type FetchSortedCommentsFailureAction = {
	type: typeof FETCH_SORTED_COMMENTS_FAILURE;
};

export const fetchSortedCommentsFailure = (): FetchSortedCommentsFailureAction => ({
	type: FETCH_SORTED_COMMENTS_FAILURE,
});

// CHANGE_COMMENT_VISIBILITY
export const CHANGE_COMMENT_VISIBILITY = 'CHANGE_COMMENT_VISIBILITY' as const;

export type ChangeCommentVisibilityAction = {
	type: typeof CHANGE_COMMENT_VISIBILITY;
	payload: {
		id: CommentId;
		isInternal: boolean;
	};
};

export const changeCommentVisibility = (
	id: CommentId,
	isInternal: boolean,
): ChangeCommentVisibilityAction => ({
	type: CHANGE_COMMENT_VISIBILITY,
	payload: {
		id,
		isInternal,
	},
});

export type ChangeCommentVisibility = ReturnType<typeof changeCommentVisibility>;

// EDIT_COMMENT_BEGIN
export const EDIT_COMMENT_BEGIN = 'EDIT_COMMENT_BEGIN' as const;

export type EditCommentBeginAction = {
	type: typeof EDIT_COMMENT_BEGIN;
	payload: {
		id: CommentId;
		isInternal: boolean;
		commentSessionId?: string | null;
	};
};

export const editCommentBegin = (
	id: CommentId,
	isInternal: boolean,
	commentSessionId?: string | null,
): EditCommentBeginAction => ({
	type: EDIT_COMMENT_BEGIN,
	payload: { id, isInternal, commentSessionId },
});

// EDIT_COMMENT_COLLAPSE
export const EDIT_COMMENT_COLLAPSE = 'EDIT_COMMENT_COLLAPSE' as const;

export type EditCommentCollapseAction = {
	type: typeof EDIT_COMMENT_COLLAPSE;
	payload: {
		id: CommentId;
		fieldId: string;
	};
};

export const editCommentCollapse = (id: CommentId): EditCommentCollapseAction => ({
	type: EDIT_COMMENT_COLLAPSE,
	payload: {
		id,
		fieldId: COMMENT,
	},
});

// EDIT_COMMENT_CANCEL
export const EDIT_COMMENT_CANCEL = 'EDIT_COMMENT_CANCEL' as const;

export type EditCommentCancelAction = {
	type: typeof EDIT_COMMENT_CANCEL;
	payload: {
		id: CommentId;
		fieldId: string;
	};
};

export const editCommentCancel = (id: CommentId): EditCommentCancelAction => ({
	type: EDIT_COMMENT_CANCEL,
	payload: {
		id,
		fieldId: COMMENT,
	},
});

// EDIT_COMMENT_UPDATE
export const EDIT_COMMENT_UPDATE = 'EDIT_COMMENT_UPDATE' as const;

export type EditCommentUpdateAction = {
	type: typeof EDIT_COMMENT_UPDATE;
	payload: {
		id: CommentId;
		value: ADF;
		fieldId: string;
	};
};

export const editCommentUpdate = (id: CommentId, value: ADF): EditCommentUpdateAction => ({
	type: EDIT_COMMENT_UPDATE,
	payload: {
		id,
		value,
		fieldId: COMMENT,
	},
});

export type EditCommentUpdate = ReturnType<typeof editCommentUpdate>;

// EDIT_COMMENT_VISIBILITY
export const EDIT_COMMENT_VISIBILITY = 'EDIT_COMMENT_VISIBILITY' as const;

export type EditCommentVisibilityAction = {
	type: typeof EDIT_COMMENT_VISIBILITY;
	payload: {
		id: CommentId;
		visibility: CommentVisibility;
	};
};

export const editCommentVisibility = (
	id: CommentId,
	visibility: CommentVisibility,
): EditCommentVisibilityAction => ({
	type: EDIT_COMMENT_VISIBILITY,
	payload: {
		id,
		visibility,
	},
});

export type EditCommentVisibility = ReturnType<typeof editCommentVisibility>;

export const EDIT_COMMENT_EVENT_OCCURRED_AT = 'EDIT_COMMENT_EVENT_OCCURRED_AT' as const;
export type EditCommentEventOccurredAtAction = {
	type: typeof EDIT_COMMENT_EVENT_OCCURRED_AT;
	payload: {
		id: CommentId;
		eventOccurredAt: number | null;
	};
};

export const editCommentEventOccurredAt = (
	id: CommentId,
	eventOccurredAt: number | null,
): EditCommentEventOccurredAtAction => ({
	type: EDIT_COMMENT_EVENT_OCCURRED_AT,
	payload: {
		id,
		eventOccurredAt,
	},
});

export type EditCommentEventOccurredAt = ReturnType<typeof editCommentEventOccurredAt>;

// DELETE_COMMENT_REQUEST
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST' as const;

export type DeleteCommentPayload = {
	id: CommentId;
	analyticsEvent: UIAnalyticsEvent;
	updatedField: string;
	parentId?: string;
	hasReplies?: boolean;
};
export type DeleteCommentRequestAction = {
	type: typeof DELETE_COMMENT_REQUEST;
	payload: DeleteCommentPayload;
};

export const deleteCommentRequest = (
	id: CommentId,
	analyticsEvent: UIAnalyticsEvent,
	parentId?: string,
	hasReplies?: boolean,
): DeleteCommentRequestAction => ({
	type: DELETE_COMMENT_REQUEST,
	payload: {
		id,
		analyticsEvent,
		parentId,
		hasReplies,
		updatedField: COMMENT,
	},
});

// DELETE_COMMENT_SUCCESS
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS' as const;

export type DeleteCommentSuccessAction = {
	type: typeof DELETE_COMMENT_SUCCESS;
	payload: {
		id: CommentId;
		fieldId: string;
		parentId?: string;
		hasReplies?: boolean;
	};
};

export const deleteCommentSuccess = (
	id: CommentId,
	parentId?: string,
	hasReplies?: boolean,
): DeleteCommentSuccessAction => ({
	type: DELETE_COMMENT_SUCCESS,
	payload: {
		id,
		fieldId: COMMENT,
		parentId,
		hasReplies,
	},
});

// DELETE_COMMENT_FAILURE
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE' as const;

export type DeleteCommentFailureActionType = {
	type: typeof DELETE_COMMENT_FAILURE;
	payload: CommentId;
};

export const deleteCommentFailure = (id: CommentId): DeleteCommentFailureActionType => ({
	type: DELETE_COMMENT_FAILURE,
	payload: id,
});

export type DeleteCommentFailureAction = ReturnType<typeof deleteCommentFailure>;

// PASTED_INTO_COMMENT_FORM
export const PASTED_INTO_COMMENT_FORM = 'PASTED_INTO_COMMENT_FORM' as const;

export type PastedIntoCommentFormAction = {
	type: typeof PASTED_INTO_COMMENT_FORM;
	payload: {
		id: CommentId;
		pastedContent: string;
	};
};

export const pastedIntoCommentForm = (
	id: CommentId,
	pastedContent: string,
): PastedIntoCommentFormAction => ({
	type: PASTED_INTO_COMMENT_FORM,
	payload: {
		id,
		pastedContent,
	},
});

export type PastedIntoCommentForm = ReturnType<typeof pastedIntoCommentForm>;

// SET_COMMENT_ATTACHMENT_IN_PROGRESS
export const SET_COMMENT_ATTACHMENT_IN_PROGRESS = 'SET_COMMENT_ATTACHMENT_IN_PROGRESS' as const;

export type SetCommentAttachmentInProgressAction = {
	type: typeof SET_COMMENT_ATTACHMENT_IN_PROGRESS;
	payload: {
		isInProgress: boolean;
	};
};

export const setCommentAttachmentInProgress = (
	isInProgress: boolean,
): SetCommentAttachmentInProgressAction => ({
	type: SET_COMMENT_ATTACHMENT_IN_PROGRESS,
	payload: {
		isInProgress,
	},
});

// SET_COMMENT_VALUE
export const SET_COMMENT_VALUE = 'SET_COMMENT_VALUE' as const;

export type SetCommentValueAction = {
	type: typeof SET_COMMENT_VALUE;
	payload: {
		id: CommentId;
		value: ADF;
		fieldId: string;
	};
};

export const setCommentValue = (id: CommentId, value: ADF): SetCommentValueAction => ({
	type: SET_COMMENT_VALUE,
	payload: {
		id,
		value,
		fieldId: COMMENT,
	},
});

// SET_COMMENT_SCROLL_STATUS
export const SET_COMMENT_SCROLL_STATUS = 'SET_COMMENT_SCROLL_STATUS' as const;

export type SetCommentScrollStatus = {
	type: typeof SET_COMMENT_SCROLL_STATUS;
	payload: {
		status: boolean;
	};
};

export const setCommentScrollStatus = (status: boolean): SetCommentScrollStatus => ({
	type: SET_COMMENT_SCROLL_STATUS,
	payload: {
		status,
	},
});

/**
 * Dispatched when the reply button is clicked on a comment.
 */
export const REPLY_COMMENT_BEGIN = 'REPLY_COMMENT_BEGIN' as const;

export type ReplyCommentBeginAction = {
	type: typeof REPLY_COMMENT_BEGIN;
	payload: {
		commentId: CommentId;
		authorId: AccountId;
		authorDisplayName: string;
		visibility?: CommentVisibility;
		commentSessionId?: string | null;
	};
};

export const replyCommentBegin = (
	commentId: CommentId,
	authorId: AccountId,
	authorDisplayName: string,
	visibility?: CommentVisibility,
	commentSessionId?: string | null,
): ReplyCommentBeginAction => ({
	type: REPLY_COMMENT_BEGIN,
	payload: { commentId, authorId, authorDisplayName, visibility, commentSessionId },
});

export type ReplyCommentBegin = ReturnType<typeof replyCommentBegin>;

/**
 * Dispatched when content is edited (onChange) in the reply comment editor.
 */
export const REPLY_COMMENT_UPDATE = 'REPLY_COMMENT_UPDATE' as const;

export type ReplyCommentUpdateAction = {
	type: typeof REPLY_COMMENT_UPDATE;
	payload: {
		parentId: CommentId;
		value: ADF;
	};
};

export const replyCommentUpdate = (parentId: CommentId, value: ADF): ReplyCommentUpdateAction => ({
	type: REPLY_COMMENT_UPDATE,
	payload: {
		parentId,
		value,
	},
});

export type ReplyCommentUpdate = ReturnType<typeof replyCommentUpdate>;

/**
 * Dispatched when cancel button is clicked or escape key is pressed in the reply comment editor.
 */
export const REPLY_COMMENT_CANCEL = 'REPLY_COMMENT_CANCEL' as const;

export type ReplyCommentCancelAction = {
	type: typeof REPLY_COMMENT_CANCEL;
	payload: {
		parentId: CommentId;
	};
};

export const replyCommentCancel = (parentId: CommentId): ReplyCommentCancelAction => ({
	type: REPLY_COMMENT_CANCEL,
	payload: {
		parentId,
	},
});

export type ReplyCommentCancel = ReturnType<typeof replyCommentCancel>;

export type CommentAction =
	| AddCommentFormCollapseAction
	| AddCommentFormExpand
	| AddCommentFormCollapse
	| ReturnType<typeof addCommentFormCancel>
	| SaveCommentRequest
	| ReturnType<typeof saveCommentRetry>
	| ReturnType<typeof saveCommentEdit>
	| ReturnType<typeof saveCommentSuccess>
	| ReturnType<typeof saveCommentSuccessWithPastedContent>
	| ReturnType<typeof saveCommentCancel>
	| ReturnType<typeof fetchOlderCommentsRequest>
	| ReturnType<typeof fetchNewerCommentsRequest>
	| ReturnType<typeof fetchMoreCommentsSuccess>
	| ReturnType<typeof fetchMoreCommentsFailure>
	| SaveCommentFailureAction
	| ReturnType<typeof editCommentBegin>
	| ReturnType<typeof editCommentCollapse>
	| ReturnType<typeof editCommentCancel>
	| EditCommentUpdate
	| EditCommentVisibility
	| EditCommentEventOccurredAt
	| ReturnType<typeof deleteCommentRequest>
	| ReturnType<typeof deleteCommentSuccess>
	| DeleteCommentFailureAction
	| PastedIntoCommentForm
	| ChangeCommentVisibility
	| ReturnType<typeof setCommentAttachmentInProgress>
	| ReturnType<typeof setCommentScrollStatus>
	| ReturnType<typeof fetchIssueSuccess>
	| SetCommentValueAction
	| FetchSortedCommentsRequestAction
	| FetchSortedCommentsSuccessAction
	| FetchSortedCommentsFailureAction
	| PreviewDataFetched
	| ReplyCommentBegin
	| ReplyCommentUpdate
	| ReplyCommentCancel;

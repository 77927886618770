import React, { Component, type ReactNode } from 'react';
import type { PreloadedQuery } from 'react-relay';
import type { OperationType } from 'relay-runtime';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/index.tsx';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	fireOperationalAnalytics,
	FireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	type AGGError,
	stopCaptureGraphQlErrors,
	type ErrorMeta,
} from '@atlassian/jira-relay-errors/src/index.tsx';
import { AGGErrors, isRelayValidationError as getIsRelayValidationError } from './utils.tsx';

interface Props {
	children: ReactNode;
	screenName: string;
	customFiltersQueryRef?: PreloadedQuery<OperationType>;
}

interface State {
	error: Error | null;
	isRelayValidationError: boolean;
	aggErrors: AGGError[] | null;
	aggErrorsMeta: ErrorMeta | null;
}

// eslint-disable-next-line jira/react/no-class-components
export class CustomFilterRelayBoundary extends Component<Props, State> {
	static getDerivedStateFromError = (error: Error) => ({
		error,
		isRelayValidationError: getIsRelayValidationError(error),
	});

	static getDerivedStateFromProps({ customFiltersQueryRef }: Props, state: State): State | null {
		if (state.error && !state.aggErrors && customFiltersQueryRef) {
			const capturedGQLErrorsInfo = stopCaptureGraphQlErrors(
				customFiltersQueryRef.fetchKey.toString(),
			);

			const validGQLErrorsInfo = capturedGQLErrorsInfo.find(
				(err) => err.meta.operationName === customFiltersQueryRef.name,
			);

			if (validGQLErrorsInfo?.errors.length) {
				return {
					...state,
					aggErrors: validGQLErrorsInfo.errors,
					aggErrorsMeta: validGQLErrorsInfo.meta,
				};
			}
		}

		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			error: null,
			aggErrors: null,
			aggErrorsMeta: null,
			isRelayValidationError: false,
		};
	}

	getCommonErrorAttributes(error: Error) {
		return {
			task: 'loadCustomFilters',
			screenName: this.props.screenName,
			isNetworkError: false,
			isRelayValidationError: this.state.isRelayValidationError,
			hash: getErrorHash(error) ?? '',
			errorMessage: error.message,
			stack: error.stack,
		};
	}

	render() {
		const { error, aggErrors, aggErrorsMeta, isRelayValidationError } = this.state;
		const { children } = this.props;

		if (!error) {
			return children;
		}

		if (aggErrors?.length) {
			const aggError = new AGGErrors(aggErrors, aggErrorsMeta?.requestId);
			return (
				<>
					<FireOperationalAnalytics
						eventName="ui taskFail"
						attributes={{
							traceId: aggError.traceId,
							statusCode: aggError.statusCode,
							...this.getCommonErrorAttributes(aggError),
						}}
					/>
					<ErrorFlagRenderer id="custom-filter-dropdown" error={aggError} />
				</>
			);
		}

		if (isRelayValidationError) {
			return (
				<>
					<FireOperationalAnalytics
						eventName="ui taskFail"
						attributes={{
							...this.getCommonErrorAttributes(error),
						}}
					/>
					<ErrorFlagRenderer id="custom-filter-dropdown" error={error} />
				</>
			);
		}

		// If this isn't an AGG or Relay error, let the upper error boundary handle it
		throw error;
	}
}

const CustomFilterBoundary = (props: Props) => {
	const { children, screenName } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onError = (_: string, error: Error) => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'ui taskFail', {
			screenName,
			task: 'loadCustomFilters',
			isNetworkError: isClientFetchError(error),
			hash: getErrorHash(error) ?? '',
			errorMessage: error.message,
		});
	};

	return (
		<JSErrorBoundary
			id="custom-filter-dropdown"
			packageName="software-filters"
			teamName="a4t-tanuki"
			fallback="flag"
			extraEventData={{ screenName }}
			onError={onError}
		>
			<CustomFilterRelayBoundary {...props}>
				<Placeholder name="custom-filter-boundary" fallback={null}>
					{children}
				</Placeholder>
			</CustomFilterRelayBoundary>
		</JSErrorBoundary>
	);
};

export default CustomFilterBoundary;

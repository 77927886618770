import React, { Component, type ReactNode, type SyntheticEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { MediaImage } from '@atlaskit/media-image';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';

const borderRadius = 2;
const coverMaxSize = 260;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CoverControl = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden',
	borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div img': {
		display: 'block',
		maxWidth: '100%',
		maxHeight: `${coverMaxSize}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CoverExperiment = styled2.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden',
	borderRadius: `${token('space.025', '2px')} ${token('space.025', '2px')} 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div img': {
		display: 'block',
		maxWidth: '100%',
		maxHeight: `${coverMaxSize}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Cover = styledComponentWithCondition(
	() => ff('compiled.migration.jsw.tanuki'),
	CoverExperiment,
	CoverControl,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CoverContainer = styled2.div({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyItems: 'center',
	alignItems: 'center',
});

export type Props = {
	mediaId: string;
	clientId: string;
	token: string;
	serviceHost: string;
	collectionName?: string;
	placeholder?: ReactNode;
	onImageLoad?: (e: SyntheticEvent<HTMLImageElement>) => void; // Required so we can notify parents (CellMeasurerCache) about resizes
};

// HOT-90084: we want to reuse mediaClientConfigs across all CardCovers to avoid extra http requests
// MediaImage internally checks for object equality to prevent image refetch.
// That's why we need to check for the individual parameters equality at this level
// and prevent the property mediaClientConfig to change
const mediaClientConfigs = new Map();
const getMediaClientConfig = ({ token: authToken, clientId, serviceHost }: Props) => {
	const key = `${authToken}:${clientId}:${serviceHost}`;

	if (mediaClientConfigs.has(key)) {
		return mediaClientConfigs.get(key);
	}

	const mediaClientConfig: MediaClientConfig = {
		authProvider: () => {
			const auth = Promise.resolve({
				clientId,
				token: authToken,
				baseUrl: serviceHost,
			});
			return auth;
		},
	};

	mediaClientConfigs.set(key, mediaClientConfig);

	return mediaClientConfig;
};

const hasAuth = ({ token: authToken, clientId, serviceHost }: Props) =>
	authToken && clientId && serviceHost;

// eslint-disable-next-line jira/react/no-class-components
export default class CardCover extends Component<Props> {
	// @ts-expect-error - TS2564 - Property 'prevScr' has no initializer and is not definitely assigned in the constructor.
	prevScr: string;

	render() {
		const mediaClientConfig: MediaClientConfig = hasAuth(this.props)
			? getMediaClientConfig(this.props)
			: undefined;

		if (!mediaClientConfig) {
			return null;
		}

		const { mediaId, collectionName } = this.props;
		const mediaImageProps = {
			identifier: {
				mediaItemType: 'file',
				id: mediaId,
				collectionName,
			},
			mediaClientConfig,
		};

		return (
			<Cover>
				<CoverContainer>
					{/* @ts-expect-error - TS2769 - No overload matches this call. */}
					<MediaImage {...mediaImageProps}>
						{({ loading, error, data }) => {
							if (loading && this.prevScr) {
								// eslint-disable-next-line jsx-a11y/alt-text
								return <img src={this.prevScr} />;
							}
							if (!data || loading || error) {
								if (this.props.placeholder !== undefined) {
									return this.props.placeholder;
								}
								return null;
							}
							// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
							this.prevScr = data.src;
							return (
								<img
									src={data.src}
									onLoad={this.props.onImageLoad ?? noop}
									alt=""
									role="presentation"
									data-test-id="platform-board-kit.ui.card.card-cover.card-cover-image"
									data-testid="platform-board-kit.ui.card.card-cover.card-cover-image"
								/>
							);
						}}
					</MediaImage>
				</CoverContainer>
			</Cover>
		);
	}
}

import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, type ApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';

export type CloneIssueStage = 'META' | 'CLONE' | 'PROGRESS' | 'FETCH';

export const cloneIssueExperienceDescription = (
	wasSuccessful: boolean,
	stage: CloneIssueStage,
	analyticsSource: string,
	projectType: ProjectType | null,
	appEditions: ApplicationEditions,
	errorMessage?: string,
) => {
	let application = null;
	let edition = null;

	if (projectType !== null) {
		try {
			application = getApplicationForProject(projectType);
			edition = getEdition(application, appEditions);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// application and edition already default to null.
		}
	}

	return {
		experience: 'cloneIssue',
		wasExperienceSuccesful: wasSuccessful,
		analyticsSource,
		application,
		edition,
		additionalAttributes: {
			stage,
			...(errorMessage !== undefined && errorMessage !== null ? { errorMessage } : {}),
		},
	};
};

// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import trim from 'lodash/trim';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type InlineEditType from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import messages from './messages.tsx';

const boardTitleMaxChars = 30;

export type Props = {
	canEdit: boolean;
	boardTitle: string;
	onSubmit: (title: string) => void;
};

type PropsWithIntl = Props & Intl;

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const InlineEdit = lazy<typeof InlineEditType<string>>(
	() => import(/* webpackChunkName: "async-board-inline-edit" */ '@atlaskit/inline-edit'),
);

// eslint-disable-next-line jira/react/no-class-components
class Title extends Component<PropsWithIntl> {
	onConfirm = (editValue: string, analyticsEvent: UIAnalyticsEvent) => {
		const { boardTitle } = this.props;
		if (editValue !== null) {
			const newTitle = trim(editValue);
			if (newTitle !== '' && newTitle !== boardTitle) {
				this.props.onSubmit(newTitle);
				fireUIAnalytics(analyticsEvent, 'boardName');
			}
		}
	};

	renderEditView: ComponentProps<typeof InlineEditType<string>>['editView'] = (fieldProps) => {
		const { errorMessage, ...fieldPropsWithoutErrorMessage } = fieldProps;
		const {
			intl: { formatMessage },
			boardTitle,
		} = this.props;
		return (
			<TextFieldWrapper>
				<Textfield
					{...fieldPropsWithoutErrorMessage}
					aria-label={formatMessage(messages.currentTitleAriaLabel, {
						currentTitle: boardTitle,
					})}
					autoFocus
					maxLength={boardTitleMaxChars}
				/>
			</TextFieldWrapper>
		);
	};

	renderReadView = () => (
		<ReadContainer>
			<TruncatingTextStyle>
				<ReadHeading>{this.props.boardTitle}</ReadHeading>
			</TruncatingTextStyle>
		</ReadContainer>
	);

	renderReadOnlyView = () => (
		<TruncatingTextStyle>
			<HeadingStyle>{this.props.boardTitle}</HeadingStyle>
		</TruncatingTextStyle>
	);

	render() {
		const {
			intl: { formatMessage },
			canEdit,
		} = this.props;

		return (
			<LimitWidthStyle data-testid="software-board.header.title.container">
				{canEdit ? (
					<EditableMarginCorrect>
						<ErrorBoundary
							id="inline-edit"
							packageName="software-board"
							render={this.renderReadOnlyView}
						>
							<Placeholder
								name="inline-edit"
								fallback={<Box xcss={loadingEditorWrapperStyles}>{this.renderReadView()}</Box>}
							>
								<InlineEdit
									editView={this.renderEditView}
									readView={this.renderReadView}
									onConfirm={this.onConfirm}
									defaultValue={this.props.boardTitle}
									editButtonLabel={formatMessage(messages.currentTitleAriaLabel, {
										currentTitle: this.props.boardTitle,
									})}
									label=""
									hideActionButtons
								/>
							</Placeholder>
						</ErrorBoundary>
					</EditableMarginCorrect>
				) : (
					this.renderReadOnlyView()
				)}
			</LimitWidthStyle>
		);
	}
}

export default injectIntl(Title);

const boardTitleMaxWidth = 420;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextFieldWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> [data-ds--text-field--container] input': {
		height: 'auto',
		font: token('font.heading.large'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LimitWidthStyle = styled.div({
	maxWidth: `${boardTitleMaxWidth}px`,
	marginTop: `${token('space.025', '2px')}`,
	marginBottom: `${token('space.025', '2px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	lineHeight: 'inherit',
	margin: `${token('space.100', '8px')} 0px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TruncatingTextStyle = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: '0 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditableMarginCorrect = styled.div({
	marginTop: `-${token('space.100', '8px')};`,
});

const loadingEditorWrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeadingStyle = styled.h1({
	font: token('font.heading.large'),
	fontWeight: '500',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadHeading = styled.h1({
	font: token('font.heading.large'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

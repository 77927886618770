/*!
 * This module contains a "CAPABILITIES" API and model for JSW UIF board.
 *
 * Any feature that is "gated" and only available on certain cases should be
 * added here.
 */
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { type CapabilityKey, hasCapability } from '../../../common/capability/index.tsx';
import { useBoardSelector } from '../../index.tsx';
import {
	getEntities,
	getIsIncrementPlanningBoard,
	projectTypeSelector,
	getCanEditBoard,
} from '../../selectors/software/software-selectors.tsx';

/**
 * Reads whether the current board is a CMP board. Use this or the
 * resource-router route name.
 */
export function useIsCMPBoard(): boolean {
	return useBoardSelector((state) => state.configuration.isCMPBoard);
}

/**
 * Reads whether the current board is a user board. Use this or the
 * resource-router route name.
 * @returns
 */
export function useIsUserBoard(): boolean {
	return useBoardSelector((state) => state.configuration.isUserBoard);
}

/**
 * Reads whether the current board is a JSM board. Use this or the
 * resource-router route name.
 */
export function useIsJSMBoard(): boolean {
	return useBoardSelector(projectTypeSelector) === SERVICE_DESK_PROJECT;
}
/**
 * Reads whether the current user has edit board permission.
 */
export function useCanEditBoard(): boolean {
	return useBoardSelector(getCanEditBoard);
}

/**
 * Reads whether the current board is a JSW board. Use this or the
 * resource-router route name.
 */
export function useIsJSWBoard(): boolean {
	return useBoardSelector(projectTypeSelector) === SOFTWARE_PROJECT;
}

/**
 * Reads whether the current board is an Increment Planning board (owned by Advanced Roadmaps Team). Use this or the
 * resource-router route name.
 */
export function useIsIncrementPlanningBoard(): boolean {
	return useBoardSelector(getIsIncrementPlanningBoard);
}

/**
 * If reading a capability on a view, use this hook.
 */
export function useCapability(key: CapabilityKey): boolean {
	const isCMPBoard = useIsCMPBoard();
	const isUserBoard = useIsUserBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const allFeatures = useBoardSelector((state) => getEntities(state).board.config.allFeatures);
	const projectType = useBoardSelector(projectTypeSelector);

	return hasCapability(
		key,
		isCMPBoard,
		isIncrementPlanningBoard,
		allFeatures,
		projectType,
		isUserBoard,
	);
}

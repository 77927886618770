import React from 'react';
import OpTrigger from '@atlassian/jira-common-util-op-triggers/src/on-mount/index.tsx';
import { addBoardToHistory } from '../../services/index.tsx';

type Props = {
	baseUrl: string;
	boardId: string;
};

const AddBoardToHistory = ({ baseUrl, boardId }: Props) => (
	<OpTrigger op={() => addBoardToHistory(baseUrl, boardId)} />
);

export default AddBoardToHistory;

import React, { useEffect } from 'react';
import type {
	ManualRule,
	ManualRuleExecutor,
	TemplateRecommendation,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import ErrorState from '@atlassian/jira-common-components-error-state/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	fireOperationalAnalyticsDeferred,
	FireScreenAnalytics,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import Skeleton from '../../../../common/ui/skeleton/styled.tsx';
import { useCanManageAutomations } from '../../../../controllers/menu-context/index.tsx';
import messages from './messages.tsx';
import RuleList from './rule-list/index.tsx';
import { ErrorStateContainer, errorImageHeight } from './styled.tsx';

// Component is responsible for triggering the ManualRulesContainer HOC to fetch the rules
// when this mounts. This is how we ensure lazy load behaviour of the menu.
const FetchRulesContainer = (props: {
	triggerFetch: () => Promise<void>;
	children: React.ReactNode;
}) => {
	const { triggerFetch, children } = props;
	useEffect(() => {
		triggerFetch();
		// Ignore exhaustive dep here to ensure no call to useEffect on re-render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>{children}</>;
};

// This value corresponds to MAX_OBJECT_COUNT in
// https://bitbucket.org/atlassian/barrel/src/9a05ddcb0406afe6565ce8f67e4eb43310155c42/platform/lambdas/router/src/main/java/com/codebarrel/aws/lambda/automation/external/manual/SearchManualRuleRequestValidator.java#lines-31
export const MAX_ISSUE_ID_LIMIT = 50;

export interface ManualRulesProps {
	triggerFetch: () => Promise<void>;
	initialised: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
	rules: ManualRule[];
	executingRuleId: number | null;
	executeRule: ManualRuleExecutor;
	setClosed: () => void;
	selectedIssueIds?: number[];
	recommendations?: TemplateRecommendation[];
}

const ManualRules = (props: ManualRulesProps) => {
	const {
		triggerFetch,
		initialised,
		error,
		rules,
		executeRule,
		setClosed,
		executingRuleId,
		selectedIssueIds,
		recommendations,
	} = props;
	const { formatMessage } = useIntl();
	const [canManageAutomations] = useCanManageAutomations();

	if (error) {
		return (
			<ErrorStateContainer canManageAutomations={canManageAutomations}>
				<MountEvent
					onMount={(event) => fireOperationalAnalyticsDeferred(event, 'ruleErrorState mounted')}
				/>
				<ErrorState
					header={formatMessage(messages.errorHeader)}
					description={formatMessage(messages.errorDescription)}
					maxImageHeight={errorImageHeight}
				/>
			</ErrorStateContainer>
		);
	}

	if (
		selectedIssueIds &&
		selectedIssueIds.length > MAX_ISSUE_ID_LIMIT &&
		fg('selected-issue-id-limit')
	) {
		return (
			<ContextualAnalyticsData
				sourceType={SCREEN}
				sourceName="selectedIssuesLimitExceeded"
				attributes={{ selectedIssueCount: selectedIssueIds.length }}
			>
				<ErrorStateContainer canManageAutomations={canManageAutomations}>
					<FireScreenAnalytics />
					<ErrorState
						header={formatMessage(messages.issueSelectionLimitExceededHeader)}
						description={formatMessage(messages.issueSelectionLimitExceededDescription, {
							limit: MAX_ISSUE_ID_LIMIT,
						})}
						maxImageHeight={errorImageHeight}
					/>
				</ErrorStateContainer>
			</ContextualAnalyticsData>
		);
	}

	return (
		<>
			<FetchRulesContainer triggerFetch={triggerFetch}>
				{initialised ? (
					<RuleList
						rules={rules}
						executingRuleId={executingRuleId}
						executeRule={executeRule}
						selectedIssueIds={selectedIssueIds}
						recommendations={recommendations}
						setClosed={setClosed}
					/>
				) : (
					<Skeleton />
				)}
			</FetchRulesContainer>
		</>
	);
};

export default ManualRules;

import React, { useMemo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { BoardToolSubItem } from '../../common/ui/tool-sections/board-tool-section-view/board-tool-sub-item/index.tsx';
import { BoardToolsView } from '../../common/ui/tool-sections/index.tsx';
import { useFetchedToolSections } from '../../controllers/index.tsx';
import type { BoardMenuItemLocation } from './types.tsx';
import { findSection } from './utils.tsx';

export type BoardConnectMenuItemsProps = {
	boardId: string;
	projectKey: string;
	location?: BoardMenuItemLocation;
	mode: 'work' | 'plan';
	onClickWebItem?: () => void;
};

function BoardConnectMenuItemsInner({
	projectKey,
	boardId,
	location,
	mode,
	onClickWebItem,
}: BoardConnectMenuItemsProps) {
	const toolSections = useFetchedToolSections({ boardId, projectKey, mode });
	const targetSection = useMemo(
		() => toolSections && location && findSection(toolSections, location),
		[toolSections, location],
	);

	if (!location && getWillShowNav4() && fg('move-connect-addons-board-backlog-nav4')) {
		return toolSections ? (
			<BoardToolsView
				toolSections={toolSections}
				mode="dropdown-item"
				onClickWebItem={fg('make_board_menu_popup') ? onClickWebItem : undefined}
			/>
		) : null;
	}

	if (!toolSections || !targetSection) {
		return null;
	}

	return (
		<>
			{targetSection.subItems
				?.filter((item) => item.id != null) // built-in items don't have IDs set
				.map((item) => (
					<BoardToolSubItem onClickWebItem={onClickWebItem} key={item.id} subItem={item} />
				)) ?? null}
		</>
	);
}

export function BoardConnectMenuItems(props: BoardConnectMenuItemsProps) {
	return (
		<JSErrorBoundary
			id="board-connect-toolbar"
			packageName="@atlassian/jira-software-board-connect-menu-items"
			teamName="a4t-tanuki"
			fallback="flag"
		>
			<BoardConnectMenuItemsInner {...props} />
		</JSErrorBoundary>
	);
}

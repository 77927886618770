export const DEFAULT_DATE_DISPLAY_FORMAT = 'd/MMM/yy hh:mm a';
export const DEFAULT_DATE_DISPLAY_FORMAT_SHORT = 'MMM d, yyyy';

export const DEFAULT_PARENT_ISSUE_TYPE_NAME = 'Epic';
export const DEFAULT_BASE_ISSUE_TYPE_NAME = 'Base';

/**
 * When tabs become active we refresh the board.
 */
export const REFRESH_SOURCE_TAB_BECAME_ACTIVE = 'TAB_BECAME_ACTIVE' as const;
/**
 * Every n seconds we poll for information, this is currently 10 minutes.
 */
export const REFRESH_SOURCE_POLLING = 'POLLING' as const;
/**
 * When issue change / board change events are broadcast we refresh the board.
 */
export const REFRESH_SOURCE_REALTIME = 'REALTIME' as const;
export const REFRESH_SOURCE_FAILURE = 'FAILURE' as const;
export const REFRESH_SOURCE_GLOBAL_ISSUE_CREATE = 'GLOBAL_ISSUE_CREATE' as const;
export const REFRESH_SOURCE_MODAL_CLOSED = 'MODAL_CLOSED' as const;
export const REFRESH_SOURCE_BENTO = 'BENTO' as const;
export const REFRESH_SOURCE_CARD_CLEAR_UNDO = 'CARD_CLEAR_UNDO' as const;
export const REFRESH_SOURCE_STATUS_REMAPPED = 'STATUS_REMAPPED' as const;
export const REFRESH_SOURCE_RELEASE_VERSION = 'RELEASE_VERSION' as const;
export const REFRESH_SOURCE_TRANSITION = 'TRANSITION' as const;
export const REFRESH_SOURCE_BULK_ISSUE_RANK = 'BULK_ISSUE_RANK' as const;
export const REFRESH_SOURCE_INLINE_CREATE = 'INLINE_CREATE' as const;
export const REFRESH_SOURCE_COLUMN_CREATE = 'COLUMN_CREATE' as const;
export const REFRESH_SOURCE_COLUMN_DELETE = 'COLUMN_DELETE' as const;
export const REFRESH_SOURCE_ADD_FLAG = 'ADD_FLAG' as const;
export const REFRESH_SOURCE_BOARD_ADD_TO_SPRINT = 'BOARD_ADD_TO_SPRINT' as const;
export const REFRESH_SOURCE_BOARD_API = 'BOARD_API' as const;
export const REFRESH_SOURCE_ARCHIVE_ISSUE = 'ARCHIVE_ISSUE' as const;
export const REFRESH_SOURCE_ADD_ISSUE_TYPE_MODAL = 'ADD_ISSUE_TYPE_MODAL' as const;

export const BOARD_LONG_TASKS_DND_MARK_NAME = 'board.long-tasks-dnd';

export const APP_ID = 'software.work';
export const TEAM_NAME = 'a4t-tanuki';
export const PACKAGE_NAME = '@atlassian/jira-software-board';

export const CARD_DND_TYPE = 'CARD' as const;
export const SUBTASK_DND_TYPE = 'SUBTASK' as const;
export const CARD_GROUP_DND_TYPE = 'CARD_GROUP' as const;

export const EXPERIENCE_TRACKER_LOCATION_BOARD = 'ng-board';
export const EXPERIENCE_TRACKER_LOCATION_CMP_BOARD = 'cmp-board';

export const BOARD_INTERACTIVE_MARK = 'BOARD_INTERACTIVE_MARK';

export const STORY_POINTS = 'STORY_POINTS' as const;
export const ORIGINAL_ESTIMATE = 'ORIGINAL_ESTIMATE' as const;
export const ISSUE_COUNT = 'ISSUE_COUNT' as const;
export const ORIGINAL_ESTIMATE_FIELD_ID = 'timeoriginalestimate';

export const SSR_CARD_LIMIT = 50;

export const TOP_OF_CONTAINER = 'TOP_OF_CONTAINER' as const;
export const BOTTOM_OF_CONTAINER = 'BOTTOM_OF_CONTAINER' as const;

export const BOARD = 'BOARD';

export const DEFAULT_JQL_SWIMLANE_ID = 'DEFAULT_JQL_SWIMLANE_ID';
